import React, { useEffect, useRef, useState } from 'react'
import { Flex } from 'componentsv2/Flex'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import { OPTIONS_NAME, SELECTED_CATEGORY } from 'consts'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { cloneDeep } from 'lodash'
import classnames from 'classnames'
import { Spin } from 'componentsv2'
import { NornaTab, NornaTable, SubTh, TableCategoryCell, TableCell, TableCellWithSymbol, TableOptionsCell, Th } from 'componentsv2/TradingTableV2'
import { SizedBox } from 'componentsv2/SizedBox'
import { useLoadingBar } from 'hooks/useLoadingBar'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ASSORTMENT_INSIGHT_TABLE_NAME } from 'configs'
import { useDashboardComparison, useDashboardMarkInfo } from 'hooks/api/useDashboardTableData'
import { getExportId } from 'utils'
import { DashboardTableForm } from 'componentsv2/business/DashboardTableForm'
import { useModuleData } from 'hooks/useModuleData'
import { useFilterCategory, useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { useLookbook } from 'hooks/useLookbook'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import { getExpandedKeysByMetricTab, getSubExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import styles from './styles.module.scss'
import * as config from './config'
import { geneCsvData, handleTableData, handleTopKpiData } from './utils'
import { AssortmentInsightTopKpi } from '../AssortmentInsightTopKpi'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_ASSORTMENT_INSIGHTS } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'

const exportId = getExportId(ASSORTMENT_INSIGHT_TABLE_NAME)

export const AssortmentInsightTable = () => {
  useLoadingBar()
  const [ filterCountry ] = useFilterCountry()
  const [ filterCategory ] = useFilterCategory()
  const [ filterCompetitor ] = useFilterCompetitor()
  const { isLookbook } = useLookbook()
  const expandedRowKeysRef = useRef<string[]>([])
  const subExpandedRowKeysRef = useRef<string[]>([])
  const [ , setRefresh ] = useState({})

  // eslint-disable-next-line
  const [ moduleData, setModuleData ] = useModuleData(ASSORTMENT_INSIGHT_TABLE_NAME)
  const { isCompetitorView, switchLoading } = moduleData

  /* ******************************** 表格 *********************************** */
  const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

  const columns: any[] = [
    {
      dataIndex: 'options',
      width: config.optionsWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }
        const options = numberUtils.formatNumber(record?.Numbers?.value, { isCommaSymbol: true, decimal: 0 })
        const changePercent = numberUtils.formatNumber(record?.Numbers?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        return (
          <TableOptionsCell
            text={options}
            changePercent={changePercent}
            record={record}
            width={config.optionsWidth}
            metricName="All"
            isDeepBorderRight
          />
        )
      },
    },
    {
      dataIndex: 'categoryKey',
      width: config.categoryWidth,
      render: (text, record) => {
        return (
          <TableCategoryCell 
            text={isCompetitorView ? record.categoryName : record.vendorName}
            record={record}
            width={config.categoryWidth}
            isCompetitorView={isCompetitorView}
            isDeepBorderRight
          />
        )
      },
    },

    {
      dataIndex: 'categorySplitValue',
      width: config.categorySplitValueWidth + config.categorySplitChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.category_fraction?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const categorySplitChangeValue = numberUtils.formatNumber(record?.category_fraction?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.categorySplitValueWidth + config.categorySplitChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.categorySplitValueWidth} isFirstMetric />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={categorySplitChangeValue} record={record} width={config.categorySplitChangeWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'sizeOfLineValue',
      width: config.sizeOfLineValueWidth + config.sizeOfLineChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.size_of_line?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })
        const sizeOfLineChangeValue = numberUtils.formatNumber(record?.size_of_line?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.sizeOfLineValueWidth + config.sizeOfLineChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.sizeOfLineValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={sizeOfLineChangeValue} record={record} width={config.sizeOfLineChangeWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'sizeRatioValue',
      width: config.sizeRatioValueWidth + config.sizeRatioChangePercentWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.size_ratio?.value, { decimal: 1 })
        const sizeRatioChangePercentValue = numberUtils.formatNumber(record?.size_ratio?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.sizeRatioValueWidth + config.sizeRatioChangePercentWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.sizeRatioValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={sizeRatioChangePercentValue} record={record} width={config.sizeRatioChangePercentWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'optionRatioValue',
      width: config.optionRatioValueWidth + config.optionRatioChangePercentWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        let value = numberUtils.formatNumber(record?.option_ratio?.value, { decimal: 1 })
        /**
         * 硬编码: zzegna && China, option ratio value = 1
         */
        if (record.vendorCode === 'zzegna' && filterCountry === 'China') {
          value = numberUtils.formatNumber(1, { decimal: 1 })
        }

        let optionRatioChangePercentValue = numberUtils.formatNumber(record?.option_ratio?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        /**
         * 硬编码: zzegna && China, option ratio change = 0
         */
        if (record.vendorCode === 'zzegna' && filterCountry === 'China') {
          optionRatioChangePercentValue = numberUtils.formatNumber(0, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        }

        return (
          <TableCell
            width={config.optionRatioValueWidth + config.optionRatioChangePercentWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.optionRatioValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={optionRatioChangePercentValue} record={record} width={config.optionRatioChangePercentWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'soldOutValue',
      width: config.soldOutValueWidth + config.soldOutChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.out_of_stock_percentage?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const soldOutChangeValue = numberUtils.formatNumber(record?.out_of_stock_percentage?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.soldOutValueWidth + config.soldOutChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.soldOutValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={soldOutChangeValue} record={record} width={config.soldOutChangeWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'ecoLabelValue',
      width: config.ecoLabelValueWidth + config.ecoLabelChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.eco_label?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const ecoLabelChangeValue = numberUtils.formatNumber(record?.eco_label?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.ecoLabelValueWidth + config.ecoLabelChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.ecoLabelValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={ecoLabelChangeValue} record={record} width={config.ecoLabelChangeWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'solidPatternValue',
      width: config.solidPatternValueWidth + config.solidPatternChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell />
          )
        }

        const value = numberUtils.formatNumber(record?.solid_pattern?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const solidPatternChangeValue = numberUtils.formatNumber(record?.solid_pattern?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.solidPatternValueWidth + config.solidPatternChangeWidth}
            isEmptyBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.solidPatternValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={solidPatternChangeValue} record={record} width={config.solidPatternChangeWidth} />
          </TableCell>
        )
      },
    },
  ]

  /* ******************************************************************* */
  const { data, loading } = useDashboardComparison({ moduleName: ASSORTMENT_INSIGHT_TABLE_NAME })
  const [ topKpiData, setTopKpiData ] = useState({ marketData: {}, customerData: {} })

  const { data: marketInfoData, loading: marketInfoLoading } = useDashboardMarkInfo({ moduleName: ASSORTMENT_INSIGHT_TABLE_NAME })

  const [ metricsTab1, setMetricsTab1 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata1, setTabledata1 ] = useState<Array<any>>([])

  const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])
  const [ , setCsvData ] = useCsvDataModuleData()

  const handleData = (data: any) => {
    // 数据不存在直接返回
    if (!Object.keys(data || {})?.length) {
      return {}
    }

    if (isCompetitorView) {
      const {
        tabledata: tabledata1,
        metricsTab: metricsTab1,
      } = handleTableData({
        apiData: data,
        isCompetitorView: true,
        competitorValue: filterCompetitor.map(seller => seller.vendor),
        categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
        expandedRowKeys: expandedRowKeysRef.current,
        subExpandedRowKeysRef: subExpandedRowKeysRef.current,
        region: filterCountry,
      })
      setTabledata1(tabledata1)
      setMetricsTab1(metricsTab1)
      setCsvData({ 
        [CSV_DATA_ASSORTMENT_INSIGHTS]: geneCsvData({ dataSource: tabledata1 }),
      })
      return
    }
    
    const {
      tabledata: tabledata2,
      metricsTab: metricsTab2,
    } = handleTableData({
      apiData: data,
      isCompetitorView: false,
      competitorValue: filterCompetitor.map(seller => seller.vendor),
      categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
      expandedRowKeys: expandedRowKeysRef.current,
      subExpandedRowKeysRef: subExpandedRowKeysRef.current,
      region: filterCountry,
    })
    
    setTabledata2(tabledata2)
    setMetricsTab2(metricsTab2)
    setCsvData({ 
      [CSV_DATA_ASSORTMENT_INSIGHTS]: geneCsvData({ dataSource: tabledata2, isCompetitorView: false }), 
    })
  }

  useDeepCompareEffect(() => {
    if (!data) return

    handleData(cloneDeep(data))
  }, [ data, filterCompetitor, isCompetitorView, expandedRowKeysRef.current, subExpandedRowKeysRef.current, {} ])

  useDeepCompareEffect(() => {
    if (!marketInfoData) return
    setTopKpiData(handleTopKpiData({ data: marketInfoData, filterCountry }))
  }, [ marketInfoData, filterCountry ])

  /* ************************ 表格展开项 ************************** */
  useDeepCompareEffect(() => {
    if (expandedRowKeysRef.current?.length) {
      const firstLevelCategory = getFirstLevelCategories({ selectedCategories: filterCategory })
      firstLevelCategory.push(SELECTED_CATEGORY)
      const vendorList = filterCompetitor.map(f => f.vendor)
      let expandedRowKeys = expandedRowKeysRef.current
      expandedRowKeys = expandedRowKeys.filter(item => {
        const code = item.split('_')[0]
        if (isCompetitorView) {
          return vendorList.includes(code)
        }
        return firstLevelCategory.includes(code)
      })

      expandedRowKeysRef.current = [ ...expandedRowKeys ]
      setRefresh({})
    }
    if (subExpandedRowKeysRef.current?.length) {
      let subExpandedRowKeys = subExpandedRowKeysRef.current
      subExpandedRowKeys = subExpandedRowKeys.filter(item => {
        const arr = item.split('__')
        if (arr.length === 1) {
          return true
        }
        return filterCategory.includes(arr[1])
      })

      subExpandedRowKeysRef.current = [ ...subExpandedRowKeys ]
      setRefresh({})
    }
    // eslint-disable-next-line
  }, [filterCategory, filterCompetitor, isCompetitorView])

  return (
    <div id={exportId}>
      <Spin spinning={loading || marketInfoLoading || !data}>
        <AssortmentInsightTopKpi
          marketData={topKpiData?.marketData || {}}
          customerData={topKpiData?.customerData || {}}
        />
        <SizedBox height={40} />
        <ModuleTitle
          type="Dashboard"
          title={ASSORTMENT_INSIGHT_TABLE_NAME}
          showComparisonDate
        />
        <SizedBox height={10} />
        <Dialog ref={dialogRef} />
        <Spin spinning={switchLoading} className={classnames([ 'norna-container-fixed-width' ])} style={{ width: '1440px' }}>
          <DashboardTableForm moduleName={ASSORTMENT_INSIGHT_TABLE_NAME} />
          <SizedBox height={20} />
          <Flex className={styles.title}>
            <div style={{ width: config.tabWidth + 'px', textAlign: 'center' }}>
              {isCompetitorView ? 'Vendors' : 'Categories'}
            </div>
            <Th title={OPTIONS_NAME} width={config.optionsWidth} style={{ paddingLeft: '5px' }} />
            <Th title={ isCompetitorView ? 'Category' : 'Vendor' } width={config.categoryWidth} style={{ paddingLeft: '14px' }} />
            <Th title="Category split" width={config.categorySplitWidth} />
            <Th title="Size of line" width={config.sizeOfLineWidth} />
            <Th title="Size ratio" width={config.sizeRatioWidth} />
            <Th title="Option ratio" width={config.optionRatioWidth} />
            <Th title="Sold out" width={config.soldOutWidth} />
            <Th title="Eco label" width={config.ecoLabelWidth} />
            <Th title="Solid" width={config.solidWidth} />
          </Flex>
          <Flex className={styles.subtitle}>
            <SubTh width={config.tabWidth} />
            <SubTh width={config.optionsWidth} innerWidth={43} showComparisonTooltip align="right" showCount showSymbol showPercent style={{ paddingRight: '5px', display: 'flex', justifyContent: 'flex-end' }} />
            <SubTh width={config.categoryWidth} />
            <SubTh width={config.categorySplitValueWidth} showCurrency />
            <SubTh width={config.categorySplitChangeWidth} innerWidth={64} showComparisonTooltip showUnit showSymbol showPercent />
            <SubTh width={config.sizeOfLineValueWidth} />
            <SubTh width={config.sizeOfLineChangeWidth} innerWidth={64} showComparisonTooltip showUnit showSymbol showPercent />
            <SubTh width={config.sizeRatioValueWidth} />
            <SubTh width={config.sizeRatioChangePercentWidth} innerWidth={33} showComparisonTooltip showSymbol showPercent />
            <SubTh width={config.optionRatioValueWidth} />
            <SubTh width={config.optionRatioChangePercentWidth} innerWidth={33} showComparisonTooltip showSymbol showPercent />
            <SubTh width={config.soldOutValueWidth} showPercent />
            <SubTh width={config.soldOutChangeWidth} innerWidth={64} showComparisonTooltip showUnit showSymbol showPercent />
            <SubTh width={config.ecoLabelValueWidth} showPercent />
            <SubTh width={config.ecoLabelChangeWidth} innerWidth={64} showComparisonTooltip showUnit showSymbol showPercent />
            <SubTh width={config.solidPatternValueWidth} showPercent />
            <SubTh width={config.solidPatternChangeWidth} innerWidth={64} showComparisonTooltip showUnit showSymbol showPercent />
          </Flex>
          {
            isCompetitorView ? (
              <CompetitorView
                columns={columns}
                data={tabledata1}
                tab={metricsTab1}
                setMetricsTab={setMetricsTab1}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            ) : (
              <CategoryView
                columns={columns}
                data={tabledata2}
                tab={metricsTab2}
                setMetricsTab={setMetricsTab2}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            )
          }
        </Spin>
      </Spin>
      <div style={{ width: 1, height: 30, background: 'transparent' }} />
    </div>
  )
}

export const CompetitorView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, [])
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.metricsContent}>
      <NornaTab
        showHeader={false}

        categoryToVendor={true}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={true}
        key="competitorview"
      />
    </div>
  )
}

export const CategoryView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    const subExpandedKeys = getSubExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, subExpandedKeys)
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.metricsContent}>
      <NornaTab
        showHeader={false}

        categoryToVendor={false}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={false}
        key="categoryview"
      />
    </div>
  )
}
