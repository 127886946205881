/**
 * localstorage methods define here
 */
import queryString from 'query-string'

import { CURRENT_SWITCHED_LICENSE } from 'consts'
import { storage } from './storage'

export const lsGetLicenseId = () => localStorage.getItem('CURRENT_SWITCHED_LICENSE')
export const lsSetLicenseId = (licenseId: string) => lsPureSet('CURRENT_SWITCHED_LICENSE', licenseId)

/**
 * 
 * ## localStorge strategy 
 * 
 * 1. 基于`licenseId`存储数据
 * 2. localStorage 多个标签页共享`CURRENT_SWITCHED_LICENSE` 
 * 3. 单个标签页共享`window[CURRENT_SWITCHED_LICENSE]`(即licenseId),
 * 4. 不同license不同tab可独立运行,基于`licenseId`独立存储, 存储数据,token互不干扰
 * 
 */

/**
 * set licenseId to memory cache `window[CURRENT_SWITCHED_LICENSE]`
 * @param value 
 */
export const setLicenseId = (value: string) => {
    window[CURRENT_SWITCHED_LICENSE] = value
}

/** *
 * init window[CURRENT_SWITCHED_LICENSE] from:
 * 1. search path
 * 2. localStorage `CURRENT_SWITCHED_LICENSE`
 * 
 */
export const initLicenseId = () => {

    //= =====================================  init licenseId of `tab memory`   ========================================
    const params: Record<string, any> = queryString.parse(window.location.search)
    if (!window[CURRENT_SWITCHED_LICENSE]) {
        // init current licenseId from search path
        if (params[CURRENT_SWITCHED_LICENSE]) {
            setLicenseId(params[CURRENT_SWITCHED_LICENSE])
        }

        // init current licenseId of `tab memory` from localStorage
        else if (lsGetLicenseId()) {
            setLicenseId(lsGetLicenseId() as string)
        }
    }

    // rm current licenseId in localSroage
    //    localStorage.removeItem('CURRENT_SWITCHED_LICENSE')

}

//= ============================= localstorage ====================================

export const lsPureSet = (key: string, val: string | object) => {
    localStorage.setItem(key, typeof val === 'object' ? JSON.stringify(val) : val)
}

/**
 * storage data with `localStorage`
 * 
 * @param key 
 * @param val 
 * @returns 
 */
export const lsSet = (key: string, val: string | object) => {
    if (!key) return
    try {
        lsRmUndefinedKey(key)
        initLicenseId()
        lsPureSet(`${window[CURRENT_SWITCHED_LICENSE]}-${key}`, typeof val === 'object' ? JSON.stringify(val) : val)
    
        const keyList = Object.keys(localStorage)
        const customerList = keyList
            .filter(item => item.endsWith(storage.LOGIN_TIME))
            .map(item => item.replace(storage.LOGIN_TIME, ''))
        const customers = customerList.filter(c => c !== window[CURRENT_SWITCHED_LICENSE])
            .map(item => {
                const key = `${item}${storage.LOGIN_TIME}`
                return {
                    customerPrefix: item,
                    time: Number(localStorage.getItem(key)),
                }
            })
            .sort((m,n) => n.time - m.time)   // 降序
            .map(item => item.customerPrefix)
        if (customers.length > 4) {
            customers.slice(4).forEach(c => {
                keyList
                    .filter(item => item.startsWith(c))
                    .map(k => localStorage.removeItem(k))
            })
        }
    } catch (e: any) {
        console.warn('e', e)
    }
}

/**
 * get data from `localStorage`
 * @param key 
 * @returns 
 */
export const lsGet = (key: string, parse = true) => {

    if (!key) return null
    initLicenseId()
    const val = localStorage.getItem(`${window[CURRENT_SWITCHED_LICENSE]}-${key}`)
    try {
        if (!parse) return val
        return val && val.indexOf('{') > -1 ? JSON.parse(val) : val
    } catch (e) {
        console.log(e)
        return val
    }

}

/**
 * rm localStorage with key `undefined-${key}`
 * @param key 
 */
export const lsRmUndefinedKey = (key: string) => {
    key = `undefined-${key}`
    if (localStorage.getItem(key)) localStorage.removeItem(key)
}

/**
 * rm localStorage with key
 * @param key 
 * @returns 
 */
export const lsRmItem = (key: string) => {
    if (!key) return
    localStorage.removeItem(key)
}

/**
 * rm localStorage with key `${window[CURRENT_SWITCHED_LICENSE]}-${key}`
 * @param key 
 */
export const lsRm = (key: string) => {
    localStorage.removeItem(`${window[CURRENT_SWITCHED_LICENSE]}-${key}`)
}

//= ============================= session storage ====================================

export const ssRmUndefinedKey = (key: string) => {
    key = `undefined-${key}`
    if (localStorage.getItem(key)) sessionStorage.removeItem(key)
}

export const ssRm = (key: string) => {
    sessionStorage.removeItem(`${window[CURRENT_SWITCHED_LICENSE]}-${key}`)
}

export const ssSet = (key: string, val: string | object) => {
    if (!key) return
    initLicenseId()
    ssRmUndefinedKey(key)
    sessionStorage.setItem(`${window[CURRENT_SWITCHED_LICENSE]}-${key}`, typeof val === 'object' ? JSON.stringify(val) : val)
}

export const ssGet = (key: string) => {

    if (!key) return null
    initLicenseId()

    key = `${window[CURRENT_SWITCHED_LICENSE]}-${key}`
    const val = sessionStorage.getItem(key)

    try {
        return val && val.indexOf('{') > -1 ? JSON.parse(val) : val
    } catch (e) {
        console.log(e)
        return val
    }

}
