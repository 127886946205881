import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'components/form-elements/Button'
import { Dropdown } from 'componentsv2/form-elements/Dropdown'
import { useAdminMetaData } from 'hooks/useAdminMetaData'
import { UNSET } from 'consts'
import { WireframeToolPropsType } from './WireframeTool.type'
import { WireframeDrawer } from './WireframeDrawer'
import { getImgNaturalDimensions } from './utils'
import { Circle } from './Circle'
import styles from './style.module.scss'
import { useIsShowDeleteImageButton } from 'hooks/useIsShowDeleteImageButton'

/**
 * 
 * @param props 
 * @returns 
 */

export function WireframeTool({ url, w = 400, points = [], h = 600, onSubmit, buttonGroupStyle = {} ,setFirstImageFn, deleteImageFn }: WireframeToolPropsType) {

  const { adminMetaData: baseData } = useAdminMetaData()

  //= =========================== canvas ref ===================================
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const wireFrmaeRef = useRef<WireframeDrawer>()

  const initCanvas = useCallback(() => {
    if (!canvasRef.current) return
    try {
      const shapesCanvas = canvasRef.current
      const context: CanvasRenderingContext2D = shapesCanvas.getContext('2d') as CanvasRenderingContext2D

      wireFrmaeRef.current = new WireframeDrawer(context, shapesCanvas,
        points.length ? points.map(item => ({ x: item[0], y: item[1], radius: 5, color: 'transparent', isSelected: false })) : [

        ],

      )
      wireFrmaeRef.current.init()
    } catch (e) {
      console.log(e)
    }
  }, [ points ])

  const [ loaded, setLoaded ] = useState(false)
  const [ wh, setWh ] = useState({ w: 0, h: 0 })

  //= ====================== color report =========================
  const [ colors, setColors ] = useState('')

  useEffect(() => {
    if (loaded)
      initCanvas()
  }, [loaded]) //eslint-disable-line

  const isShowDeleteImageBtn = useIsShowDeleteImageButton()
  
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ height: '100%', maxHeight: 600, position: 'absolute', top: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
        <img
          id="scream"
          alt=""
          className={styles.img}
          src={url}
          width={w}
          onLoad={e => {
            try {
              const { w, h } = getImgNaturalDimensions(document.getElementById('scream') as HTMLImageElement)
              setLoaded(true)
              setWh({ w, h })
            } catch (e) {
              console.log(e)
            }
          }}
        />
      </div>
      <div style={{ height: '100%', maxHeight: 600, position: 'absolute', top: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
        {loaded ? <canvas
          style={{ background: 'transparent', margin:'0 auto' }}
          ref={canvasRef} width={`${wh.w}px`} height={`${wh.h}px`}
                  /> : null}
      </div>
      <div
        style={{
          position: 'absolute', top: -47, left: -40, textAlign: 'left', display: 'flex',
          flexDirection: 'row', ...buttonGroupStyle,
        }}
      >
        <Button
          primary
          style={{ marginRight:5 }}
          onClick={useCallback( () => {
            setFirstImageFn?.()
          },[ setFirstImageFn ])}
        >Set First Image
        </Button>

        {
          isShowDeleteImageBtn && (
            <Button
              primary
              style={{ marginRight:5 }}
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this image?')) {
                  deleteImageFn?.()
                }
              }}
            >Delete Image
            </Button>
          )
        }

        <Button
          primary
          onClick={async () => {
            const circles: Array<Circle> = wireFrmaeRef.current?.getCircles() as Array<Circle>
            if (circles.length > 2) {
              onSubmit?.(wireFrmaeRef.current?.getCircles().map(item => [ parseInt(`${item.x}`, 10), parseInt(`${item.y}`, 10) ]) as Array<[number, number]>, 
              w, (w / wh.w) * wh.h,
              colors,
              )
            } else {
              alert('please provide at least 3 points on the image ~')
            }

          }}
        >submit
        </Button>
        <Button
          secondary
          style={{ margin: '0 5px', width: 80 }}
          onClick={() => wireFrmaeRef.current?.clear()}
        >clear
        </Button>
        <Dropdown
          showSearch={true}
          value={colors}
          customMaxHeight={480}
          options={(baseData.Colors?[ ...baseData.Colors,UNSET ] : [ UNSET ]).map((item: string) => ({ key: item, description: item }))}
          onChange={val => setColors(val as string)}
          placeholder="select here"
        />
      </div>
    </div>
  )
}

WireframeTool.propTypes = {}

