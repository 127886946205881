import React, { useState } from 'react'
import classnames from 'classnames'
import { Flex } from 'druikit'
import { useCategoryValue, useSettingCategoryImages, useSettingCategoryValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import { cloneDeep } from 'lodash'
import styles from './styles.module.scss'
import { ClickAwayListener } from '@material-ui/core'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { LazyBgImg } from 'components/Image'

function padArray(arr, length = 12) {
    let newArr = cloneDeep(arr)
    const newLength = newArr.length <= 6 ? 6 : length
    if (newArr.length < newLength) {
        const padLength = newLength - newArr.length
        newArr.push(...Array(padLength))
    }
    return newArr
}

export const QueryCategory = ({
    isExportReport = false,
}: {
    isExportReport?: boolean;
}) => {
    const [ settingCategoryValue ] = useSettingCategoryValue()
    const [ settingCategoryImages ] = useSettingCategoryImages()
    const [ categoryValue = [], setCategoryValue ] = useCategoryValue()
    const [ categories, setCategories ] = useState<string[]>(categoryValue)

    useDeepCompareEffect(() => {
        setCategories([ ...categoryValue ])
    }, [ categoryValue ])

    const onClickAway = () => {
        if (isExportReport) return
        const newCategoryValue = cloneDeep(categoryValue)
        const newCategories = cloneDeep(categories)
        if (newCategories.sort().toString() === newCategoryValue.sort().toString()) {
            return
        }
        setCategoryValue([ ...newCategories ].filter(item => settingCategoryValue.includes(item)))
    }

    return (
        <ClickAwayListener
            onClickAway={onClickAway}
        >
            <Flex flexWrap="wrap">
                {
                    padArray(settingCategoryValue).map((item, index) => {
                        if (!item) {
                            return (
                                <div
                                    key={index}
                                    className={styles.categoryItem}
                                    style={{ border: 'none', cursor: 'auto' }}
                                    onClick={onClickAway} 
                                />
                            )
                        }

                        const selected = categoryValue.includes(item) && categories.includes(item)
                        // 最近选中的: 背景还是灰色, 对钩是蓝色, 下面有一段文字
                        const isRecentlySelected = categories.includes(item) && !categoryValue.includes(item)
                        // 最近取消选中的：背景是蓝色, 没有对钩, 下面有一段文字
                        const isRecentlyUnselected = categoryValue.includes(item) && !categories.includes(item)

                        const maskCls = classnames({ 
                            [styles.categoryMask]: true, 
                            [styles.selectedCategoryMask]: selected || isRecentlyUnselected,
                        })
                        return (
                            <div
                                key={item}
                                className={styles.categoryItem}
                                onClick={() => {
                                    let newCategories = cloneDeep(categories)
                                    if (!newCategories.includes(item)) {
                                        newCategories.push(item)
                                    } else {
                                        newCategories = newCategories.filter(c => c !== item)
                                    }
                                    setCategories([ ...newCategories ])
                                }}
                            >
                                <div className={styles.categoryInner}>
                                    <LazyBgImg
                                        className={styles.categoryImg}
                                        src={settingCategoryImages[item]}
                                        style={{ width: '100%', height: '100%' }}
                                        cropedImg={false}
                                    />
                                    <div className={maskCls} />
                                    <div className={styles.categoryNameWrapper}>
                                        <div className={styles.categoryName}>{item}</div>
                                    </div>
                                    {
                                        isRecentlySelected || isRecentlyUnselected ? (
                                            <div className={styles.footer}>
                                                Click to execute
                                            </div>
                                        ) : null
                                    }
                                    {
                                        selected || isRecentlySelected ? (
                                            <div className={classnames({ [styles.selectedIcon]: true })}>
                                                <CheckedOutlined color={isRecentlySelected ? '#74B6B6' : '#fff'} />
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </Flex>
        </ClickAwayListener>
    )
}

const CheckedOutlined = ({
    color = '#fff',
}: {
    color?: string;
}) => {

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_0_325" maskUnits="userSpaceOnUse" x="4" y="6" width="18" height="14">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.68557 16.3658L5.60782 12.3L4.21924 13.6748L9.68557 19.125L21.4201 7.42501L20.0413 6.05026L9.68557 16.3658Z" fill={color} />
            </mask>
            <g mask="url(#mask0_0_325)">
                <rect x="-11.8276" y="-12.075" width="48.8938" height="48.75" fill={color} />
                <mask id="mask1_0_325" maskUnits="userSpaceOnUse" x="-12" y="-13" width="50" height="50">
                    <rect x="-11.8276" y="-12.075" width="48.8938" height="48.75" fill={color} />
                </mask>
                <g mask="url(#mask1_0_325)">
                </g>
            </g>
        </svg>
    )
}

function getImageUrl(image, width = 221, height = 195) {
    if (!image) return ''
    return `https://eu-de.functions.cloud.ibm.com/api/v1/web/6435fd49-9174-4454-97b4-a15a66b7c78a/ubuntu/image?image=${image}&width=${width}&height=${height}`
}
