import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { CATEGORY_TOTAL, MARKET_VENDOR, NA, SELECTED_CATEGORY, SELECTED_VENDORS, SUMMARY } from 'consts'
import { cloneDeep, uniq } from 'lodash'
import { numberUtils } from 'norna-uikit'
import { getVendorNameByCode } from 'utils'
import { difference, sortVendorList } from 'utils/array'
import { getTotalCategoryLabel, getTotalVendorLabel, handleTableExpanded } from 'utils/dashboardPageUtils'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { whetherLock } from 'utils/lockSellerUtils'
import { storage } from 'utils/storage'

interface ApiData {
    [vendor: string]: {
        [category: string]: {
            [field: string]: any;
        }
    }
}

export const handleTableData = ({
    apiData,
    isCompetitorView,
    competitorValue,
    categoryValue,
    expandedRowKeys = [],
    subExpandedRowKeysRef = [],
    region,
}: {
    apiData: ApiData,
    isCompetitorView: boolean;
    competitorValue: string[];
    categoryValue: string[];
    expandedRowKeys: string[];
    subExpandedRowKeysRef: string[];
    region: string;
}): any => {
    if (!apiData || !Object.keys(apiData || {})?.length) {
        return {
            tabledata: [],
            metricsTab: [],
        }
    }
    apiData = { ...apiData }

    let totalVendorLabel = getTotalVendorLabel(apiData)
    const totalCategoryLabel = getTotalCategoryLabel(apiData[competitorValue[0]])
    const sellers = storage.getSellers()

    if (!storage.getIsLookbook()) {
        if (isCompetitorView) {
            difference(Object.keys(apiData), competitorValue).forEach(vendor => {
                delete apiData[vendor]
            })
        } else {
            difference(Object.keys(apiData), uniq([ ...competitorValue, SELECTED_VENDORS ])).forEach(vendor => {
                delete apiData[vendor]
            })
            if (!competitorValue.includes(SELECTED_VENDORS) && apiData[SELECTED_VENDORS]) {
                apiData[SUMMARY] = apiData[SELECTED_VENDORS]
                delete apiData[SELECTED_VENDORS]
                totalVendorLabel = SUMMARY
            }
        }
    }

    const tabledata: any = []
    const metricsTab: any[] = []

    const categoryTreeObj = storage.getCategoryTreeObj()
    const firstLevelCategoryList = getFirstLevelCategories({ selectedCategories: categoryValue })
    const categoryKeys = [ ...categoryValue ]

    // vendor 进行排序
    let vendorList = Object.keys(apiData)
    vendorList = sortVendorList({ vendorList })
    
    // competitor 视图
    if (isCompetitorView) {
        const metricsChildren: any[] = []

        vendorList.forEach(vendor => {
            const queryDateValid = isQueryDateValid({ vendor, region, competitorValue })
            const queryComparisonDateValid = isQueryDateValid({ vendor, region, competitorValue, isComparisonDate: true })
            const isLock = whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue })

            const vendorName = getVendorNameByCode(vendor)
            const vendorData = apiData[vendor]

            const firstLevelExpandId = `${vendor}_${SELECTED_CATEGORY}`

            // 第 0 级
            const level0Children: any[] = []
            firstLevelCategoryList.forEach(category => {
                const level1Children: any[] = []
                if (categoryTreeObj[category]?.list?.length) {
                    const secondLevelExpandId = `${vendor}_${category}`
                    metricsChildren.push({
                        expandId: `${firstLevelExpandId}__${secondLevelExpandId}`,
                        active: false,
                        code: vendor,
                        level: 1,
                        categoryKey: category,
                        title: vendorName,
                        categorycount: categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name)).length,
                    })

                    // 第 2 级
                    categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name))?.forEach(subcategoryObj => {
                        const subcategory = subcategoryObj.name
                        const level2TreeItemData = {
                            ...vendorData[subcategory],
                            level: 2,
                            vendorName,
                            vendorCode: vendor,
                            isLock,
                            categoryName: subcategory,
                            queryDateValid,
                            queryComparisonDateValid,
                            optionsNum: vendorData[subcategory]?.Numbers?.value || 0,
                        }
                        level1Children.push(level2TreeItemData)
                    })
                }

                // 第 1 级
                const level1TreeItemData = {
                    ...vendorData[category],
                    children: level1Children,
                    level: 1,
                    vendorName,
                    vendorCode: vendor,
                    isLock,
                    categoryName: category,
                    queryDateValid,
                    queryComparisonDateValid,
                    optionsNum: vendorData[category]?.Numbers?.value || 0,
                }
                level0Children.push(level1TreeItemData)
            })

            // 第 0 级, All categories
            const lavel0TreeItemData = {
                ...vendorData[totalCategoryLabel],
                children: level0Children,
                level: 0,
                vendorName,
                vendorCode: vendor,
                isLock,
                categoryName: totalCategoryLabel,
                queryDateValid,
                queryComparisonDateValid,
                optionsNum: vendorData[totalCategoryLabel]?.Numbers?.value || 0,
            }
            tabledata.push(lavel0TreeItemData)

            metricsTab.push({
                expandId: firstLevelExpandId,
                active: false,
                code: vendor,           // 小写名称
                title: vendorName,      // 大写名称
                categoryKey: totalCategoryLabel,        // category 名称
                level: 0,
                children: metricsChildren.filter(c => c.code === vendor),
                categorycount: firstLevelCategoryList.length + 1,
            })
        })
    }

    // category 视图
    else {
        const vendorDateValidMapper = {}
        vendorList.forEach(vendor => {
            vendorDateValidMapper[vendor] = {
                queryDateValid: isQueryDateValid({ vendor, region, competitorValue }),
                queryComparisonDateValid: isQueryDateValid({ vendor, region, competitorValue, isComparisonDate: true }),
                isLock: whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue }),
            }
        })

        // totalCategoryLabel 放在数组最后面, 对应页面上表格最后一列
        const categoryList = [ ...firstLevelCategoryList, totalCategoryLabel ]
        categoryList.forEach(category => {
            const level0ChildrenData: any[] = []
            const level0Children: any[] = []
            const metricsChildren: any[] = []

            vendorList.forEach(vendor => {
                const queryDateValid = vendorDateValidMapper[vendor]?.queryDateValid
                const queryComparisonDateValid = vendorDateValidMapper[vendor]?.queryComparisonDateValid
                const isLock = vendorDateValidMapper[vendor]?.isLock
                
                const vendorName = getVendorNameByCode(vendor)
                const vendorData = apiData[vendor]
                const rowData = { ...vendorData[category] }

                // 第 1 级
                if (vendor !== totalVendorLabel) {
                    const level1TreeItemData = {
                        ...rowData,
                        level: 1,
                        vendorName,
                        vendorCode: vendor,
                        isLock,
                        categoryName: category,
                        queryDateValid,
                        queryComparisonDateValid,
                        optionsNum: rowData?.Numbers?.value || 0,
                    }
                    level0Children.push(level1TreeItemData)
                }
                // 第 0 级
                else {
                    tabledata.push({
                        ...rowData,
                        level: 0,
                        children: level0Children,
                        childrenData: level0ChildrenData,
                        isLock,
                        vendorCode: vendor,
                        vendorName,
                        categoryName: category,
                        queryDateValid,
                        queryComparisonDateValid,
                        optionsNum: rowData?.Numbers?.value || 0,
                    })
                }
            })

            // 构造 childrenData 数据
            const categoryValue = categoryTreeObj[category]
            const firstLevelExpandId = [ CATEGORY_TOTAL, SELECTED_CATEGORY ].includes(category) ? SELECTED_CATEGORY : category
            if (categoryValue?.list?.filter(item => categoryKeys.includes(item.name))?.length) {
                categoryValue.list.filter(item => categoryKeys.includes(item.name)).forEach(subcategory => {
                    const children: any[] = []

                    vendorList.forEach(vendor => {
                        const queryDateValid = vendorDateValidMapper[vendor]?.queryDateValid
                        const queryComparisonDateValid = vendorDateValidMapper[vendor]?.queryComparisonDateValid
                        const isLock = vendorDateValidMapper[vendor]?.isLock

                        const vendorName = getVendorNameByCode(vendor)
                        const vendorData = apiData[vendor]
                        const rowData = { ...vendorData[subcategory.name] }

                        // 第 1 级
                        if (vendor !== totalVendorLabel) {
                            children.push({
                                ...rowData,
                                vendorCode: vendor,
                                vendorName,
                                isLock,
                                categoryName: subcategory.name,
                                parentCategoryName: category,
                                queryDateValid,
                                queryComparisonDateValid,
                                optionsNum: rowData?.Numbers?.value || 0,
                            })
                        }
                        // 第 0 级
                        else {
                            level0ChildrenData.push({
                                ...rowData,
                                level: 1,
                                children,
                                vendorCode: vendor,
                                vendorName,
                                isLock,
                                categoryName: subcategory.name,
                                parentCategoryName: category,
                                queryDateValid,
                                queryComparisonDateValid,
                                optionsNum: rowData?.Numbers?.value || 0,
                            })
                        }
                    })

                    metricsChildren.push({
                        expandId: `${firstLevelExpandId}__${subcategory.name}`,
                        active: false,
                        code: subcategory.name,
                        level: 1,
                        pTitle: category,
                        title: subcategory.name,
                        categorycount: vendorList.length,
                    })
                })
            }

            metricsTab.push({
                expandId: firstLevelExpandId,
                active: false,
                code: category,       
                title: category,      
                level: 0,
                children: metricsChildren,
                categorycount: vendorList.length,
            })
        })
    }

    handleTableExpanded(metricsTab, expandedRowKeys, subExpandedRowKeysRef)

    return {
        tabledata,
        metricsTab,
    }
}

export const handleTopKpiData = ({ data, filterCountry }) => {
    data = cloneDeep(data)

    const cusomterVendor = storage.getCustomerVendor()
    const totalVendorLabel = getTotalVendorLabel(data)
    const totalCategoryLabel = getTotalCategoryLabel(data[cusomterVendor])
    const marketData = data[totalVendorLabel]?.[totalCategoryLabel] || {}
    const vendorData = data[cusomterVendor]?.[totalCategoryLabel] || {}
    const comparisonQueryDateValid = isQueryDateValid({ 
        vendor: MARKET_VENDOR.vendor, 
        region: filterCountry, 
        isComparisonDate: true,
        isValidWhenLaunchDateNotExist: true,
    })

    const horizontalMaxValue = Math.max(...[
        vendorData?.option_ratio?.value || 0,
        (vendorData?.option_ratio?.value || 0) - (vendorData?.option_ratio?.change || 0),
        vendorData?.size_ratio?.value || 0,
        (vendorData?.size_ratio?.value || 0) - (vendorData?.size_ratio?.change || 0),
    ]) / 0.5

    const verticalMaxValue = Math.max(...[
        vendorData?.Numbers?.value || 0,
        (vendorData?.Numbers?.value || 0) - (vendorData?.Numbers?.change || 0),
    ]) / 0.7

    return {
        marketData: {
            // options
            optionsCurrentValue: marketData?.Numbers?.value || 0,
            optionsComparisonValue: (marketData?.Numbers?.value || 0) - (marketData?.Numbers?.change || 0),
            optionsChangePercent: comparisonQueryDateValid ? marketData?.Numbers?.change_percent || 0 : NA,
            // optionRatio
            optionRatioCurrentValue: marketData?.option_ratio?.value || 0,
            optionRatioComparisonValue: (marketData?.option_ratio?.value || 0) - (marketData?.option_ratio?.change || 0),
            optionRatioChangePercent: comparisonQueryDateValid ? marketData?.option_ratio?.change_percent || 0 : NA,
            // sizeRatio
            sizeRatioCurrentValue: marketData?.size_ratio?.value || 0,
            sizeRatioComparisonValue: (marketData?.size_ratio?.value || 0) - (marketData?.size_ratio?.change || 0),
            sizeRatioChangePercent: comparisonQueryDateValid ? marketData?.size_ratio?.change_percent || 0 : NA,
            // ecoLabel
            ecoLabelCurrentValue: marketData?.eco_label?.value || 0,
            ecoLabelComparisonValue: (marketData?.eco_label?.value || 0) - (marketData?.eco_label?.change || 0),
            ecoLabelChangePercent: comparisonQueryDateValid ? marketData?.eco_label?.change || 0 : NA,
            // soldOut
            soldOutCurrentValue: marketData?.out_of_stock_percentage?.value || 0,
            soldOutComparisonValue: (marketData?.out_of_stock_percentage?.value || 0) - (marketData?.out_of_stock_percentage?.change || 0),
            soldOutChangePercent: comparisonQueryDateValid ? marketData?.out_of_stock_percentage?.change || 0 : NA,
            // solid
            solidCurrentValue: marketData?.solid_pattern?.value || 0,
            solidComparisonValue: (marketData?.solid_pattern?.value || 0) - (marketData?.solid_pattern?.change || 0),
            solidChangePercent: comparisonQueryDateValid ? marketData?.solid_pattern?.change || 0 : NA,
        },
        customerData: {
            // options
            optionsCurrentValue: vendorData?.Numbers?.value || 0,
            optionsComparisonValue: (vendorData?.Numbers?.value || 0) - (vendorData?.Numbers?.change || 0),
            optionsChangePercent: vendorData?.Numbers?.change_percent || 0,
            optionsMaxValue: verticalMaxValue || 0,
            // optionRatio
            optionRatioCurrentValue: vendorData?.option_ratio?.value || 0,
            optionRatioComparisonValue: (vendorData?.option_ratio?.value || 0) - (vendorData?.option_ratio?.change || 0),
            optionRatioChangePercent: vendorData?.option_ratio?.change_percent || 0,
            optionRatioMaxValue: horizontalMaxValue || 0,
            // sizeRatio
            sizeRatioCurrentValue: vendorData?.size_ratio?.value || 0,
            sizeRatioComparisonValue: (vendorData?.size_ratio?.value || 0) - (vendorData?.size_ratio?.change || 0),
            sizeRatioChangePercent: vendorData?.size_ratio?.change_percent || 0,
            sizeRatioMaxValue: horizontalMaxValue || 0,
            // ecoLabel
            ecoLabelCurrentValue: vendorData?.eco_label?.value || 0,
            ecoLabelComparisonValue: (vendorData?.eco_label?.value || 0) - (vendorData?.eco_label?.change || 0),
            ecoLabelChangePercent: vendorData?.eco_label?.change || 0,
            // soldOut
            soldOutCurrentValue: vendorData?.out_of_stock_percentage?.value || 0,
            soldOutComparisonValue: (vendorData?.out_of_stock_percentage?.value || 0) - (vendorData?.out_of_stock_percentage?.change || 0),
            soldOutChangePercent: vendorData?.out_of_stock_percentage?.change || 0,
            // solid
            solidCurrentValue: vendorData?.solid_pattern?.value || 0,
            solidComparisonValue: (vendorData?.solid_pattern?.value || 0) - (vendorData?.solid_pattern?.change || 0),
            solidChangePercent: vendorData?.solid_pattern?.change || 0,
        },
    }
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    isCompetitorView = true,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        isCompetitorView ? 'Vendors' : 'Categories',	
        'Options',	
        '# +/- %',	
        isCompetitorView ? 'Category' : 'Vendor',	
        'Category split',	
        'Units +/- %',	
        'Size of line',	
        'Units +/- %',	
        'Size ratio',	
        '+/- %',	
        'Option ratio',	
        '+/- %',	
        'Sold out',	
        'Units +/- %',	
        'Eco label',	
        'Units +/- %',
        'Solid',	
        'Units +/- %',
    ]
    data.push(titleRow)

    const handleRowData = (item) => {
        return [
            isCompetitorView ? item?.vendorName : item?.categoryName,
            item?.Numbers?.value,
            numberUtils.formatNumber(item?.Numbers?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            isCompetitorView ? item?.categoryName : item?.vendorName,
            numberUtils.formatNumber(item?.category_fraction?.value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.category_fraction?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.size_of_line?.value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.size_of_line?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.size_ratio?.value, { decimal: 1 }),
            numberUtils.formatNumber(item?.size_ratio?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.option_ratio?.value, { decimal: 1 }),
            numberUtils.formatNumber(item?.option_ratio?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.out_of_stock_percentage?.value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.out_of_stock_percentage?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.eco_label?.value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.eco_label?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.solid_pattern?.value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.solid_pattern?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
        ]
    }

    dataSource.forEach(item => {
        const dataRow = handleRowData(item)
        data.push(dataRow)

        if (item?.children?.length) {
            item.children.forEach(item2 => {
                const dataRow2 = handleRowData(item2)
                data.push(dataRow2)

                if (item2?.children?.length) {
                    item2.children.forEach(item3 => {
                        const dataRow3 = handleRowData(item3)
                        data.push(dataRow3)
                    })
                }
            })
        }
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
