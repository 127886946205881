import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'components/layout'
import Loadable from 'react-loadable'
import { Header } from 'components/layout/Header'
import { Redirect, Route, Switch } from 'react-router-dom'
import { isDashboardPages, isDynamicDashboardPage, RoutesObj } from 'global/Routes'
import { NoData } from 'components/NoData/NoData'
import { cloneDeep, isEmpty } from 'lodash'
import { ProgressBarContext } from 'components/ProgressBar/ProgressBarContext'
import { SearchForOptions } from 'pages/SearchForOptions'
import { SizeOfLine } from 'pages/SizeOfLine'
import { CrossRegionPricing } from 'pages/CrossRegionPricing'
import CrossRegionSoldOut from 'pages/CrossRegionSoldOut'
import { CategorySplit } from 'pages/CategorySplit'
import { ColorSplit } from 'pages/ColorSplit'
import { MaterialSplit } from 'pages/MaterialSplit'
import { MySavedFilters } from 'pages/MySavedFilters'
import { AttributeRanking } from 'pages/AttributeRanking'
import { ProductRanking } from 'pages/ProductRanking'
import { PriceHistoryPage } from 'pages/PriceHistory'
import { PriceDriversPeriodToPeriod } from 'pages/PriceDriversPeriodToPeriod'
import { PriceArchitecture } from 'pages/PriceArchitecture'
import { Discounts } from 'pages/Discounts'
import { OptionsInAndOutByCalendar } from 'pages/OptionsInAndOutByCalendar'
import { Loading } from 'components/'
import { MenuBar } from 'componentsv2/'
import { ObjectProps } from 'types/'
import { HideWhenOpenInNew, whetherOpenInNew } from 'componentsv2/business/OpenInNewButton'
import { PriceInsight } from 'pages/PriceInsight'
import { AssortmentInsight } from 'pages/AssortmentInsight'
import { PriceSpreads } from 'pages/PriceSpreads'
import { InOutMonitoring } from 'pages/InOutMonitoring'
import { MostPopularLaunchDays } from 'pages/MostPopularLaunchDays'
import SoldOutAnalyticsVendors from 'pages/SoldOutAnalyticsVendors'
import { DynamicDashboard } from 'pages/DynamicDashboard'
import { useDispatch, useSelector } from 'react-redux'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { selectFilterCountry, selectVendor, setFilterCountryAndCompetitors, updateFilterForDashboard } from 'features/filters/filtersSlice'
import { useSellers } from 'graphql/sellers/getSellers'
import { ALL_COUNTRIES, IS_LIVE_ENV } from 'consts'
import { MenuBarDisplay } from 'componentsv2/MenuBar/MenuBarDisplay'
import classNames from 'classnames'
import { MyLookbooks } from 'pages/MyLookbooks'
import { useLookbook } from 'hooks/useLookbook'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { storage } from 'utils/storage'
import { useFilterCategory, useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { getFirstLevelCategories, getLeafCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { getFilterCategoryMaxCountInLive } from 'utils'
import { intersection } from 'utils/array'
import { arrayUtils } from 'norna-uikit'
import { judgeCompetitorStatusFromPreviousCountry } from 'components/layout/Header/components/HeaderFilter/competitor.util'
import { LookbookPage } from 'pages/Lookbook'
import { getQaData } from 'features/filters/qaDataSlice'
import { NavigationBarContainer } from './NavigationBarContext'
import { useAuthenticationDataContext } from '../../components/guards/AuthenticationContext'
import { SignIn } from '../SignIn'
import { useReadCache } from '../../hooks/useReadCache'
import { ForgotPassword } from '../ForgotPassword'
import { SnackInfo } from '../../componentsv2/SnackInfo'
import styles from './styles.module.scss'
import TestPage from 'pages/Test'
import PlatformAnalytics from 'pages/PlatformAnaltyics'
import ChatBox from 'componentsv2/ChatBox'
import DynamicDashboardReport from 'pages/DynamicDashboard/DynamicDashboardReport'
import LineGraphReport from 'pages/LineGraphReport'

export const Home = () => {
  const [ loadingBar, setLoadingBar ] = useState(null)
  const { user, publicRoute } = useAuthenticationDataContext()
  const { isReady, loading, sharedFilterInfo } = useReadCache(user)
  const [ filterCountry ] = useFilterCountry()

  const snackRef = React.useRef<ObjectProps>({})

  React.useEffect(() => {
    if (sharedFilterInfo) snackRef.current?.open?.(sharedFilterInfo)
  }, [ sharedFilterInfo, snackRef ])

  // -----------------------  Event Mock Before Nav route  --------------------------------
  const { regions } = useSelector(getCacheData)
  const region = useSelector(selectFilterCountry)
  const vendors = useSelector(selectVendor)
  const dispatch = useDispatch()
  const [ currentUrl, setCurrentUrl ] = useState('')
  const { filterVendorEuropeOrCountry } = useSellers([])

  const updateAllCountry = useCallback(() => {
    // update region and competitors
    const region = regions.length ? regions[0] : ''
    const sellers = filterVendorEuropeOrCountry(region)
    const allSellers = filterVendorEuropeOrCountry(ALL_COUNTRIES)
    const competitors = cloneDeep(sellers).filter(item => judgeCompetitorStatusFromPreviousCountry(vendors.map(vendor => vendor.vendor), allSellers.map(allVendor => allVendor.key), item.key)).map(item => ({
      vendor: item.key,
      region,
    }))
    dispatch(setFilterCountryAndCompetitors({ region, competitors }))
  }, [ dispatch, regions, vendors, filterVendorEuropeOrCountry ])

  const updateCurrentUrl = useCallback((url: string) => {
    if (url !== currentUrl) {
      /** *
       * 
       * Set the first available region as the `Filter.region` value when user is 
       * jumping out `RoutesObj.SEARCH_OPTIONS` functionality
       * and the selected region is `ALL_COUNTRIES` 
       */
      if (![ RoutesObj.SEARCH_OPTIONS.URL ].some(item => url.startsWith(item)) && [ RoutesObj.SEARCH_OPTIONS.URL ].some(item => currentUrl.startsWith(item))) {
        if (region === ALL_COUNTRIES) {
          updateAllCountry()
        }
      }
      setCurrentUrl(url)
      //--------------------------------------------------
      // Case: 
      // 1. 用户在`RoutesObj.SEARCH_OPTIONS.URL`选择了ALL_COUNTRIES ，并在此功能页推出
      // 2. 通过网址进入新的非`RoutesObj.SEARCH_OPTIONS.URL`功能页, region 可能会从LocaleStorage 中读取`ALL_COUNTRIES`的情况
    } else if (region === ALL_COUNTRIES && ![ RoutesObj.SEARCH_OPTIONS.URL ].some(item => url.startsWith(item))) {
      updateAllCountry()
      setCurrentUrl(url)
    }
  }, [ region, setCurrentUrl, currentUrl, updateAllCountry ])

  /* *********************** 监听并更新 localStorage 中的 isLookbook 变量 ***************************** */
  const { checked } = useLookbook()

  useDeepCompareEffect(() => {
    storage.setIsLookbook(!!checked?.length)
  }, [ checked ])

  /* *********************** 监听 qa ***************************** */
  const qaData = useSelector(getQaData)

  useEffect(() => {
    storage.setIsGate(!!qaData?.qaPage)
  }, [ qaData ])

  /* *********************** 监听 Filter category 变化 ***************************** */
  const [ filterCategory, setFilterCategory ] = useFilterCategory()

  useDeepCompareEffect(() => {
    if (IS_LIVE_ENV && !isDashboardPages() && (getFirstLevelCategories({ selectedCategories: filterCategory })?.length > 2 && !isDynamicDashboardPage())) {
      setFilterCategory(intersection(getLeafCategories({ selectedCategories: getFirstLevelCategories({ selectedCategories: filterCategory }).slice(0, getFilterCategoryMaxCountInLive()) }), filterCategory))
    }
    // eslint-disable-next-line
  }, [ window.location.pathname, filterCategory ])

  /* *********************** 监听 filter > competitor 变化 ***************************** */
  const [ filterCompetitor, setFilterCompetitor ] = useFilterCompetitor()

  useDeepCompareEffect(() => {
    if (!filterCompetitor?.length) {
      setFilterCompetitor([ { vendor: storage.getCustomerVendor(), region: filterCountry } ])
    }
  }, [ filterCompetitor ])

  /* *********************** 监听 pathname 变化 ***************************** */
  useEffect(() => {
    if (window.location.pathname === '/') return
    let pathnameQueue = storage.getPathnameQueue()
    // 往前面加一个值
    pathnameQueue.unshift(window.location.pathname)
    pathnameQueue = pathnameQueue.slice(0, 2)
    storage.setPathnameQueue(pathnameQueue)
    // eslint-disable-next-line
  }, [ window.location.pathname ])

  useEffect(() => {
    if (window.location.pathname === '/') return
    const pathnameQueue = storage.getPathnameQueue()
    if (!pathnameQueue?.length) return
    const currentPagePathname = arrayUtils.first(pathnameQueue)
    const prevPagePathname = arrayUtils.last(pathnameQueue)

    if (isDashboardPages(currentPagePathname) && prevPagePathname === currentPagePathname) {
      dispatch(updateFilterForDashboard('refresh'))
    }
    
    // eslint-disable-next-line
  }, [ window.location.pathname ])

  // -----------------------  Logined or not --------------------------------
  if (isEmpty(user) || !user.email) {
    return publicRoute === RoutesObj.FORGOT_PASSWORD.URL ? <ForgotPassword /> : <SignIn />
  }

  // 是否是通过 Open in new 按钮打开的新页面
  const openInNew = whetherOpenInNew()

  if (loading || !isReady) {
    return <Loading />
  };

  // --------------- update current pathname --------------------
  updateCurrentUrl(window.location.pathname)

  return (
    <ProgressBarContext.Provider
      value={{
        loadingBar,
        setLoadingBar,
        snackOpen: msg => snackRef.current?.open?.(msg),
      }}
    >
      <NavigationBarContainer>
        <Container sty={{ marginLeft:85 }}>
          <SnackInfo ref={snackRef} />
          <HideWhenOpenInNew>
            <Header />
            <MenuBarDisplay />
            <MenuBar />
          </HideWhenOpenInNew>
          {/* FilterTags 有根据内容高度的变化，设置 `page-container` paddingTop的依赖 */}
          <div className={classNames(styles['page-container'],'page-container')} style={{ marginTop: openInNew ? '10px' : '77px', overflowY: 'scroll' }}>
            {
              window.location.host !== 'analytics.norna.ai' ? <ChatBox /> : null
            }
              <Switch>
                <Route path="/" exact >
                  <Redirect to={RoutesObj.DYNAMIC_DASHBOARD.URL} />
                </Route>
                <Route path={RoutesObj.DASHBOARD.URL} exact >
                  <Redirect to={RoutesObj.DYNAMIC_DASHBOARD.URL} />
                </Route>
                <Route path="/ASCOKmtVmiwlNUHH" component={TestPage} />
                <Route path={RoutesObj.PLATFORM_ANALYTICS.URL} component={PlatformAnalytics} />
                <Route path={RoutesObj.MY_SAVED_FILTERS.URL} component={MySavedFilters} />
                <Route path={RoutesObj.LOOKBOOL_LIST.URL} component={MyLookbooks} />
                <Route path={RoutesObj.CATEGORY_ANALYTICS.URL} component={CategorySplit} />
                <Route path={RoutesObj.COLOR_ANALYTICS.URL} component={ColorSplit} />
                <Route path={RoutesObj.MATERIAL_ANALYTICS.URL} component={MaterialSplit} />
                <Route path={RoutesObj.POPULAR_PRODUCTS.URL} component={ProductRanking} />
                <Route path={RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL} component={MostPopularLaunchDays} />
                <Route path={RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL} component={OptionsInAndOutByCalendar} />
                <Route path={RoutesObj.ASSORTMENT_SIZE.URL} component={SizeOfLine} />
                <Route path={RoutesObj.PRICING_JUMP_TABLE.URL} component={CrossRegionPricing} />
                <Route path={RoutesObj.CROSS_REGION_SOLD_OUT.URL} component={CrossRegionSoldOut} />
                <Route path={RoutesObj.POPULAR_ATTRIBUTES.URL} component={AttributeRanking} />
                <Route path={RoutesObj.PRICE_ARCHITECTURE.URL} component={PriceArchitecture} />
                <Route path={RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL} component={Discounts} />
                <Route path={RoutesObj.PRICE_HISTORY.URL} component={PriceHistoryPage} />
                <Route path={RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL} component={PriceDriversPeriodToPeriod} />
                <Route path={RoutesObj.PRICE_INSIGHT.URL} component={PriceInsight} />
                <Route path={RoutesObj.ASSORTMENT_INSIGHT.URL} component={AssortmentInsight} />
                <Route path={RoutesObj.PRICE_SPREADS.URL} component={PriceSpreads} />
                <Route path={RoutesObj.IN_OUT_MONITORING.URL} component={InOutMonitoring} />
                <Route path={RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.URL} component={SoldOutAnalyticsVendors} />
                <Route path={RoutesObj.DYNAMIC_DASHBOARD.URL} component={DynamicDashboard} />
                <Route path={RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL} component={DynamicDashboardReport} />
                <Route path={RoutesObj.LINE_GRAPH_REPORT.URL} component={LineGraphReport} />
                <Route
                  path={`${RoutesObj.COLLECTION.URL}/:id/:comparisonId`} component={SearchForOptions}
                />
                <Route
                  path={RoutesObj.PROFILE.URL}
                  component={Loadable({
                    loader: () => import('../Profile/Profile').then(e => e.ProfilePage),
                    loading: Loading,
                  })}
                />
                <Route
                  path={`${RoutesObj.LOOKBOOK.URL}/:lookbookId/:lookbookComparisonId`}
                  component={LookbookPage}
                />
                <Route component={() => <NoData>Not found...</NoData>} />
              </Switch>
          </div>
        </Container>
      </NavigationBarContainer>
    </ProgressBarContext.Provider>
  )
}
