import React, { useEffect, useState } from 'react'
import { Flex } from 'componentsv2/Flex'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import { NA, OPTIONS_IN_NAME } from 'consts'
import useDeepCompareEffect from 'use-deep-compare-effect'
import classnames from 'classnames'
import { Spin } from 'componentsv2'
import { NornaTab, NornaTable, TableCategoryCell, TableCell, TableOptionsCell, Th } from 'componentsv2/TradingTableV2'
import { SizedBox } from 'componentsv2/SizedBox'
import { ColumnsType } from 'componentsv2/TradingTableV2/types'
import { useLoadingBar } from 'hooks/useLoadingBar'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { WEEKLY_TABLE_NAME } from 'configs'
import { appendDialogTitlePriceFilter, getExportId } from 'utils'
import { DashboardTableForm } from 'componentsv2/business/DashboardTableForm'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterPricePerspective } from 'hooks/useFilter'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { usePageDate } from 'hooks/usePageDate'
import { useWeeklyData } from 'hooks/api/useWeeklyData'
import { Switch } from 'componentsv2/form-elements/Switch'
import { Progress } from 'componentsv2/Progress'
import { useDashboardFetchInOutProducts } from 'hooks/api/useDashboard'
import { ProductsModal } from 'components/ProductsModalV2'
import { formatDateStr2 } from 'utils/dateUtils'
import { cloneDeep } from 'lodash'
import { sortVendorList } from 'utils/array'
import { downloadFileByAxios } from 'export'
import { mostPopularLaunchDaysExportFilename } from 'utils/exportUtils'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import { useLookbook } from 'hooks/useLookbook'
import { geneCsvData, handleTableData } from './utils'
import * as config from './config'
import styles from './styles.module.scss'
import { getExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_MOST_POPULAR_LAUNCH_DAYS } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'

const exportId = getExportId(WEEKLY_TABLE_NAME)

export const MostPopularLaunchDays = () => {
    useLoadingBar()
    const [ filterCountry ] = useFilterCountry()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterCategory ] = useFilterCategory()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { pageDate } = usePageDate()
    const [ switchValue, setSwitchValue ] = useState(false)
    const [ expandedRowKeys, setExpandedRowKeys ] = useState<string[]>([])
    const { isLookbook } = useLookbook()

    /* ******************************** 表格 *********************************** */
    const [ metricsTab, setMetricsTab ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata, setTabledata ] = useState<Array<any>>([])
    const [ tablecolumns, setTablecolumns ] = useState<ColumnsType[]>([])
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || metricsTab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)

        const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
        setExpandedRowKeys([ ...expandedKeys ])
    }

    useEffect(() => {
        metricTabFn(null)
        // eslint-disable-next-line
    }, [])

    const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

    /* ************************************************************ */
    const { fetchData: fetchInOutProductData } = useDashboardFetchInOutProducts()

    const onShowInOutProductModal = async (record: any, type = OPTIONS_IN_NAME) => {
        const categoryName = record.categoryName
        const vendorCode = record.vendorCode
        const vendorName = record.vendorName
        const metric = type === OPTIONS_IN_NAME ? 'Options in' : 'Options out'
        const ids = await fetchInOutProductData({ category: categoryName, competitor: vendorCode, metric })
        if (!ids.length) return
        const dialogId = `${type}-${vendorCode}-${categoryName}`
        const closeFn = dialogRef.current?.closeDialog
        const title = [ vendorName, categoryName, type ].filter(item => item).join(', ')
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
                productUrls={ids}
                onClose={() => closeFn(dialogId)}
                headerLeading={numberUtils.formatNumberByComma(ids.length)}
                headerTitle={`${appendDialogTitlePriceFilter(title, filterPricePerspective)}`}
                headerDate={formatDateStr2(pageDate, true)}
            />
        ))
    }

    const columns: any[] = [
        {
            dataIndex: 'options',
            width: config.optionsWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell isDeepBorderRight />
                    )
                }

                const options = numberUtils.formatNumber(record?.Numbers?.value, { isCommaSymbol: true, decimal: 0 })
                return (
                    <TableOptionsCell
                        text={options}
                        record={record}
                        width={config.optionsWidth}
                        metricName="All"
                        isDeepBorderRight
                        onClick={async () => {
                            await onShowInOutProductModal(record, OPTIONS_IN_NAME)
                        }}
                    />
                )
            },
        },
        {
            dataIndex: 'categoryKey',
            width: config.categoryWidth,
            render: (text, record) => {
                return (
                    <TableCategoryCell
                        text={record.categoryName}
                        record={record}
                        width={config.categoryWidth}
                        isDeepBorderRight
                    />
                )
            },
        },
        {
            dataIndex: 'monday',
            width: config.weekdayWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell />
                    )
                }

                return (
                    <TableWeekdayCell
                        record={record}
                        switchValue={switchValue}
                        weekday="Monday"
                        isFirstMetric
                    />
                )
            },
        },
        {
            dataIndex: 'tuesday',
            width: config.weekdayWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell />
                    )
                }

                return (
                    <TableWeekdayCell
                        record={record}
                        switchValue={switchValue}
                        weekday="Tuesday"
                    />
                )
            },
        },
        {
            dataIndex: 'wednesday',
            width: config.weekdayWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell />
                    )
                }

                return (
                    <TableWeekdayCell
                        record={record}
                        switchValue={switchValue}
                        weekday="Wednesday"
                    />
                )
            },
        },
        {
            dataIndex: 'thursday',
            width: config.weekdayWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell />
                    )
                }

                return (
                    <TableWeekdayCell
                        record={record}
                        switchValue={switchValue}
                        weekday="Thursday"
                    />
                )
            },
        },
        {
            dataIndex: 'friday',
            width: config.weekdayWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell />
                    )
                }

                return (
                    <TableWeekdayCell
                        record={record}
                        switchValue={switchValue}
                        weekday="Friday"
                    />
                )
            },
        },
        {
            dataIndex: 'saturday',
            width: config.weekdayWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell />
                    )
                }

                return (
                    <TableWeekdayCell
                        record={record}
                        switchValue={switchValue}
                        weekday="Saturday"
                    />
                )
            },
        },
        {
            dataIndex: 'sunday',
            width: config.weekdayWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell />
                    )
                }
                
                return (
                    <TableWeekdayCell
                        record={record}
                        switchValue={switchValue}
                        weekday="Sunday"
                    />
                )
            },
        },
    ]

    /* ******************************************************************* */
    const { data, loading, fetchPayload } = useWeeklyData()
    const [ , setCsvData ] = useCsvDataModuleData()

    const handleData = (data: any) => {
        // 数据不存在直接返回
        if (!data) {
            return {}
        }

        const {
            tabledata,
            metricsTab,
        } = handleTableData({
            apiData: data,
            competitorValue: filterCompetitor.map(seller => seller.vendor),
            categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
            region: filterCountry,
            expandedRowKeys,
        })
        console.log('metricsTab', metricsTab)
        setTabledata(tabledata)
        setTablecolumns(columns)
        setMetricsTab(metricsTab)
        setCsvData({
            [CSV_DATA_MOST_POPULAR_LAUNCH_DAYS]: geneCsvData({ dataSource: tabledata, switchValue }),
        })
    }

    useDeepCompareEffect(() => {
        if (!data) return

        handleData(data)
    }, [ data, filterCompetitor, expandedRowKeys, switchValue, {} ])

    /* ******************************************************************* */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        // competitors 参数决定 excel 导出文件中 vendor 的排序
        payload.query.competitors = sortVendorList({
            vendorList: payload.query.competitors?.split(',') || [],
        }).join(',')
        payload.query.use_index = switchValue
        await downloadFileByAxios({
            filename: mostPopularLaunchDaysExportFilename,
            payload,
        })
    }

    /* ************************ 表格展开项 ************************** */
    useDeepCompareEffect(() => {
        let keys = [ ...expandedRowKeys ]
        // 写递归没写出来, 后面脑子清醒时再尝试优化
        metricsTab.forEach((item1, index1) => {
        if (item1.active === true) {
            keys.push(String(index1))
        } else {
            keys = keys.filter(i => i !== String(index1))
            keys = keys.filter(i => !i.startsWith(`${index1}-`))
        }
        if (Array.isArray(item1.children) && item1.children.length > 0) {
            item1.children.forEach((item2, index2) => {
            if (item2.active === true && keys.includes(String(index1))) {
                keys.push(`${index1}-${index2}`)
            } else {
                keys = keys.filter(i => i !== `${index1}-${index2}`)
            }
            })
        }
        })
        // 去重
        keys = Array.from(new Set(keys))
        setExpandedRowKeys([ ...keys ])
        // eslint-disable-next-line
    }, [metricsTab])

    /* ************************ 表格展开项 ************************** */
    useDeepCompareEffect(() => {
        if (expandedRowKeys?.length) {
          const vendorList = filterCompetitor.map(f => f.vendor)
          let newExpandedRowKeys = [ ...expandedRowKeys ]
          newExpandedRowKeys = newExpandedRowKeys.filter(item => {
            const code = item.split('_')[0]
            return vendorList.includes(code)
          })
          setExpandedRowKeys([ ...newExpandedRowKeys ])
        }
        // eslint-disable-next-line
      }, [filterCompetitor])

    return (
        <div id={exportId}>
            <Spin spinning={loading || !tabledata?.length}>
                <ModuleTitle
                    type="Dashboard"
                    title={WEEKLY_TABLE_NAME}
                    showDate
                />
                <SizedBox height={10} />
                <Dialog ref={dialogRef} />
                <DashboardTableForm
                    moduleName={WEEKLY_TABLE_NAME}
                    customePointOfView={(
                        <Switch
                            value={switchValue}
                            onChange={value => setSwitchValue(value)}
                            leftLabel="#"
                            rightLabel="%"
                            label="Point of view"
                        />
                    )}
                    onExport={onExportExcel}
                />
                <SizedBox height={20} />
                <div className={classnames([ 'norna-container-fixed-width' ])} style={{ width: '1440px' }}>
                    <Flex className={styles.title}>
                        <div style={{ width: config.tabWidth + 'px', textAlign: 'center' }}>
                            Vendors
                        </div>
                        <Th title={OPTIONS_IN_NAME} width={config.optionsWidth} style={{ paddingLeft: '5px' }} />
                        <Th title="Category" width={config.categoryWidth} style={{ paddingLeft: '14px' }} />
                        <Th style={{ textAlign: 'center' }} title="Monday" width={config.weekdayWidth} />
                        <Th style={{ textAlign: 'center' }} title="Tuesday" width={config.weekdayWidth} />
                        <Th style={{ textAlign: 'center' }} title="Wednesday" width={config.weekdayWidth} />
                        <Th style={{ textAlign: 'center' }} title="Thursday" width={config.weekdayWidth} />
                        <Th style={{ textAlign: 'center' }} title="Friday" width={config.weekdayWidth} />
                        <Th style={{ textAlign: 'center' }} title="Saturday" width={config.weekdayWidth} />
                        <Th style={{ textAlign: 'center' }} title="Sunday" width={config.weekdayWidth} />
                    </Flex>
                    <div className={styles.metricsContent}>
                        <NornaTab
                            showHeader={false}

                            categoryToVendor={true}
                            metricsTab={metricsTab}
                            cateHeight={cateHeight}
                            expendHeight={expendHeight}
                            metricTabFn={metricTabFn}
                            isDashboard={true}
                        />
                        <NornaTable
                            showHeader={false}
                            dataSource={tabledata}
                            columns={tablecolumns}

                            isTd={true}
                            metricTabFn={metricTabFn}
                            metricsTab={metricsTab}
                            categoryToVendor={true}
                        />
                    </div>
                </div>
            </Spin>
            <div style={{ width: 1, height: 70, background: 'transparent' }} />
        </div>
    )
}

const TableWeekdayCell = ({
    record,
    weekday,
    switchValue,
    isFirstMetric = false,
}: {
    record: any;
    weekday: string;
    switchValue: boolean;
    isFirstMetric?: boolean;
}) => {
    if (switchValue) {
        let value = numberUtils.formatNumber(record[weekday]?.products_in_share, { isCommaSymbol: true, isCentuple: true, decimal: 1, isPercentSymbol: true })
        if (record.optionsNum === 0) {
            value = '0'
        }
        if (!record?.queryDateValid) {
            value = ''
        }
        const isMax = record[weekday]?.products_in_share === record.max.products_in_share
        return (
            <TableCell
                record={record}
                width={config.weekdayWidth}
                isFirstMetric={isFirstMetric}
                style={{ justifyContent: 'center', position: 'relative' }}
            >
                {
                    value === '' ? <></> : (
                        <>
                            <div style={{
                                position: 'absolute',
                                left: '10px',
                            }}
                            >
                                <Progress
                                    percent={parseInt(`${100 * record[weekday]?.products_in_share}`, 10)}
                                    strokeColor={isMax && value !== NA ? '#ec7765' : '#649392'}
                                    thick={2}
                                    length={31}
                                    direction="vertical"
                                />
                            </div>
                            <div style={{ textAlign: 'center', color: isMax && value !== NA ? '#ec7765' : '#649392' }}>
                                {value}
                            </div>
                        </>
                    )
                }
            </TableCell>
        )
    }

    // 这一行数据最大的用橙色
    let value = numberUtils.formatNumber(record[weekday]?.products_in, { isCommaSymbol: true, decimal: 0 })
    if (record.optionsNum === 0) {
        value = '0'
    }
    if (!record?.queryDateValid) {
        value = ''
    }
    const isMax = record[weekday]?.products_in === record.max.products_in
    return (
        <TableCell
            text={value}
            record={record}
            width={config.weekdayWidth}
            isFirstMetric={isFirstMetric}
            style={{ color: isMax && value !== NA ? '#ec7765' : '#649392', justifyContent: 'center' }}
        >
            <div style={{ textAlign: 'center', color: isMax && value !== NA ? '#ec7765' : '#649392' }}>
                {value}
            </div>
        </TableCell>
    )
}
