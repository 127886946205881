import { cloneDeep } from 'lodash'
import { stringifyDateRangePickerValue, getOneLatestWeekAgoDateRange } from 'componentsv2/form-elements/DateRangePicker'
import { CATEGORY_TOTAL, defaultSort, isZeemanLicense, PRICE_FILTER, PRICE_FILTER_VALS, PRICE_RANGE_VALS, PROPERTY_MULTI_PACK_LABEL, PROPERTY_MULTI_PACK_VALUE, SELECTED_CATEGORY } from 'consts'
import { PriceFilterPayloadType, PriceRangeType, RequestParamType, ObjectType, SortFieldType, GroupsType, TaxFilterType, SortByPayloadType, OptionsInOutType, FilterPriceRangeType } from 'types'
import { formatCompetitorsFromStore } from 'utils'
import { getGroupsPayload } from 'utils/group'
import { storage } from 'utils/storage'
import { getCategoriesPayload, getMarketPayload, getSizeFilterPayload, getTargetGroupPayload, getTaxPayload } from 'utils/queryPayload'
import { TdPriceRangeArgType, TdPriceRangeType } from './types'
import { releaseNote } from 'configs/releaseNote'

const formatTargetFn = query => typeof query.target === 'object' ? query.target.key : query.target
const formatDiscountFn = query => typeof query.inclDiscount === 'object' ? query.inclDiscount.key : query.inclDiscount

enum CategoryTreeOptions {
  GENERIC = 'generic',
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}
/**
 *  ## Get api values of `PRICE_FILTER`
 * @param key Keys of `PRICE_FILTER`
 * @returns 
 */
export const getPriceFilterValueByKey = (key: string | undefined) => key ? PRICE_FILTER_VALS[key] ?? {} : {}

export const getPriceFilterKeyByValue = (value) => {
  let result = ''
  Object.keys(PRICE_FILTER_VALS).forEach(key => {
    const val = PRICE_FILTER_VALS[key]
    if (val.actual_price === value.actual_price && val.special_price === value.special_price) {
      result = PRICE_FILTER[key]
    }
  })
  return result
}

/**
 *  ## Generate `PriceFilter` payload for api
 *  
 */
export const getPriceFilterPayload = (priceFilter: PriceFilterPayloadType, priceRange: FilterPriceRangeType = {} as FilterPriceRangeType, query: any) => {
  if (priceFilter && Object.keys(priceFilter).length !== 0) {
    return {
      // price range
      activate_price_range: priceRange.activate_price_range,
      min_price: priceRange?.min || 0,
      max_price: priceRange?.max || 0,
      discount_only: PRICE_RANGE_VALS[priceRange.optionValue],
      // price perspective
      ...getPriceFilterValueByKey(priceFilter.val),
      // tax
      tax_rate: getTaxPayload({ filterTax: query?.taxFilter, region: query?.regions?.key }),
      currency: priceFilter.currency || 'SEK',
    }
  }
  return null
}
/**
 *  Format `sort_by` param for api
 * @param query  `Filter`
 * @returns 
 */
export const getSortObject = (query: any): ObjectType<SortFieldType> => (
  query?.collection?.query?.sortField?.sortField ? {
    sort_by: {
      field: query?.collection?.query?.sortField?.sortField,
      order: query?.collection?.query?.sortField?.sortOrder,
    },
  } : { sort_by: defaultSort }
)

/**
 * transfer categories in state to the format that python neeed
 * @param categories
 */
export function transformCategories(categories = []) {
  /**
   * 新增业务逻辑：
   * 右上角 Filter > Category 里如果勾选了 CATEGORY_TOTAL 常量值(目前是 ’Total‘)，是想要查询全部分类的数据
   * 但是接口规定 categories = [] 时就是查询全部分类
   * 此处需要做判断，如果 categories = [CATEGORY_TOTAL]，需要重置为 categories = []
   */
  if (categories.find(item => item === CATEGORY_TOTAL)) {
    const data = getCategoriesPayload(categories)
    return data
  }

  return categories
}

const propertyExclude = [ 'Material', 'Colors' ]
export const getQueryPayloadV2 = (collectionQuery: any, excludedProducts: string[] = [], otherQuery = {}) => {
  const blacklist: object[] = []

  if (collectionQuery.excludedProducts) {
    collectionQuery.excludedProducts.forEach(url => blacklist.push({ nornaid: url }))
  }

  if (excludedProducts?.length) {
    excludedProducts.forEach(url => blacklist.push({ nornaid: url }))
  }
  const { categories, properties, packFilter } = collectionQuery

  let properties1 = cloneDeep(properties) || {}

  if (isZeemanLicense() && Object.keys(properties1?.Packs || {})?.length) {
    properties1.Packs = properties1?.Packs.map(item => {
      if (item === PROPERTY_MULTI_PACK_LABEL) {
        return PROPERTY_MULTI_PACK_VALUE
      }
      return item
    })
  }

  if (packFilter?.length) {
    const notShowSubGroupsMap = storage.getNotShowSubGroupsMap()
    if (Array.isArray(notShowSubGroupsMap[packFilter[0]])) {
      properties1.Packs = notShowSubGroupsMap[packFilter[0]]
    }
  }

  const query: any = {
    category_tree: collectionQuery.categoryTree || CategoryTreeOptions.GENERIC,
    categories: transformCategories(categories),
    target_groups: getTargetGroupPayload({ targetGroups: collectionQuery.targetGroups, returnType: 'array' }),
    material_filter: {
      percent: 0,
      ...collectionQuery.materialFilter,
      ...{
        checked: undefined,
        materials: properties1.Material || [],
      },
    },
    color_filter: {
      percent: 0,
      ...collectionQuery.colorFilter,
      ...{
        checked: undefined,
        colors: properties1.Colors || [],
      },
    },
    blacklist,
    // 排除propertyExclude 包含的properties
    properties: Object.keys(properties1).filter(key => {
      if (propertyExclude.includes(key)) {
        // console.log(key)
      }
      return !propertyExclude.includes(key)
    }).map(key => [ key, properties1[key] ]),
    black_properties: [],
    exclude_property_groups: [],
    earliest_launch_date: collectionQuery.earliestLaunchDate,
    latest_launch_date: collectionQuery.launchBeforeDate,
    no_selection_dates_size: collectionQuery.noSize,
    no_historical_size: collectionQuery.noHistoricalSize,
    no_unavailable_size_update: collectionQuery.noUnavailableSize,
    no_harmonized_size: collectionQuery.noHarmonizedSize,
    zalandodeep_shop_empty_string: collectionQuery.zalandodeepShopEmptyString,
    ignore_no_available_size: collectionQuery.ignoreNoAvailableSize,
    price_filter: getPriceFilterPayload({
      ...collectionQuery.priceFilter,
      currency: collectionQuery.regions.currency,
    }, collectionQuery.priceRange, collectionQuery),
    ...otherQuery,
  }

  // Size filter
  query.size_filter = getSizeFilterPayload({
    sizeFilter: collectionQuery?.sizeFilter,
    targetGroups: collectionQuery?.targetGroups,
  })

  return query
}

/**
 * Mark ups and downs > Table
 */
export const argFnMarkLadder = (query, range, lookbooks: Array<string> = []) => {
  const isLookbook = !!lookbooks.length
  const sortBy = query?.collection?.query?.sortField
  return {
    url: 'mark_ladder',
    query: {
      is_lookbook: isLookbook,
      currency: query.collection.query.regions.currency ?? 'SEK',
    },
    data: {
      sort_by: sortBy ? {
        field: sortBy.sortField,
        order: sortBy.sortOrder,
      } : undefined,
      is_lookbook: isLookbook,
      lookbooks: isLookbook ? lookbooks : null,
      currency: query.collection.query.regions.currency ?? 'SEK',
      customer_seller: query.customer_seller,
      competitor_sellers: formatCompetitorsFromStore(query.sellers, query.collection.query.regions.key),
      market: query.market,
      groups: query.groups,
      query: getQueryPayloadV2(
        {
          ...query.collection.query,
          categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
        },
        [],
        {
          start_date: { iso8601: range.split('_')[0] },
          end_date: { iso8601: range.split('_')[1] },
        },
      ),
    },
  }
}

/**
 * Mark ups and downs > Graph
 */
export const argFnMarkDepthAndWidth = (query, range, lookbooks: Array<string> = []) => {
  const isLookbook = !!lookbooks.length

  const sortBy = query?.collection?.query?.sortField
  return {
    url: 'mark_depth_and_width',
    query: {
      is_lookbook: isLookbook,
      currency: query.collection.query.regions.currency ?? 'SEK',
    },
    data: {
      is_lookbook: isLookbook,
      lookbooks: isLookbook ? lookbooks : null,
      currency: query.collection.query.regions.currency ?? 'SEK',
      customer_seller: query.customer_seller,
      competitor_sellers: query.sellers,
      market: query.market,
      groups: query.groups,
      sort_by: sortBy ? {
        field: sortBy.sortField,
        order: sortBy.sortOrder,
      } : undefined,
      query: getQueryPayloadV2(
        {
          ...query.collection.query,
          categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
        },
        [],
        {
          start_date: { iso8601: range.split('_')[0] },
          end_date: { iso8601: range.split('_')[1] },
        },
      ),
    },
  }
}

/**
 *  ## td price range param generator
 * 
 * @param query 
 * @returns 
 */
function getTdPriceRange(query: TdPriceRangeType, includeDiscount?: string) {
  const priceFilter = {
    currency: query.currency ?? 'SEK',
    min_price: 0,
    max_price: 100,
    activate_price_range: false,
    ...getPriceFilterValueByKey(includeDiscount || formatDiscountFn(query)),
    tax_rate: getTaxPayload({ filterTax: query?.taxFilter, region: query.region }),
  }

  if (query.priceRange && query.priceRange.indexOf(',')) {
    const [ min, max ] = query.priceRange.split(',')
    priceFilter.min_price = min as unknown as number
    priceFilter.max_price = max as unknown as number
    priceFilter.activate_price_range = true
  }
  return {
    price_filter: priceFilter,
  }
}

export const argFnTradingdashboard = (query): any => {
  const date =
    !query.date || (typeof query.date === 'string' && query.date.indexOf('Week') > -1)
      ? stringifyDateRangePickerValue(getOneLatestWeekAgoDateRange())
      : query.date

  return {
    url: 'dashboard/dashboard_get_table_data/',
    query: {
      region: query.region,
      target_group: formatTargetFn(query),
      currency: query.currency ?? 'SEK',
      date,
      customer: query.customer,
      competitors: query.competitors.map(item => item.code || item.key).join(','),
    },
    data: {
      all_category: query.categories || [],
      market: query?.market || [],
      groups: getGroupsPayload({
        competitorValue: query.competitors,
        customerVendor: query.customer,
        region: query.region,
      }),
      ...getTdPriceRange(query),
    },
  }
}

export const argFnPriceBasketAnalytics = query => {
  const date = !query.date || query.date.indexOf('Week') > -1 ? stringifyDateRangePickerValue(getOneLatestWeekAgoDateRange()) : query.date
  return {
    url: 'dashboard/price_basket_table/',
    query: {
      region: query.region,
      target_group: formatTargetFn(query),
      currency: query.currency ?? 'SEK',
      date,
      customer: query.customer,
      competitors: query.competitors.map(item => item.code || item.key).join(','),
    },
    data: {
      market: query?.market || [],
      groups: getGroupsPayload({
        competitorValue: query.competitors,
        customerVendor: query.customer,
        region: query.region,
      }),
      ...getTdPriceRange(query),
    },
  }
}

/**
 * new price dashboard 表格接口
 */
export const argFnNewPriceDashboardTable = (query: { priceRange: string; region: string; currency: string; targetGroup: string; date: string; customer: string; competitors: string; includeDiscount: string; market: string[] }) => ({
  url: 'dashboard/new_price_dashboard_table/',
  query: {
    region: query.region,
    target_group: query.targetGroup,
    currency: query.currency ?? 'SEK',
    date: query.date,
    customer: query.customer,
    competitors: query.competitors,
  },
  data: {
    market: query?.market || [],
    groups: getGroupsPayload({
      competitorValue: query.competitors.split(',').map(item => ({ vendor: item, region: query.region })),
      customerVendor: query.customer,
      region: query.region,
    }),
    ...getTdPriceRange(query, query.includeDiscount),
  },
})

/**
 * new price dashboard 线图接口
 */
export const argFnNewPriceDashboardGraph = (opt: { priceRange: string, customer: string; region: string; targetGroup: string; currency: string; category: string; includeDiscount?: string; metric?: string; date?: string; competitors: string[]; market: string[]; }) => ({
  url: 'dashboard/new_price_dashboard_graph/',
  query: {
    customer: opt.customer,
    region: opt.region,
    target_group: opt.targetGroup,
    currency: opt.currency ?? 'SEK',
    category: opt.category,
    metric: opt.metric,
    date: !opt.date ? stringifyDateRangePickerValue(getOneLatestWeekAgoDateRange()) : opt.date,
  },
  data: {
    competitors: opt.competitors,
    market: opt.market,
    groups: getGroupsPayload({
      competitorValue: opt.competitors.map(item => ({ vendor: item, region: opt.region })),
      customerVendor: opt.customer,
      region: opt.region,
    }),
    ...getTdPriceRange(opt, opt.includeDiscount),
  },
})

/** *
 * in & out dashboard table request paramter
 * @param query
 */
export const argFnInAndOutDashboard = query => {
  const date = !query.date || query.date.indexOf('Week') > -1 ? stringifyDateRangePickerValue(getOneLatestWeekAgoDateRange()) : query.date
  return {
    url: 'dashboard/dashboard_products_in_out_table/',
    query: {
      region: query.region,
      target_group: formatTargetFn(query),
      currency: query.currency ?? 'SEK',
      date,
      customer: query.customer,
      competitors: query.competitors.map(item => item.code || item.key).join(','),
    },
    data: {
      market: query?.market || [],
      groups: getGroupsPayload({
        competitorValue: query.competitors,
        customerVendor: query.customer,
        region: query.region,
      }),
      ...getTdPriceRange(query),
    },
  }
}

export const argFnInAndOutMonthly = query => ({
  url: 'monthly_products_in_and_out/',
  query: {
    region: query.region,
    // region: query.region,
    target_group: 'All Options',
    // target_group: query.target,
    customer: query.customer,
    competitors: query.competitors.map(item => item.code || item.key).join(','),
  },
  data: {
    market: query?.market || [],
    groups: getGroupsPayload({
      competitorValue: query.competitors,
      customerVendor: query.customer,
      region: query.region,
    }),
    ...getTdPriceRange(query),
  },
})

// market visuals 线图接口参数
export const marketVisualGraphArg = (opt: any) => ({
  url: 'dashboard/dashboard_get_graph_data/',
  query: {
    customer: storage.getCustomerVendor(),
    region: opt.region,
    target_group: formatTargetFn(opt),
    currency: opt.currency ?? 'SEK',
    category: opt.category,
    metric: opt.metric,
    date: !opt.date || opt.date.indexOf('Week') > -1 ? stringifyDateRangePickerValue(getOneLatestWeekAgoDateRange()) : opt.date,
  },
  data: {
    competitors: opt.competitors,
    market: opt.market,
    groups: opt.groups,
    ...getTdPriceRange(opt),
  },
})

/**
 * admin meta data
 * @returns 
 */
export const argFnGetMetaData = () => ({
  url: 'meta_structure/metadata',
  query: {},
  data: {},
})

export const argFnPostReleaseNote = () => ({
  url: '/release_note',
  query: {
    version: releaseNote.version,
  },
  method: 'post',
})

/**
 * get region & target_group
 */
export const argFnGetTargetGroupAndRegion = () => ({
  url: 'dashboard_get_target_group_region_currency/',
  query: {},
  data: {},
})

/**
 * Analytics > Assortment > Popular products api
 */
export const popularProductsArg = (query, range, lookbooks: Array<string> = []) => {
  const isLookbook = !!lookbooks.length
  const collectionQuery = query.collection.query
  const queryPayLoad = getQueryPayloadV2(
    {
      ...collectionQuery,
      categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
    },
    [],
    {
      start_date: { iso8601: range.split('_')[0] },
      end_date: { iso8601: range.split('_')[1] },
    },
  )
  if (query.categoryParsed) queryPayLoad.categories = query.categoryParsed

  return {
    url: 'get_popular_products',
    query: {
      is_lookbook: isLookbook,
      currency: query?.collection?.query?.regions?.currency ?? 'SEK',
    },
    data: {
      lookbooks: lookbooks.length ? lookbooks : [],
      is_lookbook: isLookbook,
      customer_seller: query.customer_seller,
      competitor_sellers: query.competitor_sellers,
      market: query.market,
      groups: query.groups,
      query: queryPayLoad,
    },
  }
}

/**
 * Analytics > Assortment > Products in and out > Daily products in & out api
 */
export const dailyProductsInOutArg = (query, year, month, category, region, targetGroup, properties, priceFilter, lookbooks, noSize, noHistoricalSize, noUnavailableSize, noHarmonizedSize, zalandodeepShopEmptyString, ignoreNoAvailableSize) => {
  return {
    url: 'dashboard/daily_products_in_out',
    query: {
      customer: query?.customer_seller?.vendor,
      region,
      category: category || CATEGORY_TOTAL,
      no_selection_dates_size: noSize,
      no_historical_size: noHistoricalSize,
      no_unavailable_size_update: noUnavailableSize,
      no_harmonized_size: noHarmonizedSize,
      zalandodeep_shop_empty_string: zalandodeepShopEmptyString,
      ignore_no_available_size: ignoreNoAvailableSize,
    },
    data: {
      target_group: targetGroup,
      competitors: query.competitors,
      market: query.market,
      groups: query.groups,
      all_category: category,
      properties,
      price_filter: priceFilter,
      select_month: {
        month,
        year,
      },
      lookbooks,
    },
  }
}

/**
 * Analytics > Assortment > Products in and out > in and out products
 */
export const fetchInOutProductsArg = (query, vendor, date, allCategory, region, targetGroup, vendors, taxRate, groups, sortBy, properties, priceFilter, lookbooks, noSize = false, noHistoricalSize = false, noUnavailableSize = false, noHarmonizedSize = false, zalandodeepShopEmptyString = false, ignoreNoAvailableSize = false) => (
  {
    url: 'dashboard/fetch_daily_in_and_out_products',
    query: {
      customer: query?.customer_seller?.vendor,
      region,
      vendor,
      date,
      tax_rate: taxRate,
      no_selection_dates_size: noSize,
      no_historical_size: noHistoricalSize,
      no_unavailable_size_update: noUnavailableSize,
      no_harmonized_size: noHarmonizedSize,
      zalandodeep_shop_empty_string: zalandodeepShopEmptyString,
      ignore_no_available_size: ignoreNoAvailableSize,
    },
    data: {
      competitors: [ vendor ],
      target_group: targetGroup,
      all_category: allCategory,
      groups,
      sort_by: sortBy,
      properties,
      price_filter: priceFilter,
      lookbooks,
    },
  }
)

/**
 * Browse options 页面调用接口
 *
 * 参数说明：
 * - query  Filter 中选中项，可从 redux 工具方法中快速获取
 *    const query = useSelector(selectCollection)
 * 
 * - query.qaPage=true 表示Qa商品模式
 * 
 * - date   页面上日期选择框选中值，字符串格式，如：date = '2021-10-20'
 */
export const matchAllCategories = (query, date, stateDate, categories: string[] = [], exclusive = false, historicalSizeFilter: string[] = [], multiGender = false, multiMetaCategory = false) => {
  const newQuery = getQueryPayloadV2(
    {
      ...query.collection.query,
      categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
    },
    [],
  )

  return {
    url: 'navigation/match_all_categories/',
    query: {
      currency: query?.collection?.query?.regions?.currency ?? 'SEK',
    },
    data: {
      // competitor_sellers: query.sellers,
      competitor_sellers: query.sellers,
      customer_seller: query.customer_seller,
      sort_by: query?.collection?.query?.sortField?.sortField ? {
        field: query?.collection?.query?.sortField?.sortField,
        order: query?.collection?.query?.sortField?.sortOrder,
      } : undefined,
      market: query.market || [],
      groups: query.groups,
      query: {
        ...newQuery,
        categories,
        start_date: {
          iso8601: stateDate,
          exclusive,
        },
        end_date: {
          iso8601: date,
          exclusive,
        },
        historical_size_filter: historicalSizeFilter,
        multi_gender: multiGender,
        multi_meta_category: multiMetaCategory,
      },
    },
  }
}

/**
 * ## Browse options 查询产品列表
 * @param query  Filter query
 * @param date   
 * @param ids 
 * @param currency 
 * @returns 
 */
export const fetchProducts = (query: any, date: string, ids: Array<string>, currency = '', priceRange?: PriceRangeType, priceFilter?: string): RequestParamType => {
  currency = currency ?? query?.collection?.query?.regions?.currency ?? 'SEK'
  priceRange = priceRange ?? query.collection.query.priceRange
  priceFilter = priceFilter ?? query.collection.query.priceFilter.val

  return {
    url: 'fetch_products',
    query: {
      to_currency: currency,
    },
    data: {
      ids,
      ...getSortObject(query),
      ...date ? {
        date: {
          iso8601: date,
        },
      } : {},

      price_filter: getPriceFilterPayload({ currency, val: priceFilter as string }, query.collection.query.priceRange, query.collection.query),
    },
  }
}

/**
 * product detail by nornaid
 * @param nornaid
 * @param query
 */
export const fetchProductDetail = (nornaid, query, qaAnnotation, metaName = '', toCurrency = '', taxRate, qaPage = false): RequestParamType => ({
  url: 'get_product_details',
  query: {
    /**
     * 大多数 Analytics 页面的 currency 可以通过右上角 Filter > Country 选中值获取
     * 所以在这个方法里默认从 query?.collection?.query?.regions?.currency 获取币种
     *
     * 但是 Options in and out distribution > Options in and out by calendar 页面中，没有右上角 Filter 选项
     * 也需要查询产品详情模态框，币种是通过页面内的 Currency 下拉框传递的
     * 所以这个方法新增 toCurrency 参数
     */
    to_currency: toCurrency || (metaName ? '' : query?.collection?.query?.regions?.currency ?? 'SEK'),
    nornaid,
    qa_annotation: qaAnnotation,
    meta_name: metaName,
    tax_rate: taxRate,
  },
  data: {},
})

/**
 * search url
 * @param url
 */
export const searchUrl = url => ({
  url: 'search_url',
  query: {
    qa_page: false,
    /**
     * BFF 的 query 已经做过一次 url 编码了，所以这里不需要再做 url 编码
     */
    // url: encodeURIComponent(url),
    url,
  },
  data: {},
})

/**
 * 产品详情模态框中 Report Product 按钮调用接口
 *
 * - user: 传用户的 email 地址
 * - url: 产品地址，如：https://www.filippa-k.com/en/woman/clothing/dresses/cherice-dress-1?color=navy
 * - reports: 产品类型，数组类型，如：['price']
 */
export const reportProductArg = ({ user, url, reports }) => ({
  url: 'report_product/',
  data: {
    user,
    url,
    reports,
  },
})

/**
 * qa products list
 * @param vendors
 * @param region
 * @param target
 * @param metaCatgory
 * @param category
 * @param reviewStatus
 */
export const qaProductsArg = ({ vendors, region, targets, metaCategory, category, reviewStatus, page }) => ({
  url: 'qa/review_products/',
  query: {
    vendors: vendors.join(','),
    region,
    target_group: targets,
    meta_name: metaCategory,
    category,
    review_status: reviewStatus,// New_unviewed、old_unviewed、viewed、property_change
    page_size: 200,
    current_page: page,
  },
  data: {},
})

/**
 * qa meta data
 */
export const qaMetaData = () => ({
  url: 'qa/meta/',
  query: {},
  data: {},
})

export const qaProductInfoArg = (products, info) => ({
  url: 'qa/write_result/',
  query: {
  },
  data: {
    products: [
      ...products,
    ],
    meta_name: info.metaName,
    user: info.user,
  },
})

/**
 * MarketDashboard > Promotions 模块使用该接口
 * 查询促销活动信息
 *
 * competitors：多个 competitor 用逗号隔开 'apc,filippak,boss'
 */
export const fetchCampaignsArg = ({ region, competitors, groups }) => ({
  url: 'dashboard/fetch_campaigns',
  query: {
    competitors,
    region,
    target_group: 'All Options',
  },
  data: groups,
})

/**
 * Price history 页面 -> Price history drivers 模块
 */
export const argFnPriceIndicesDetail = (query, range, lookbooks: Array<string> = []) => {
  const isLookbook = !!lookbooks.length

  const data: any = {
    customer_seller: query.customer_seller,
    competitor_sellers: query.sellers,
    market: query.market,
    groups: query.groups,
    query: getQueryPayloadV2(
      {
        ...query.collection.query,
        categoryTree: query.customer_seller.vendor + '_category',
      },
      [],
      {
        start_date: { iso8601: range.split('_')[0] },
        end_date: { iso8601: range.split('_')[1] },
      },
    ),
    sort_by: query?.collection?.query?.sortField?.sortField ? {
      field: query?.collection?.query?.sortField?.sortField,
      order: query?.collection?.query?.sortField?.sortOrder,
    } : undefined,
  }

  if (isLookbook) data.lookbooks = lookbooks
  return {
    url: 'navigation/price_indices_detail/',
    query: {
      is_lookbook: isLookbook,
      currency: query.collection.query.regions.currency ?? 'SEK',
    },
    data,
  }
}

/**
 * Assortment size 页面 -> Assortment size 模块
 */
export const argFnOptionsIndices = (opt: {
  customer: string;
  region: string;
  targetGroup: string[];
  competitors: string[];
  date: string;
  market: string[];
  groups: GroupsType;
  priceFilter: any,
  properties: any,
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}) => ({
  url: 'dashboard/options_indices',
  query: {
    customer: opt.customer,
    region: opt.region,
    date: opt.date,
    no_selection_dates_size: opt?.noSize,
    no_historical_size: opt.noHistoricalSize,
    no_unavailable_size_update: opt.noUnavailableSize,
    no_harmonized_size: opt?.noHarmonizedSize,
    zalandodeep_shop_empty_string: opt?.zalandodeepShopEmptyString,
    ignore_no_available_size: opt?.ignoreNoAvailableSize,
  },
  data: {
    target_group: opt.targetGroup,
    competitors: opt.competitors,
    groups: opt.groups,
    market: opt.market,
    price_filter: opt?.priceFilter,
    properties: opt?.properties,
  },
})

export const argFnProductRegionsDistribution = (query, range, priceFilterValue, lookbooks?: Array<string>) => {
  const queryPayload = getQueryPayloadV2(
    {
      ...query.collection.query,
      categoryTree: query.customer_seller.vendor + '_category',
    },
    [],
    {
      start_date: { iso8601: range.split('_')[0] },
      end_date: { iso8601: range.split('_')[1] },
    },
  )
  const priceFilterPayload = getPriceFilterValueByKey(priceFilterValue)
  queryPayload.price_filter = { ...queryPayload.price_filter, ...priceFilterPayload } as any

  return {
    url: 'navigation/product_regions_distribution',
    data: {
      customer_seller: query.customer_seller,
      competitor_sellers: query.sellers,
      market: query.market,
      groups: query.groups,
      sort_by: query?.collection?.query?.sortField?.sortField ? {
        field: query?.collection?.query?.sortField?.sortField,
        order: query?.collection?.query?.sortField?.sortOrder,
      } : undefined,
      query: queryPayload,
      ...{ ...lookbooks ? { lookbooks } : {} },
    },

  }
}

/**
 * Material QA 页面
 * 获取是否已标注情况
 */
export const argFnGetMaterialAnnotation = (nornaids: Array<string>) => ({
  url: '/get_mask_tag/',
  data: {
    products: { nornaids },
    select_tags: [ 'material', 'select_tags' ],
  },
})

/**
 * ## price range
 * 
 * @returns
 */
export const argFnPriceRange = (query: any, currency: string, qa_page = false, range: string, lookbooks?: Array<string>) => {
  const queryPayload = getQueryPayloadV2(
    {
      ...query.collection.query,
      categoryTree: query?.customer_seller?.vendor + '_category',
    },
    [],
    {
      start_date: { iso8601: range.split('_')[0] },
      end_date: { iso8601: range.split('_')[1] },
    },
  )

  return {
    // url: '/navigation/price_ranges/',
    url: '/navigation/min_and_max_price/',
    query: {
      currency,
      qa_page,
      columns: 10,
    },
    data: {
      customer_seller: query?.customer_seller,
      competitor_sellers: formatCompetitorsFromStore(query.sellers, query.collection?.query?.regions?.key),
      market: query.market,
      groups: getGroupsPayload({
        competitorValue: formatCompetitorsFromStore(query.sellers, query.collection?.query?.regions?.key),
        customerVendor: query?.customer_seller?.vendor,
        region: query?.customer_seller?.region,
      }),
      sort_by: query?.collection?.query?.sortField?.sortField ? {
        field: query?.collection?.query?.sortField?.sortField,
        order: query?.collection?.query?.sortField?.sortOrder,
      } : undefined,
      query: queryPayload,
      ...{ ...lookbooks ? { lookbooks } : {} },
    },
  }
}

export const getTdQueryPayloadV2 = (queryCombo: any, otherQuery = {}) => {
  const query: any = {
    category_tree: queryCombo.categoryTree || CategoryTreeOptions.GENERIC,
    categories: transformCategories(queryCombo.categories),
    target_groups: queryCombo.target_group,
    price_filter: getPriceFilterPayload({
      ...queryCombo.price_filter,
      currency: queryCombo.currency,
    }, queryCombo.priceRange, queryCombo),
    ...otherQuery,
  }
  return query
}

export const argFnTdPriceRange = (query: TdPriceRangeArgType) => {
  const queryCombo = {
    // Array<string>
    competitors: query.competitors,
    // Array<string>
    categories: query.categories,
    categoryTree: `${query.customer}_category`,

    // Record
    price_filter: query.priceFilter,

    // Array<string>
    market: query.market,

    // String
    region: query.region,

    // String
    currency: query.currency,

    // String
    customer: query.customer,

    // String
    include_discount: query.includeDiscount,

    // String
    date: query.date,
    // Array<string>
    target_group: query.targetGroup,
  }
  const competitorSellers = queryCombo.competitors.map((vendor: string) => ({ vendor, region: queryCombo.region }))
  const queryPayload = getTdQueryPayloadV2(queryCombo, {
    start_date: { iso8601: queryCombo.date.split('_')[0] },
    end_date: { iso8601: queryCombo.date.split('_')[1] },
  })

  return {
    url: '/navigation/price_ranges/',
    query: {
      currency: queryCombo.currency,
      qa_page: false,
      columns: 10,
    },
    data: {
      customer_seller: { vendor: queryCombo.customer, region: queryCombo.region },
      competitor_sellers: competitorSellers, // formatCompetitorsFromStore(query.sellers, query.region),
      market: queryCombo.market.map((vendor: string) => ({ vendor, region: queryCombo.region })),
      groups: getGroupsPayload({
        competitorValue: formatCompetitorsFromStore(competitorSellers, queryCombo.region),
        customerVendor: queryCombo?.customer,
        region: queryCombo.region,
      }),
      query: queryPayload,
    },
  }
}

/**
 * Popular attributes 表格接口
 */
export const argFnGetPopularProductsProperties = (query: any, dateRangeValue, topOptions) => {
  const queryPayload = getQueryPayloadV2(
    {
      ...query.collection.query,
      categoryTree: query?.customer_seller?.vendor + '_category',
    },
    [],
    {
      start_date: { iso8601: dateRangeValue.split('_')[0] },
      end_date: { iso8601: dateRangeValue.split('_')[1] },
    },
  )

  return {
    url: 'get_popular_products_properties',
    query: {
      top_options: topOptions,
    },
    data: {
      customer_seller: query?.customer_seller,
      competitor_sellers: formatCompetitorsFromStore(query.competitor_sellers, query.collection?.query?.regions?.key),
      market: query.market,
      groups: query.groups,
      sort_by: query?.collection?.query?.sortField?.sortField ? {
        field: query?.collection?.query?.sortField?.sortField,
        order: query?.collection?.query?.sortField?.sortOrder,
      } : undefined,
      query: queryPayload,
    },
  }
}

/* ************************************ new api ****************************************** */

export const argFnDashboardComparison = (query: {
  customer: string;
  region: string;
  target: string[];
  currency: string;
  competitors: string[];
  market: string[];
  groups: GroupsType;
  date: string;
  comparisonDate: string;
  metric: 'Price' | 'Market' | 'In&out' | 'Assortment' | 'Dynamic' | 'Sold out' | 'Traditional sold out' | 'Zalando Shops';
  export: boolean;
  categories: string[];
  inclDiscount: string;
  priceRange: string;
  taxFilter: TaxFilterType;
  properties: any;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}): any => {
  return {
    url: 'dashboard/comparison',
    query: {
      customer: query.customer,
      region: query.region,
      currency: query.currency,
      date: query.date,
      comparison_date: query.comparisonDate,
      metric: query.metric,
      export: query.export || false,
      no_selection_dates_size: query.noSize,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.target,
      competitors: query.competitors,
      market: query.market,
      groups: query.groups,
      all_category: getCategoriesPayload(query.categories),
      price_filter: query.priceFilter,
      properties: query.properties,
      lookbooks: query.lookbooks,
    },
  }
}

/**
 * Price insight > Avg price > detail info
 */
 export const argFnNavigationPriceChange = (query: {
  currency: string;
  region: string;
  targetGroup: string;
  category: string;
  date: string;
  inclDiscount: string;
  priceRange: string;
  competitorValue: string[];
  competitorOptions: {vendor: string; region: string}[]
}) => {
  const customerVendorCode = storage.getCustomerVendor()

  const market = getMarketPayload({
    competitorSellers: query.competitorValue?.map(vendor => ({ vendor, region: query.region })),
    competitorOptions: query.competitorOptions,
    returnType: 'vendor',
  })

  const groups = getGroupsPayload({
    competitorValue: query.competitorValue?.map(vendor => ({ vendor, region: query.region })),
    region: query.region,
  })

  return {
    url: 'navigation/price_change',
    query: {
      customer: customerVendorCode,
      region: query.region,
      currency: query.currency,
      target_group: query.targetGroup,
      category: query.category,
      date: query.date,
    },
    data: {
      competitors: query.competitorValue,
      market,
      groups,
      ...getTdPriceRange(query),
    },
  }
}

export type DashboardFetchProductsMetric = 'All' | 'Minimum price' | 'Maximum price';

export const argFnDashboardFetchProductsV2 = (query: {
  region: string;
  currency: string;
  targetGroup: string[];
  category: string;   // 'All categories'
  date: string;
  includeDiscount: string;
  competitors: string;
  allCategory: string[];
  metric?: DashboardFetchProductsMetric;   // 'All' -> 'Options'
  taxFilter: TaxFilterType;
  groups?: GroupsType;
  sortBy: SortByPayloadType;
  properties: any;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}) => {
  const { region } = query

  let categoriesGroups: any = {}
  if (query.category === SELECTED_CATEGORY) {
    categoriesGroups = { [SELECTED_CATEGORY]: query?.allCategory || [] }
  }

  return {
    url: 'dashboard/dashboard_fetch_products',
    query: {
      customer: storage.getCustomerVendor(),
      region,
      currency: query.currency,
      category: query.category,
      metric: query.metric || 'All',
      date: query.date,
      vendor: query.competitors,
      no_selection_dates_size: query.noSize,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.targetGroup,
      all_category: getCategoriesPayload(query.allCategory),
      groups: query.groups || {},
      categories_groups: categoriesGroups,
      price_filter: query.priceFilter,
      sort_by: query.sortBy,
      properties: query.properties,
      lookbooks: query.lookbooks,
    },
  }
}

export const argFnDashboardHarmonizingSoldOutIds = (query: {
  region: string;
  currency: string;
  category: string;   // 'All categories'
  metric?: '<S' | 'S' | 'M' | 'L' | 'XL' | '>XL';
  date: string;
  competitors: string;

  targetGroup: string[];
  includeDiscount: string;
  allCategory: string[];
  taxFilter: TaxFilterType;
  groups?: GroupsType;
  sortBy: SortByPayloadType;
  properties: any;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}) => {
  let categoriesGroups: any = {}
  if (query.category === SELECTED_CATEGORY) {
    categoriesGroups = { [SELECTED_CATEGORY]: query?.allCategory || [] }
  }

  return {
    url: '/dashboard/harmonizing_sold_out_ids/',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      currency: query.currency,
      category: query.category,
      metric: query.metric,
      date: query.date,
      vendor: query.competitors,
      no_selection_dates_size: query.noSize || false,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      sort_by: query.sortBy,
      target_group: query.targetGroup,
      competitors: [ query.competitors ],
      all_category: getCategoriesPayload(query.allCategory),
      groups: query.groups || {},
      categories_groups: categoriesGroups,
      price_filter: query.priceFilter,
      properties: query.properties,
      lookbooks: query.lookbooks,
    },
  }
}

/**
 * Price insight 页面 > 点击 Avg price 两个变化列发请求获取产品列表
 */
export const argFnAveragePriceChanges = (query: {
  region: string;
  targetGroup: string[];
  selectedVendor: string;
  currency: string;
  comparisonDate?: string;
  date: string;
  category: string;   // 'All categories'
  includeDiscount: string;
  allCategory: string[];
  taxFilter: TaxFilterType;
  groups?: GroupsType;
  sortBy: SortByPayloadType;
  properties: any;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}) => {
  return {
    url: 'navigation/average_price_changes/',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      vendor: query.selectedVendor,
      currency: query.currency,
      comparison_date: query.comparisonDate,
      date: query.date,
      category: query.category,
      no_selection_dates_size: query.noSize,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.targetGroup,
      all_category: getCategoriesPayload(query.allCategory),
      groups: query.groups || {},
      sort_by: query.sortBy,
      properties: query.properties,
      price_filter: query.priceFilter,  
      lookbooks: query.lookbooks,
    },
  }
}

/**
 * Dashboard 页面查询 in 和 out 产品 ids
 */
export const argFnDashboardProductsInOutIds = (query: {
  region: string;
  targetGroup: string[];
  category?: string;      // 当前表格选择了哪一个 category
  date?: string;
  competitor?: string[];
  metric?: OptionsInOutType;
  currency: string;
  sortBy: SortByPayloadType;
  properties: any;
  includeDiscount: string;
  allCategory: string[];
  groups?: GroupsType;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}) => {
  return {
    url: 'dashboard/dashboard_products_in_out_ids/',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      category: query.category, // 'All categories',
      date: query.date,
      metric: query.metric,
      vendor: query.competitor?.join(','),
      no_selection_dates_size: query.noSize || false,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.targetGroup,
      all_category: getCategoriesPayload(query.allCategory),
      groups: query.groups || {},
      price_filter: query.priceFilter,
      properties: query.properties,
      sort_by: query.sortBy,
      lookbooks: query.lookbooks,
    },
  }
}

export const argFnDashboardProductsInOutGraph = (query: {
  region: string;
  targetGroup: string;
  currency: string;
  category: string;
  metric: string;
  date: string;
  export?: boolean;
  graphImage?: string;
  competitors: string[];
  market: string[];
  groups: GroupsType;
  inclDiscount: string;
  taxFilter: TaxFilterType;
}) => {
  return {
    url: 'dashboard/dashboard_products_in_out_graph/',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      target_group: query.targetGroup,
      currency: query.currency,
      category: query.category,
      metric: query.metric,
      date: query.date,
    },
    data: {
      competitors: query.competitors,
      market: query.market,
      groups: query.groups,
      ...getTdPriceRange({ ...query, priceRange: '' }, query.inclDiscount),
    },
  }
}

export const argFnDashboardPriceBasketGraph = (query: {
  region: string;
  targetGroup: string;
  currency: string;
  category: string;
  metric: string;
  date: string;
  export?: boolean;
  graphImage?: string;
  competitors: string[];
  market: string[];
  groups: GroupsType;
  inclDiscount: string;
  taxFilter: TaxFilterType;
}) => {
  return {
    url: 'dashboard/price_basket_graph/',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      target_group: query.targetGroup,
      currency: query.currency,
      category: query.category,
      metric: query.metric,
      date: query.date,
    },
    data: {
      competitors: query.competitors,
      market: query.market,
      groups: query.groups,
      ...getTdPriceRange({ ...query, priceRange: '' }, query.inclDiscount),
    },
  }
}

export const argFnDashboardGetGraphData = (query: {
  region: string;
  targetGroup: string;
  currency: string;
  category: string;
  metric: string;
  date: string;
  export?: boolean;
  graphImage?: string;
  competitors: string[];
  market: string[];
  groups: GroupsType;
  inclDiscount: string;
  taxFilter: TaxFilterType;
}) => {
  return {
    url: 'dashboard/dashboard_get_graph_data/',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      target_group: query.targetGroup,
      currency: query.currency,
      category: query.category,
      metric: query.metric,
      date: query.date,
    },
    data: {
      competitors: query.competitors,
      market: query.market,
      groups: query.groups,
      ...getTdPriceRange({ ...query, priceRange: '' }, query.inclDiscount),
    },
  }
}

export const argFnColorMaterialCategoryAssortmentComparison = (query, range, currency, lookbooks: Array<string> = [], comparisonDate) => {
  const isLookbook = !!lookbooks.length
  const queryPayLoad = getQueryPayloadV2(
    {
      ...query.collection.query,
      categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
    },
    [],
    {
      start_date: { iso8601: range.split('_')[0] },
      end_date: { iso8601: range.split('_')[1] },
    },
  )
  if (query.categoryParsed && !query.categoryParsed.includes(CATEGORY_TOTAL)) queryPayLoad.categories = query.categoryParsed
  const sortBy = query?.collection?.query?.sortField

  return {
    url: 'color_material_category_assortment_comparison',
    query: {
      is_lookbook: isLookbook,
      selected_property: query.tab,
      currency,
    },
    data: {
      comparison_date: {
        start_date: { iso8601: comparisonDate.split('_')[0] },
        end_date: { iso8601: comparisonDate.split('_')[1] },
      },
      is_lookbook: isLookbook,
      lookbooks: isLookbook ? lookbooks : null,
      customer_seller: query.customer_seller,
      competitor_sellers: query.sellers,
      market: query.market,
      groups: query.groups,
      sort_by: sortBy?.sortField ? {
        field: sortBy?.sortField,
        order: sortBy?.sortOrder,
      } : undefined,
      query: queryPayLoad,
    },
  }
}

export const argFnDiscountLadderComparison = (query, range, lookbooks: Array<string> = [], comparisonDate) => {
  const isLookbook = !!lookbooks.length
  const sortBy = query?.collection?.query?.sortField
  return {
    url: 'discount_ladder_comparison',
    query: {
      is_lookbook: isLookbook,
      currency: query.collection.query.regions.currency ?? 'SEK',
    },
    data: {
      comparison_date: {
        start_date: { iso8601: comparisonDate.split('_')[0] },
        end_date: { iso8601: comparisonDate.split('_')[1] },
      },
      sort_by: sortBy ? {
        field: sortBy.sortField,
        order: sortBy.sortOrder,
      } : undefined,
      is_lookbook: isLookbook,
      lookbooks: isLookbook ? lookbooks : null,
      currency: query.collection.query.regions.currency ?? 'SEK',
      customer_seller: query.customer_seller,
      competitor_sellers: query.sellers,
      market: query.market,
      groups: query.groups,
      query: getQueryPayloadV2(
        {
          ...query.collection.query,
          categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
        },
        [],
        {
          start_date: { iso8601: range.split('_')[0] },
          end_date: { iso8601: range.split('_')[1] },
        },
      ),
    },
  }
}

/**
 * price ladder and distribution
 * @param query
 * @param range
 */
export const argFnPriceLadderAndDistributionComparison = (query, range, query1, lookbooks: Array<string> = [], comparisonDate) => {
  const isLookbook = !!lookbooks.length

  const sortBy = query?.collection?.query?.sortField

  return {
    url: 'price_ladder_and_distribution_comparison',
    query: {
      currency: query.collection.query.regions.currency ?? 'SEK',
      is_lookbook: isLookbook,
      columns: 36,
    },
    data: {
      comparison_date: {
        start_date: { iso8601: comparisonDate.split('_')[0] },
        end_date: { iso8601: comparisonDate.split('_')[1] },
      },
      lookbooks: isLookbook ? lookbooks : null,
      price_gap: query1.gap,
      customer_seller: query.customer_seller,
      competitor_sellers: query.sellers,
      market: query.market,
      groups: query.groups,
      sort_by: sortBy?.sortField ? {
        field: sortBy?.sortField,
        order: sortBy?.sortOrder,
      } : undefined,
      query: getQueryPayloadV2(
        {
          ...query.collection.query,
          categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
        },
        [],
        {
          start_date: { iso8601: range.split('_')[0] },
          end_date: { iso8601: range.split('_')[1] },
        },
      ),
    },
  }
}

/**
 * Product Card -> size detail
 */
export const argFnGetProductAvailableSizesDetails = ({
  nornaid,
  toCurrency,
  qaAnnotation,
  metaName,
  taxRate = 0,
}: {
  nornaid: string;
  toCurrency?: string;
  qaPage?: boolean;
  qaAnnotation?: boolean;
  metaName?: string;
  taxRate?: number;
}): RequestParamType => ({
  url: '/get_product_available_sizes_details',
  query: {
    nornaid,
    to_currency: toCurrency,
    qa_annotation: qaAnnotation,
    meta_name: metaName,
    tax_rate: taxRate,
  },
  data: {},
})

/**
 * Price drivers period to period
 */
export const argFnAveragePriceComparison = (query, range, lookbooks: Array<string> = [], comparisonDate) => {
  const isLookbook = !!lookbooks.length

  const data: any = {
    comparison_date: {
      start_date: { iso8601: comparisonDate.split('_')[0] },
      end_date: { iso8601: comparisonDate.split('_')[1] },
    },
    customer_seller: query.customer_seller,
    competitor_sellers: query.sellers,
    market: query.market,
    groups: query.groups,
    query: getQueryPayloadV2(
      {
        ...query.collection.query,
        categoryTree: query.customer_seller.vendor + '_category',
      },
      [],
      {
        start_date: { iso8601: range.split('_')[0] },
        end_date: { iso8601: range.split('_')[1] },
      },
    ),
    sort_by: query?.collection?.query?.sortField?.sortField ? {
      field: query?.collection?.query?.sortField?.sortField,
      order: query?.collection?.query?.sortField?.sortOrder,
    } : undefined,
  }

  if (isLookbook) data.lookbooks = lookbooks
  return {
    url: 'navigation/average_price_comparison/',
    query: {
      is_lookbook: isLookbook,
      currency: query.collection.query.regions.currency ?? 'SEK',
    },
    data,
  }
}

export const argFnDashboardGraphComparison = (query: {
  region: string;
  targetGroup: string[];
  currency: string;
  allCategory: string[];
  metrics: string[];
  date: string;
  comparisonDate?: string;
  export?: boolean;
  graphImage?: string;
  competitors: string[];
  market: string[];
  groups: GroupsType;
  inclDiscount: string;
  targetGraph?: string;
  properties?: any;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}) => {
  return {
    url: 'dashboard/graph_comparison',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      currency: query.currency,
      date: query.date,
      comparison_date: query.comparisonDate,
      target_graph: query.targetGraph,
      no_selection_dates_size: query.noSize,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.targetGroup,
      competitors: query.competitors,
      metrics: query.metrics,
      all_category: query.allCategory,
      market: query.market,
      groups: query.groups,
      properties: query.properties,
      price_filter: query.priceFilter,
      lookbooks: query.lookbooks,
    },
  }
}

/**
 * Price drivers (intro period) -> Line chart
 */
export const argFnPriceIndicesComparison = (query, range, lookbookList: string[] = []) => {
  const isLookbook = !!lookbookList.length

  return {
    url: 'navigation/price_indices_comparison/',
    query: {
      is_lookbook: isLookbook,
      currency: query.collection.query.regions.currency ?? 'SEK',
    },
    data: {
      lookbooks: !isLookbook ? null : lookbookList,
      customer_seller: query.customer_seller,
      competitor_sellers: query.sellers,
      market: query.market,
      groups: query.groups,
      query: getQueryPayloadV2(
        {
          ...query.collection.query,
          categoryTree: query.customer_seller.vendor + '_category',
        },
        [],
        {
          start_date: { iso8601: range.split('_')[0] },
          end_date: { iso8601: range.split('_')[1] },
        },
      ),
    },
  }
}

/**
 * Discount depth and width -> Line chart
 */
export const argFnDiscountDepthAndWidthComparison = (query, range, lookbooks: Array<string> = [], comparisonDate: string) => {
  const isLookbook = !!lookbooks.length

  const sortBy = query?.collection?.query?.sortField
  return {
    url: 'discount_depth_and_width_comparison',
    query: {
      is_lookbook: isLookbook,
      currency: query.collection.query.regions.currency ?? 'SEK',
    },
    data: {
      is_lookbook: isLookbook,
      lookbooks: isLookbook ? lookbooks : null,
      currency: query.collection.query.regions.currency ?? 'SEK',
      customer_seller: query.customer_seller,
      competitor_sellers: query.sellers,
      market: query.market,
      groups: query.groups,
      sort_by: sortBy ? {
        field: sortBy.sortField,
        order: sortBy.sortOrder,
      } : undefined,
      query: getQueryPayloadV2(
        {
          ...query.collection.query,
          categoryTree: (query.customer_seller?.vendor ?? '') + '_category',
        },
        [],
        {
          start_date: { iso8601: range.split('_')[0] },
          end_date: { iso8601: range.split('_')[1] },
        },
      ),
      comparison_date: {
        start_date: { iso8601: comparisonDate.split('_')[0] },
        end_date: { iso8601: comparisonDate.split('_')[1] },
      },
    },
  }
}

/**
 * Category / Color / Material split -> Line chart
 */
export const argFnNewAssortmentOverTimeComparison = (query, range, lookbooks: Array<string> = [], comparisonDate: string) => {
  const isLookbook = !!lookbooks.length
  let selectedProperty = ''
  if (query.tab === 'category') selectedProperty = 'category'
  if (query.tab === 'color') selectedProperty = 'color'
  if (query.tab === 'material') selectedProperty = 'material'
  const queryPayLoad = getQueryPayloadV2(
    {
      ...query.collection.query,
      categoryTree: query.customer_seller?.vendor + '_category',
    },
    [],
    {
      start_date: { iso8601: range ? range?.split('_')[0] : '' },
      end_date: { iso8601: range ? range?.split('_')[1] : '' },
    },
  )
  if (query.categoryParsed) queryPayLoad.categories = query.categoryParsed
  return {
    url: 'new_assortment_over_time_comparison',
    query: {
      is_lookbook: isLookbook,
      selected_property: selectedProperty,
    },
    data: {
      // is_lookbook: isLookbook,
      lookbooks: isLookbook ? lookbooks : null,
      // start_date: { iso8601: range.split('_')[0] },
      // end_date: { iso8601: range.split('_')[1] },
      customer_seller: query.customer_seller,
      /**
       * 之前是根据 customer_seller 和 competitor_sellers 两个字段过滤线图数据的
       * 现在只根据 competitor_sellers 一个字段过滤线图数据，允许全部取消勾选，此时该字段就是个空数组
       */
      competitor_sellers: query.competitor_sellers || [],
      // competitor_sellers: formatCompetitorsFromStore(query.competitor_sellers || [],query?.collection?.query?.regions.key),
      market: query.market,
      query: queryPayLoad,
      groups: query.groups,
      comparison_date: {
        start_date: { iso8601: comparisonDate.split('_')[0] },
        end_date: { iso8601: comparisonDate.split('_')[1] },
      },
    },
  }
}

export const argFnWeeklyProductsIn = (query: {
  region: string;
  targetGroup: string[];
  date: string;
  competitors: string[];
  allCategory: string[];
  priceFilter: any;
  properties: any;
  market: any;
  groups: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}) => {
  return {
    url: 'dashboard/weekly_products_in',
    query: {
      customer: storage.getCustomerVendor(),
      region: query.region,
      date: query.date,
      no_selection_dates_size: query.noSize,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.targetGroup,
      competitors: query.competitors,
      all_category: query.allCategory,
      market: query?.market || [],
      groups: query?.groups || {},
      price_filter: query?.priceFilter,
      properties: query?.properties,
      lookbooks: query?.lookbooks,
    },
  }
}

export const argFnDashboardShopsSalesCountRanking = (query: {
  customer: string;
  region: string;
  currency: string;
  target: string[];
  competitors: string[];
  market: string[];
  groups: GroupsType;
  date: string;
  comparisonDate: string;
  export: boolean;
  categories: string[];
  inclDiscount: string;
  priceRange: string;
  taxFilter: TaxFilterType;
  properties: any;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}): any => {
  return {
    url: '/dashboard/shops_sales_count_ranking',
    query: {
      customer: query.customer,
      region: query.region,
      currency: query.currency,
      date: query.date,
      comparison_date: query.comparisonDate,
      export: query.export || false,
      no_selection_dates_size: query.noSize,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.target,
      competitors: query.competitors,
      market: query.market,
      groups: query.groups,
      all_category: getCategoriesPayload(query.categories),
      price_filter: query.priceFilter,
      properties: query.properties,
      lookbooks: query.lookbooks,
    },
  }
}

export const argFnDashboardShopsSalesOptionsAndPercentage = (query: {
  customer: string;
  region: string;
  currency: string;
  target: string[];
  competitors: string[];
  market: string[];
  groups: GroupsType;
  vendor: string;
  category: string;
  date: string;
  comparisonDate: string;
  export: boolean;
  categories: string[];
  inclDiscount: string;
  priceRange: string;
  taxFilter: TaxFilterType;
  properties: any;
  priceFilter: any;
  lookbooks?: string[];
  noSize?: boolean;
  noHistoricalSize?: boolean;
  noUnavailableSize?: boolean;
  noHarmonizedSize?: boolean;
  zalandodeepShopEmptyString?: boolean;
  ignoreNoAvailableSize?: boolean;
}): any => {
  return {
    url: '/dashboard/shops_sales_options_and_percentage',
    query: {
      customer: query.customer,
      region: query.region,
      currency: query.currency,
      date: query.date,
      vendor: query.vendor,
      category: query.category,
      comparison_date: query.comparisonDate,
      export: query.export || false,
      no_selection_dates_size: query.noSize,
      no_historical_size: query.noHistoricalSize,
      no_unavailable_size_update: query.noUnavailableSize,
      no_harmonized_size: query?.noHarmonizedSize,
      zalandodeep_shop_empty_string: query?.zalandodeepShopEmptyString,
      ignore_no_available_size: query?.ignoreNoAvailableSize,
    },
    data: {
      target_group: query.target,
      competitors: query.competitors,
      market: query.market,
      groups: query.groups,
      all_category: getCategoriesPayload(query.categories),
      price_filter: query.priceFilter,
      properties: query.properties,
      lookbooks: query.lookbooks,
    },
  }
}
