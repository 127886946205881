import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useFetch } from 'libs'
import { argFnDashboardComparison } from 'graphql/nornaapi'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterCurrency, useFilterGender, useFilterIgnoreNoAvailableSize, useFilterNoHarmonizedSize, useFilterNoHistoricalSize, useFilterNoSize, useFilterNoUnavailableSize, useFilterPricePerspective, useFilterTax, useFilterZalandodeepShopEmptyString } from 'hooks/useFilter'
import { usePageDate } from 'hooks/usePageDate'
import { storage } from 'utils/storage'
import { cloneDeep, uniq } from 'lodash'
import { useModuleData } from 'hooks/useModuleData'
import { ASSORTMENT_INSIGHT_TABLE_NAME, CROSS_REGION_SOLD_OUT_NAME, DYNAMIC_DASHBOARD_NAME, IN_OUT_MONITORING_TABLE_NAME, PRICE_SPREADS_TABLE_NAME } from 'configs'
import { useCompetitorOptions } from 'hooks/useOptions'
import { CATEGORY_TOTAL, MARKET_VENDOR, SELECTED_CATEGORY, SELECTED_VENDORS } from 'consts'
import { getCompetitorPayload, getDashboardPropertiesPayload, getGroupsPayload, getMarketPayload, getPriceFilterPayload, getTargetGroupPayload } from 'utils/queryPayload'
import { useExcludeProductsComparisonQuery, useManualLoading } from 'hooks'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { useLookbook } from 'hooks/useLookbook'
import { PLATFORM_ANALYTICS_MODULE_NAME, SOLD_OUT_VENDORS_TABLE } from 'features/filters/moduleDataSlice.const'
import { usePlatformAnalyticsModuleData } from 'features/filters/moduleDataSlice.hook'
import { dateRangeUtils } from 'norna-uikit'
import { UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE, isSubsetPayload } from 'features/filters/moduleDataSlice'
import { isCompetitorGroupChanged } from 'utils/group'
import { isSuperArray } from 'utils/array'

export const useDashboardComparison = ({
    moduleName,
}: {
    moduleName: string;
}) => {
    let metric: 'Price' | 'Market' | 'In&out' | 'Assortment' | 'Dynamic' | 'Sold out' | 'Traditional sold out' | 'Zalando Shops' = 'Market'
    if (moduleName === ASSORTMENT_INSIGHT_TABLE_NAME) {
        metric = 'Assortment'
    } else if (moduleName === PRICE_SPREADS_TABLE_NAME) {
        metric = 'Price'
    } else if (moduleName === IN_OUT_MONITORING_TABLE_NAME) {
        metric = 'In&out'
    } else if (moduleName === DYNAMIC_DASHBOARD_NAME) {
        metric = 'Dynamic'
    } else if (moduleName === SOLD_OUT_VENDORS_TABLE) {
        metric = 'Sold out'
    } else if (moduleName === CROSS_REGION_SOLD_OUT_NAME) {
        metric = 'Traditional sold out'
    } else if (moduleName === PLATFORM_ANALYTICS_MODULE_NAME) {
        metric = 'Zalando Shops'
    } else {
        metric = 'Market'
    }

    const [ filterCategory ] = useFilterCategory()
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterNoSize ] = useFilterNoSize()
    const [ filterNoHistoricalSize ] = useFilterNoHistoricalSize()
    const [ filterNoUnavailableSize ] = useFilterNoUnavailableSize()
    const [ filterNoHarmonizedSize ] = useFilterNoHarmonizedSize()
    const [ filterZalandodeepShopEmptyString ] = useFilterZalandodeepShopEmptyString()
    const [ filterIgnoreNoAvailableSize ] = useFilterIgnoreNoAvailableSize()
    const { pageDate: date, comparisonPageDate: comparisonDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks, isLookbook } = useLookbook()
    const { manualLoading, showManualLoading } = useManualLoading()

    const { postFn: fetch, data, loading } = useFetch()
    const [ filteredApiData, setFilteredApiData ] = useState<any>({})

    // eslint-disable-next-line
    const [ moduleData, setModuleData ] = useModuleData(moduleName)
    const [ , setPlatformAnalyticsModuleData ] = usePlatformAnalyticsModuleData()

    const {
        oldCompetitorValue = [],
        newCompetitorValue = [],
        fetchPayload,
        apiData,
    } = moduleData

    const handleCacheData = () => {
        let newApiData = cloneDeep(apiData)
        if (!isLookbook) {
            Object.keys(newApiData || {}).forEach(vendorCode => {
                if (!newCompetitorValue.includes(vendorCode)) {
                    delete newApiData[vendorCode]
                }
            })
        }
        setFilteredApiData(newApiData)
    }

    const fetchData = async () => {
        if (!filterCompetitor.length || !competitorOptions?.length) return
        const priceFilter = getPriceFilterPayload(comparisonQuery.collection.query)
        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const competitorVendors = [ ...filterCompetitor.map(item => item.vendor), SELECTED_VENDORS ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: filterCountry }))

        const market = getMarketPayload({
            competitorOptions,
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers ],
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        let target = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
        if (moduleName === SOLD_OUT_VENDORS_TABLE) {
            target = target.filter(item => [ 'Men', 'Women' ].includes(item))
        }

        const categories = [ ...filterCategory ]

        const payload = argFnDashboardComparison({
            customer: storage.getCustomerVendor(),
            region: filterCountry,
            target,
            currency: filterCurrency,
            competitors,
            market,
            groups,
            date: dateRangeUtils.from(date),
            comparisonDate: dateRangeUtils.from(comparisonDate),
            metric,
            export: false,
            categories,
            inclDiscount: filterPricePerspective,
            taxFilter: filterTax,
            priceRange: '',
            properties,
            priceFilter,
            lookbooks,
            noSize: filterNoSize,
            noHistoricalSize: filterNoHistoricalSize,
            noUnavailableSize: filterNoUnavailableSize,
            noHarmonizedSize: filterNoHarmonizedSize,
            zalandodeepShopEmptyString: filterZalandodeepShopEmptyString,
            ignoreNoAvailableSize: filterIgnoreNoAvailableSize,
        })

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload })) {
            showManualLoading()
            return
        }

        setModuleData({ 
            type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, 
            payload: cloneDeep(payload), 
        })

        if (moduleName === PLATFORM_ANALYTICS_MODULE_NAME) {
            setPlatformAnalyticsModuleData({ 
                tableApiPayload: cloneDeep(payload),
                tableApiLoading: true, 
            })
        } else {
            setModuleData({ fetchPayload: cloneDeep(payload) })
        }
        await fetch(payload.url, payload)
        if (moduleName === PLATFORM_ANALYTICS_MODULE_NAME) {
            setPlatformAnalyticsModuleData({ 
                tableApiLoading: false, 
            })
        }
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, date, comparisonDate, competitorOptions, lookbooks ])

    useDeepCompareEffect(() => {
        
        if (!newCompetitorValue?.length) return

        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleCacheData()
            return
        }

        fetchData()
    }, [ newCompetitorValue, [] ])

    /**
     * 更新 newCompetitorValue
     */
    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: { competitorValue: filterCompetitor.map(item => item.vendor) },
        })
    }, [ filterCompetitor, [] ])
    /**
     * 接口返回的数据保存一份到内存中
     */
    useDeepCompareEffect(() => {
        if (!data) return

        const newData = cloneDeep(data)

        if (!isLookbook) {
            // 是否选中全部一级 category, 全部选中显示 All categories, 否则显示 Selected categories
            const categoryTreeList = storage.getCategoryTreeList()
            if (getFirstLevelCategories({ selectedCategories: filterCategory }).length !== categoryTreeList.length) {
                Object.keys(newData).forEach(vendor => {
                    newData[vendor][SELECTED_CATEGORY] = cloneDeep(newData[vendor][CATEGORY_TOTAL])
                    delete newData[vendor][CATEGORY_TOTAL]
                })
            }
        }

        setFilteredApiData({ ...newData })
        if (moduleName === PLATFORM_ANALYTICS_MODULE_NAME) {
            setPlatformAnalyticsModuleData({ tableApiData: { ...newData } })
        } else {
            setModuleData({ apiData: { ...newData } })
        }
    }, [ data, {} ])

    return {
        fetchData,
        data: filteredApiData,
        loading: loading || manualLoading,
    }
}

export const useDashboardMarkInfo = ({
    moduleName,
}: {
    moduleName: string;
}) => {
    let metric: | 'Market' | 'Assortment' = 'Market'
    if (moduleName === ASSORTMENT_INSIGHT_TABLE_NAME) {
        metric = 'Assortment'
    } else {
        metric = 'Market'
    }

    const [ filterCategory ] = useFilterCategory()
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterNoSize ] = useFilterNoSize()
    const [ filterNoHistoricalSize ] = useFilterNoHistoricalSize()
    const [ filterNoUnavailableSize ] = useFilterNoUnavailableSize()
    const [ filterNoHarmonizedSize ] = useFilterNoHarmonizedSize()
    const [ filterZalandodeepShopEmptyString ] = useFilterZalandodeepShopEmptyString()
    const [ filterIgnoreNoAvailableSize ] = useFilterIgnoreNoAvailableSize()
    const { pageDate: date, comparisonPageDate: comparisonDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks } = useLookbook()

    const { postFn: fetch, data, loading } = useFetch()
    const [ filteredApiData, setFilteredApiData ] = useState<any>({})

    const fetchData = () => {
        if (!filterCompetitor.length || !competitorOptions?.length) return
        const priceFilter = getPriceFilterPayload(comparisonQuery.collection.query)
        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const competitorSellers = [ storage.getCustomerVendor(), MARKET_VENDOR.vendor ].map(vendor => ({ vendor, region: filterCountry }))

        const market = getMarketPayload({
            competitorOptions,
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        const target = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        const payload = argFnDashboardComparison({
            customer: storage.getCustomerVendor(),
            region: filterCountry,
            target,
            currency: filterCurrency,
            competitors,
            market,
            groups: {},
            date: dateRangeUtils.from(date),
            comparisonDate: dateRangeUtils.from(comparisonDate),
            metric,
            export: false,
            categories: [ ...filterCategory ],
            inclDiscount: filterPricePerspective,
            taxFilter: filterTax,
            priceRange: '',
            properties,
            priceFilter,
            lookbooks,
            noSize: filterNoSize,
            noHistoricalSize: filterNoHistoricalSize,
            noUnavailableSize: filterNoUnavailableSize,
            noHarmonizedSize: filterNoHarmonizedSize,
            zalandodeepShopEmptyString: filterZalandodeepShopEmptyString,
            ignoreNoAvailableSize: filterIgnoreNoAvailableSize,
        })
        payload.url = 'dashboard/top_info'
        fetch(payload.url, payload)
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, date, comparisonDate, competitorOptions, lookbooks ])

    useDeepCompareEffect(() => {
        if (!data) return

        const newData = cloneDeep(data)
        // 是否选中全部一级 category, 全部选中显示 All categories, 否则显示 Selected categories
        const categoryTreeList = storage.getCategoryTreeList()
        if (getFirstLevelCategories({ selectedCategories: filterCategory }).length !== categoryTreeList.length) {
            Object.keys(newData).forEach(vendor => {
                newData[vendor][SELECTED_CATEGORY] = cloneDeep(newData[vendor][CATEGORY_TOTAL])
                delete newData[vendor][CATEGORY_TOTAL]
            })
        }

        setFilteredApiData({ ...newData })
    }, [ data, filterCompetitor, {} ])

    return {
        fetchData,
        data: filteredApiData,
        loading,
    }
}
