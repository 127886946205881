import React, { FC, useState, useEffect, CSSProperties } from 'react'
import classnames from 'classnames'
import { LazyBgImg } from 'components/Image'
import { isKeepOriginalAspectRatio } from 'utils/imgUtils'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { QaCheck } from 'components/Product/components/QaCheck'
import { useQa } from 'hooks/useQa'
import { getVendorNameByCode } from 'utils'
import { ProductImageProps } from './types'
import styles from './styles.module.scss'
import { ImageAnnotation } from '../ImageAnnotation'

/**
 * ProductImage
 * 
 * 一张大图带很多缩略图
 */
export const ProductImage: FC<ProductImageProps> = ({ 
    images = [], 
    defaultSelectedImage,
    style = {},
    product,
    enabledQa=false,
}) => {
    // 当前选中的图片
    const [ selectedImage, setSelectedImage ] = useState<string>(defaultSelectedImage || images[0])

    /**
     * 默认选中图片
     */
    useEffect(() => {
        if (defaultSelectedImage) {
            setSelectedImage(defaultSelectedImage)
        }
    }, [ defaultSelectedImage ])

    /**
     * 图片列表变化时，重新设置图片列表第一张图片为选中图片
     */
    useEffect(() => {
        if (Array.isArray(images) && images.length > 0) {
            setSelectedImage(images[0])
        }
    }, [ images ])

    return (
        <div className={styles.productImage} style={style}>
            <div className={styles.bigImage}>
            {
                product ? (
                    <ImageAnnotation 
                        productOriginalUrl={product?.originalUrl??''}
                        productUrl={product?.url??''}
                        nornaid={product?.nornaid??''}
                        vendor={product?.vendor || ''}
                        vendorName={getVendorNameByCode(product?.vendor) || ''}
                        enabledQa={enabledQa}
                        imageUrl={selectedImage}
                        nocache
                    />
                ) : null
            }
            </div>
            <div className={styles.smallImageList}>
                {
                    images.map(image => (
                        <div key={image} className={classnames(styles.smallImageItem, image === selectedImage && styles.selectedSmallImageItem)}>
                            <LazyBgImg 
                                cropedImg={!isKeepOriginalAspectRatio(getVendorNameByCode(product?.vendor) || '')}
                                src={`${image}&width=121&height=98`}
                                onClick={() => setSelectedImage(image)}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

ProductImage.displayName = 'ProductImage'

export const ProductImageWrapper = ({
    images = [],
    originalUrl = '',
    url = '',
    vendor = '',
    nornaid,
    enabledQa,
    style,
    className,
}: {
    images: string[];       // 小图列表
    originalUrl?: string;    // 原始产品地址, 可能会带 nornaRegionInfo 参数
    url?: string;            // 产品地址, 不带 nornaRegionInfo 参数
    vendor?: string;         // 产品所述供应商, e.g Canada Goose@farfetch
    nornaid: string;
    enabledQa?: boolean;
    style?: CSSProperties;
    className?: string;
}) => {
    /* ********************************* Qa *********************************** */
    const { updateCheckedProduct, checkedProduct } = useQa()
    
    return (
        <div style={{ width: '400px', ...style }} className={classnames([ styles.imageWrapper, className ])}>
            <ShouldRender shouldRender={(images || []).length > 0}>
                <ProductImage
                    images={images || []}
                    product={{
                        originalUrl,
                        url,
                        nornaid,
                        vendor,
                    }}
                    enabledQa={enabledQa}
                />
                <ShouldRender shouldRender={!!enabledQa}>
                    <QaCheck style={{ zIndex: 9999 }} checked={checkedProduct[nornaid]} onChange={() => updateCheckedProduct(nornaid)} />
                </ShouldRender>
            </ShouldRender>
        </div>
    )
}
