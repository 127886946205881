import { ProgressBarContext } from 'components/ProgressBar/ProgressBarContext'
import { IS_LIVE_ENV, PERMISSIONS, PropertyQaAnnotation } from 'consts'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { getQaData, updateCheckedReturn } from 'features/filters/qaDataSlice'
import { batchConfirmReportArg, batchReportArg, deleteImageArg, getReportedArg, reportToolArg, setFirstImageArg, unsetReportArg } from 'graphql/nornaapi/report'
import { disableCancelRequestFn, useFetch } from 'libs'
import { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useQaPermission } from './useQaPermission'

function getReportSpliting(currentNornaIds: Array<string>, getReport: Function, qaPage = false) {
    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
        try {
            const query = disableCancelRequestFn(getReportedArg(currentNornaIds, PropertyQaAnnotation));
            if (qaPage) query.query.qa_page = qaPage;
            const res = await getReport(query.url, query)
            resolve(res.data);
        } catch (e) {
            console.log(e);
            reject([])
        }
    })
    /* eslint-disable */
}
export const asyncForEach = async (arr, predicate) => {
    const results = await Promise.all(arr.map(predicate));
    return arr.map((_v, index) => results[index]);
};

/**
 * Report Tool hooks
 * 
 * @returns 
 */
export function useReport() {
    const { account: currentUser, ourSeller } = useSelector(getCacheData);
    const { currentNornaIds, checkedReturn } = useSelector(getQaData);
    const { postFn: batchReportFn } = useFetch();
    const { postFn: batchCancelReportFn } = useFetch();
    const { getFn: qaRun } = useFetch();
    const { getFn: qaCheck } = useFetch();
    const { postFn: reportFn } = useFetch();
    const { getFn: setFirstImageFn } = useFetch();
    const { getFn: deleteImage } = useFetch();
    const { postFn: getReport } = useFetch();
    const { getFn: getQaCount } = useFetch();
    const { postFn: deleteProduct } = useFetch();

    const { qaPage, checkedProduct } = useSelector(getQaData);
    const qaPermission = useQaPermission()
    const enabledReport = !IS_LIVE_ENV && qaPermission.includes(PERMISSIONS.QA)
    const enableQaRun = !IS_LIVE_ENV && qaPermission.includes(PERMISSIONS.QQ_RUN)
    const { snackOpen } = useContext(ProgressBarContext);

    const dispatch = useDispatch()
    /**
    * get report product
    */
    const getReportedFn = useCallback(async () => {
        try {
            if (!enabledReport) return;
            if (currentNornaIds.length === 0) { // no nornaid exists
                dispatch(updateCheckedReturn({}))
            } else {
                /* eslint-disable */
                const promises: Array<any> = [];
                const len = 7000;
                for (let i = 0; i <= Math.floor(currentNornaIds.length / len); i++) {
                    promises.push(currentNornaIds.slice(i * len, (i + 1) * len))
                }
                const res = await asyncForEach(promises, async item => await getReportSpliting(item, getReport, qaPage));
                dispatch(updateCheckedReturn(res.reduce((res: any, item) => ({
                    ...res,
                    ...item
                }), {})))
                /* eslint-disable */
            }
        } catch (e) {
            console.log(e)
        }
    }, [dispatch, currentNornaIds, enabledReport, qaPage, getReport])

    useDeepCompareEffect(() => {
        getReportedFn()
    }, [currentNornaIds, []])

    return {

        enabledReport,
        snackOpen,
        reportedProducted: checkedReturn,

        /**
         *  Get reported function 
         */
        getReportedFn,
        /**
         * report for single product
         * @param url 
         * @param mask 
         * @param field 
         * @returns 
         */
        reportFn: async (url: string, mask: Array<any>, field, unset = false, vendor = '', crossVariation = false) => {
            const payload = { user: currentUser?.email, url, mask, field, unset, vendor, crossVariation };
            const query: any = reportToolArg(payload);
            if (qaPage) query.query.qa_page = qaPage;
            const res = await reportFn(query.url, query)
            return res;
        },

        /***
         * ## set first image for product
         * 
         * @param imageName string, image name
         */
        setFirstImageFn: async (imageName: string, nornaid: string) => {
            const payload = { user: currentUser?.email, imageName, };
            const query: any = setFirstImageArg(payload);
            const res = await setFirstImageFn(`${query.url}`, {
                user: `${query.query.user}`,
                image_name: `${imageName}`,
                nornaid,
                qa_page: qaPage,
            })
            return res;
        },

        deleteImageFn: async (imageName: string, nornaid: string) => {
            const payload = { user: currentUser?.email, imageName };
            const query: any = deleteImageArg(payload);
            const res = await deleteImage(`${query.url}`, {
                user: `${query.query.user}`,
                image_name: `${imageName}`,
                nornaid,
                qa_page: qaPage,
            })
            return res;
        },

        /**
         * report for batch product
         * @param nornaids 
         * @param mask 
         * @returns 
         */
        batchReportFn: async (mask: Array<any>, type: string, unset = false, products: {nornaid: string; vendor: string;}[] = [], crossVariation = false) => {
            const query: any = batchReportArg(currentUser?.email, mask, type, unset, products, crossVariation)
            if (qaPage) query.query.qa_page = qaPage;
            const res = await batchReportFn(query.url, query)
            return res;
        },


        qaPage: qaPage,
        /**
         * confirm for batch product
         * @param nornaids 
         * @param mask 
         * @returns 
         */
        batchConfirmReportFn: async (preliminaryConfirm = false) => {
            let nornaids = Object.keys(checkedProduct).filter(nornaid => checkedProduct[nornaid]);
            if (!nornaids.length) return nornaids;
            const query: any = batchConfirmReportArg(currentUser?.email, nornaids, preliminaryConfirm)
            if (qaPage) query.query.qa_page = qaPage;
            const res = await batchReportFn(query.url, query)
            return res;
        },
        /**
         * batch cancel report
         * @param nornaids 
         * @returns 
         */
        batchCancelReportFn: async (nornaids: Array<string>) => {
            const query = unsetReportArg(nornaids)
            const res = await batchCancelReportFn(query.url, query)
            return res;
        },

        enableQaRun,

        //qa run function
        qaRun: async (isPro: boolean = false) => {
            let res = await qaRun('/update_masks/', { qa_page: qaPage, production: isPro })
            return res;
        },

        //qa check function
        qaCheck: async (isPro: boolean = false, date: string) => {
            let res = await qaCheck('/check_masks/', { qa_page: qaPage, production: isPro, date })
            return res;
        },

        qaCount: async () => {
            let res = await getQaCount('/gate_count/')
            return res
        },

        deleteProduct: async (payload) => {
            const res = await deleteProduct(payload.url, payload)
            return res
        },
    }

}

