import React from 'react'
import classnames from 'classnames'
import { NornaBarChart } from 'componentsv2/NornaBarChart'
import { useWindowZoom } from 'hooks/useWindow'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { ASSORTMENT_INSIGHT_TABLE_NAME } from 'configs/moduleName'
import { OPTIONS_NAME } from 'consts/strings'
import { useFilterCountry, useFilterCurrency } from 'hooks/useFilter'
import { getVendorNameByCode, regionShortMappingFn } from 'utils'
import { storage } from 'utils/storage'
import { Flex, Flex1 } from 'componentsv2/Flex'
import { DisplayText } from 'componentsv2/DisplayText'
import { DashboardDoughnutChart } from 'componentsv2/DashboardDoughnutChart'
import { usePageDate } from 'hooks/usePageDate'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

export const AssortmentInsightTopKpi = ({
    marketData = {},
    customerData = {},
}: {
    marketData: any;
    customerData: any;
}) => {
    useWindowZoom()
    const customerVendor = storage.getCustomerVendor()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()
    // eslint-disable-next-line
    const { pageDate, comparisonPageDate } = usePageDate()

    return (
        <div style={{ width: '1440px' }} className="norna-container-fixed-width">
            <div className={styles.row1}>
                <div className={classnames([ styles.column, styles.column1 ])}>
                    <div className={styles.left}>
                        <ModuleTitle showDate={false} category="DASHBOARD" title={ASSORTMENT_INSIGHT_TABLE_NAME} />
                        <div className={styles.customerVendor}>{getVendorNameByCode(customerVendor)}</div>
                        <div>
                            <span className={styles.kpiName}>{OPTIONS_NAME}</span>&nbsp;
                            <span className={styles.region}>{regionShortMappingFn(filterCountry)}</span>
                        </div>
                        <div className={styles.currency}>({filterCurrency})</div>
                        <Flex1 />
                        {/* <div className={styles.date}>
                            {`${formatDateStr2(pageDate)} vs ${formatDateStr2(comparisonPageDate)}`}
                        </div> */}
                        <div className={styles.remark}>
                            <span>%&nbsp;</span>
                            <span>= vs your comparison period</span>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.kpiName}>Options</div>
                        <NornaBarChart
                            direction="vertical"
                            currentValue={customerData?.optionsCurrentValue}
                            comparisonValue={customerData?.optionsComparisonValue}
                            maxValue={customerData?.optionsMaxValue}
                            changePercent={customerData?.optionsChangePercent}
                        />
                    </div>
                </div>
                <div className={classnames([ styles.column, styles.column3 ])}>
                    <div className={styles.kpiTitle}>Size ratio</div>
                    <div className={styles.chartWrapper}>
                        <NornaBarChart
                            currentValue={customerData?.sizeRatioCurrentValue?.toFixed(1) || 0}
                            comparisonValue={customerData?.sizeRatioComparisonValue?.toFixed(1) || 0}
                            maxValue={customerData?.sizeRatioMaxValue || 0}
                            changePercent={customerData?.sizeRatioChangePercent}
                        />
                    </div>
                    <div className={styles.kpiDesc} />
                </div>
                <div className={classnames([ styles.column, styles.column2 ])}>
                    <div className={styles.kpiTitle}>Option ratio</div>
                    <div className={styles.chartWrapper}>
                        <NornaBarChart 
                            currentValue={customerData?.optionRatioCurrentValue?.toFixed(1) || 0}
                            comparisonValue={customerData?.optionRatioComparisonValue?.toFixed(1) || 0}
                            maxValue={customerData?.optionRatioMaxValue || 0}
                            changePercent={customerData?.optionRatioChangePercent}
                        />
                    </div>
                    <div className={styles.kpiDesc} />
                </div>
                <div className={classnames([ styles.column, styles.column5 ])}>
                    <div className={styles.kpiTitle}>Sold out</div>
                    <div className={styles.chartWrapper}>
                        <DashboardDoughnutChart 
                            value={(customerData?.soldOutCurrentValue || 0) * 100}
                            content={(
                                <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                                    <div style={{ fontSize: '18px', color: '#666666', fontWeight: 'bold' }}>{((customerData?.soldOutCurrentValue || 0) * 100).toFixed(1) + '%'}</div>
                                    <div style={{ height: '1px', width: '59px', backgroundColor: '#D8D8D8' }} />
                                    <DisplayText
                                        style={{ fontWeight: 'bold', marginTop: '2px' }}
                                        value={(customerData?.soldOutChangePercent * 100).toFixed(1) + '%'} 
                                        fontSize={14}
                                    />
                                </Flex>
                            )}
                        />
                    </div>
                    <div className={styles.kpiDesc}>change is in <span className={styles.colorPrimary}>% units</span></div>
                </div>
                <div className={classnames([ styles.column, styles.column4 ])}>
                    <div className={styles.kpiTitle}>Eco label</div>
                    <div className={styles.chartWrapper}>
                        <DashboardDoughnutChart 
                            value={(customerData?.ecoLabelCurrentValue || 0) * 100}
                            content={(
                                <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                                    <div style={{ fontSize: '18px', color: '#666666', fontWeight: 'bold' }}>{((customerData?.ecoLabelCurrentValue || 0) * 100).toFixed(1) + '%'}</div>
                                    <div style={{ height: '1px', width: '59px', backgroundColor: '#D8D8D8' }} />
                                    <DisplayText
                                        style={{ fontWeight: 'bold', marginTop: '2px' }}
                                        value={(customerData?.ecoLabelChangePercent * 100).toFixed(1) + '%'} 
                                        fontSize={14}
                                    />
                                </Flex>
                            )}
                        />
                    </div>
                    <div className={styles.kpiDesc}>change is in <span className={styles.colorPrimary}>% units</span></div>
                </div>
                <div className={classnames([ styles.column, styles.column6 ])}>
                    <div className={styles.kpiTitle}>Solid</div>
                    <div className={styles.chartWrapper}>
                        <DashboardDoughnutChart 
                            value={(customerData?.solidCurrentValue || 0) * 100}
                            content={(
                                <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                                    <div style={{ fontSize: '18px', color: '#666666', fontWeight: 'bold' }}>{((customerData?.solidCurrentValue || 0) * 100).toFixed(1) + '%'}</div>
                                    <div style={{ height: '1px', width: '59px', backgroundColor: '#D8D8D8' }} />
                                    <DisplayText 
                                        style={{ fontWeight: 'bold', marginTop: '2px' }}
                                        value={(customerData?.solidChangePercent * 100).toFixed(1) + '%'} 
                                        fontSize={14}
                                    />
                                </Flex>
                            )}
                        />
                    </div>
                    <div className={styles.kpiDesc}>change is in <span className={styles.colorPrimary}>% units</span></div>
                </div>
            </div>
            <div className={styles.row2}>
                <div className={styles.marketName}>Market</div>
                <div className={styles.column1}>
                    <div className={styles.currentValue}>
                        {numberUtils.formatNumberByComma(marketData?.optionsCurrentValue || 0)}
                    </div>
                    <DisplayText 
                        value={((marketData?.optionsChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column3}>
                    <div className={styles.currentValue}>
                        {numberUtils.formatNumberByComma(marketData?.sizeRatioCurrentValue?.toFixed(1))}
                    </div>
                    <DisplayText 
                        value={((marketData?.sizeRatioChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column2}>
                    <div className={styles.currentValue}>
                        {numberUtils.formatNumberByComma(marketData?.optionRatioCurrentValue?.toFixed(1))}
                    </div>
                    <DisplayText 
                        value={((marketData?.optionRatioChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column5}>
                    <div className={styles.currentValue}>
                        {((marketData?.soldOutCurrentValue || 0) * 100)?.toFixed(1) + '%'}
                    </div>
                    <DisplayText 
                        value={((marketData?.soldOutChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column4}>
                    <div className={styles.currentValue}>
                        {((marketData?.ecoLabelCurrentValue || 0) * 100)?.toFixed(1) + '%'}
                    </div>
                    <DisplayText 
                        value={((marketData?.ecoLabelChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column6}>
                    <div className={styles.currentValue}>
                        {((marketData?.solidCurrentValue || 0) * 100)?.toFixed(1) + '%'}
                    </div>
                    <DisplayText 
                        value={((marketData?.solidChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
            </div>
        </div>
    )
}
