import Modal from 'componentsv2/Modal'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import FinancialChart, { ChartDataType, FinancialChartRefProps } from '../../../FinancialChart';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { METRIC_AVERAGE_PRICE, METRIC_DISCOUNT_EFFECT, METRIC_KEY_COLOR, METRIC_KEY_MATERIAL, METRIC_OPTIONS, METRIC_OPTIONS_IN, formatMetricStep, formatMetricValue, getMetricOptions, getMetricParamByMetricDisplay, handleChartData } from './utils';
import styles from './styles.module.scss'
import { Button, Flex1 } from 'druikit';
import { LineChartCompetitorDropdown } from 'componentsv2/business/LineChartCompetitorDropdown';
import { LineChartMetricDropdown } from 'componentsv2/business/LineChartMetricDropdown';
import { AxisSwitch } from 'componentsv2/business/AxisSwitch';
import { cloneDeep } from 'lodash';
import { ExportDropdown, getHtml2CanvasImgUploadPath } from 'componentsv2/ExportDropdown';
import { useGraphButtonModuleData } from 'features/filters/moduleDataSlice.hook';
import { usePageDate } from 'hooks/usePageDate';
import { useDashboardLineGraphData4 } from 'hooks/api/useDashboardLineGraphData';
import classNames from 'classnames';
import { getExcelCompetitorPayload, getExcelGroupsPayload, getExcelMarketPayload } from 'utils/queryPayload';
import { downloadFileByAxios } from 'export';
import { useCompetitorOptions } from 'hooks/useOptions';
import LineChartCategoryDropdown from 'componentsv2/business/LineChartCategoryDropdown';
import { useFilterCategory, useFilterCompetitor, useFilterCountry } from 'hooks/useFilter';
import { RoutesObj } from 'global/Routes';
import { RegionDropdown } from 'componentsv2/business/RegionDropdown';
import { SELECTED_VENDORS } from 'consts';
import { loadingBar } from 'hooks';
import { RedoOutlined } from '@ant-design/icons';
import { isExcludeHmAndZaraForCompetitorOptions } from 'utils/privUtils';
import { sortVendorList } from 'utils/array';
import { arrayUtils } from 'norna-uikit';
import { formatDateStr2 } from 'utils/dateUtils';
import { storage } from 'utils/storage';
import { Flex } from 'antd';

const modalClassName = 'graph-button-modal'
const modalSelector = `.${modalClassName}`
const fileName = 'Norna_Analytics_Line_Graph_Export'

const isCompetitorEqual = (comp1, comp2) => {
    if (comp1.length !== comp2.length) return false
    return arrayUtils.isEqual(comp1, comp2, [ 'vendor' ])
}

const GraphModal = ({
    onCancel,
}: {
    onCancel?: () => void;
}) => {
    const { fetchData, loading: apiLoading } = useDashboardLineGraphData4()
    
    const [ moduleData, setModuleData ] = useGraphButtonModuleData()
    const { 
        apiData, 
        apiPayload,
        switchValue,
        categoryValue, 
        metricValue, 
        competitorValue = [], 
        regionValue, 
        dateRangeValue,
        tmpCategoryValue, 
        tmpMetricValue, 
        tmpCompetitorValue = [], 
        tmpRegionValue, 
        tmpDateRangeValue,
        tmpSwitchValue,
        isFilterChanged,
    } = moduleData

    const [ filterCategory ] = useFilterCategory()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCompetitor ] = useFilterCompetitor()
    const metricOptions = getMetricOptions()
    const { competitorOptions } = useCompetitorOptions()

    // 日期
    const { pageDate: date } = usePageDate()

    /* ******************************** 初始化 switchValue ********************************** */
    useEffect(() => {
        setModuleData({ 
            switchValue: false,
            tmpSwitchValue: false, 
        })
    }, [])

    /* ******************************** 初始化 competitorValue ********************************** */
    useEffect(() => {
        if (!filterCompetitor?.length) return
        let value = [ ...filterCompetitor ].map(item => ({ vendor: item.vendor, region: item.region }))
        if (isExcludeHmAndZaraForCompetitorOptions()) {
            value = value.filter(item => ![ 'hm', 'zara' ].includes(item.vendor))
        }
        setModuleData({ 
            competitorValue: sortVendorList({ vendorList: value, vendorField: 'vendor' }), 
            tmpCompetitorValue: sortVendorList({ vendorList: value, vendorField: 'vendor' }),
        })
    }, [])

    /* ******************************** 初始化 categoryValue ********************************** */
    useEffect(() => {
        if (!filterCategory?.length) return
        setModuleData({ 
            categoryValue: filterCategory, 
            tmpCategoryValue: filterCategory,
        })
    }, [])

    /* ******************************** 初始化 regionValue ********************************** */
    useEffect(() => {
        if (!filterCountry) return
        setModuleData({ 
            regionValue: filterCountry,
            tmpRegionValue: filterCountry, 
        })
    }, [])

    /* ******************************** 初始化 dateRangeValue ********************************** */
    useEffect(() => {
        setModuleData({ 
            dateRangeValue: date, 
            tmpDateRangeValue: date,
        })
    }, [])

    /* ******************************** 初始化 metricValue ********************************** */
    useEffect(() => {
        const { pathname } = window.location

        const averagePriceList = [
            RoutesObj.PRICE_INSIGHT.URL,
            RoutesObj.PRICE_SPREADS.URL,
            RoutesObj.PRICE_HISTORY.URL,
            RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL,
            RoutesObj.PRICE_ARCHITECTURE.URL,
            RoutesObj.PRICING_JUMP_TABLE.URL,
        ]
        if (averagePriceList.includes(pathname)) {
            setModuleData({ 
                metricValue: [ METRIC_AVERAGE_PRICE ],
                tmpMetricValue: [ METRIC_AVERAGE_PRICE ],
            })
        }

        const optionsList = [
            RoutesObj.ASSORTMENT_INSIGHT.URL,
            RoutesObj.CATEGORY_ANALYTICS.URL,
            RoutesObj.POPULAR_PRODUCTS.URL,
            RoutesObj.POPULAR_ATTRIBUTES.URL,
            RoutesObj.ASSORTMENT_SIZE.URL,
            RoutesObj.SEARCH_OPTIONS.URL,
        ]
        if (optionsList.includes(pathname)) {
            setModuleData({ 
                metricValue: [ getMetricParamByMetricDisplay(METRIC_OPTIONS) ],
                tmpMetricValue: [ getMetricParamByMetricDisplay(METRIC_OPTIONS) ],
            })
        }

        const optionsInList = [
            RoutesObj.IN_OUT_MONITORING.URL,
            RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL,
            RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL,
        ]
        if (optionsInList.includes(pathname)) {
            setModuleData({ 
                metricValue: [ METRIC_OPTIONS_IN ], 
                tmpMetricValue: [ METRIC_OPTIONS_IN ], 
            })
        }

        if (pathname === RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL) {
            setModuleData({ 
                metricValue: [ METRIC_DISCOUNT_EFFECT ], 
                tmpMetricValue: [ METRIC_DISCOUNT_EFFECT ], 
            })
        }

        if (pathname === RoutesObj.COLOR_ANALYTICS.URL) {
            const defaultValue = metricOptions.find(item => item.key === METRIC_KEY_COLOR)?.children?.[0]?.key
            if (defaultValue) {
                setModuleData({ 
                    metricValue: [ defaultValue ], 
                    tmpMetricValue: [ defaultValue ], 
                })
            }
        }

        if (pathname === RoutesObj.MATERIAL_ANALYTICS.URL) {
            const defaultValue = metricOptions.find(item => item.key === METRIC_KEY_MATERIAL)?.children?.[0]?.key
            if (defaultValue) {
                setModuleData({ 
                    metricValue: [ defaultValue ], 
                    tmpMetricValue: [ defaultValue ],
                })
            }
        }
    }, [])

    /* ******************************** 线图数据 ********************************** */
    // 线图实例对象
    const chartRef = useRef<FinancialChartRefProps>(null)
    // 线图数据源
    const [ chartData, setChartData ] = useState<ChartDataType>([])

    useDeepCompareEffect(() => {
        if (!apiData || apiLoading || !competitorValue?.length || !metricValue?.length) return
        const dataSource = handleChartData({ 
            apiData, 
            switchValue, 
            competitorValue, 
            metricValue: sortMetricList(metricValue), 
            regionValue, 
        })
        setChartData(dataSource)
    }, [ apiData, apiLoading, competitorValue, switchValue, metricValue, regionValue ])

    /* ******************************** 监听弹窗宽高变化 ********************************** */
    useLayoutEffect(() => {
        const child = document.querySelector(modalSelector) as HTMLDivElement
        if (!child) return

        const observer = new ResizeObserver(function (mutations) {
            chartRef.current?.updateChartHeight()
        })
        observer.observe(child)
        return () => {
            if (child && observer) {
                observer.unobserve(child)
            }
        }
    }, [])

    /* ******************************** 导出 excel ********************************** */
    const onExportExcel = async () => {
        const payload: any = cloneDeep(apiPayload)
        payload.query.graph_image = await getHtml2CanvasImgUploadPath({ selector: modalSelector, excludeSelectors: [ `${modalSelector} .form` ], formHeight: switchValue ? 180 : 120 })
        payload.data.competitors = getExcelCompetitorPayload({ competitorOptions, returnType: 'vendor' })
        payload.data.groups = getExcelGroupsPayload({ competitorOptions })
        payload.data.market = getExcelMarketPayload({ competitorOptions, returnType: 'vendor' })
        await downloadFileByAxios({
            filename: fileName,
            payload,
        })
    }

    const sortVendors = (vendors) => {
        return sortVendorList({ vendorList: cloneDeep(vendors), vendorField: 'vendor' })
    }

    /**
     * 执行
     */
    const onExecute = () => {
        if (
            arrayUtils.isEqual(tmpCategoryValue, categoryValue)
            && isCompetitorEqual(tmpCompetitorValue, competitorValue)
            && tmpRegionValue === regionValue
            && arrayUtils.isEqual(tmpMetricValue, metricValue)
            && tmpSwitchValue === switchValue
            && tmpDateRangeValue === dateRangeValue
        ) {
            return
        }

        chartRef.current?.updateVisibleTimeRange()

        if (
            arrayUtils.isEqual(tmpCategoryValue, categoryValue)
            && tmpRegionValue === regionValue
            && arrayUtils.isEqual(tmpMetricValue, metricValue)
            && tmpDateRangeValue === dateRangeValue
            && tmpSwitchValue === switchValue
            && !isCompetitorEqual(tmpCompetitorValue, competitorValue)
        ) {
            setModuleData({
                competitorValue: tmpCompetitorValue,
            })
            const tmpVendorList = tmpCompetitorValue.map(item => item.vendor)
            const vendorList = Object.keys(apiData)
            // tmpCompetitorValue 中不包含 Selected vendors
            // 判断 tmpCompetitorValue 是 competitorValue 的子集, 就直接从缓存中取数据
            if (!tmpVendorList.includes(SELECTED_VENDORS) && tmpCompetitorValue.filter(item => vendorList.includes(item.vendor)).length === tmpCompetitorValue.length) {
                const newApiData = cloneDeep(apiData)
                Object.keys(newApiData).forEach(vendor => {
                    if (!tmpVendorList.includes(vendor)) {
                        delete newApiData[vendor]
                    }
                })
                const dataSource = handleChartData({ 
                    apiData: newApiData, 
                    switchValue, 
                    competitorValue: tmpCompetitorValue, 
                    metricValue, 
                    regionValue: filterCountry, 
                })
                setChartData(dataSource)
                setModuleData({
                    isFilterChanged: false,
                })
                return
            }
            fetchData(tmpCompetitorValue)
            loadingBar.restart()
            return
        }

        setModuleData({
            categoryValue: tmpCategoryValue,
            competitorValue: tmpCompetitorValue,
            regionValue: tmpRegionValue,
            metricValue: tmpMetricValue,
            dateRangeValue: tmpDateRangeValue,
            switchValue: tmpSwitchValue,
        })
        loadingBar.restart()
    }

    useEffect(() => {
        if (apiLoading === false) {
            if (
                arrayUtils.isEqual(tmpCategoryValue, categoryValue)
                && isCompetitorEqual(tmpCompetitorValue, competitorValue)
                && tmpRegionValue === regionValue
                && arrayUtils.isEqual(tmpMetricValue, metricValue)
                && tmpSwitchValue === switchValue
                && tmpDateRangeValue === dateRangeValue
            ) {
                setModuleData({ isFilterChanged: false })
            }
        }
    }, [ apiLoading ])

    const sortMetricList = (metricList: string[] = []) => {
        const keyList = metricOptions.map(item => {
            if (item?.children?.length) {
                return item?.children?.map(item2 => item2.key)
            }
            return item.key
        }).flat(2)
        const sortedValue = [ ...metricList ].sort((a, b) => {
            const aIndex = keyList.findIndex(i => i === getMetricParamByMetricDisplay(a))
            const bIndex = keyList.findIndex(i => i === getMetricParamByMetricDisplay(b))
            return aIndex - bIndex
        })
        return sortedValue
    }

    return (
        <Modal
            style={{ width: window.innerWidth - 80, height: window.innerHeight - 30,  zIndex: 9999999 }}
            left={40}
            onCancel={onCancel}
            className={modalClassName}
        >
            <div className={styles.wrapper}>
                <div className={classNames(styles.form, 'form')}>
                    <LineChartCategoryDropdown
                        enableFilter={false}
                        showClearAll
                        showSelectAll
                        right={false}
                        showOkButton={false}
                        selectorClassName={ !arrayUtils.isEqual(tmpCategoryValue, categoryValue) ? 'tree-dropdown-changed': '' }
                        value={tmpCategoryValue}
                        onItemChange={value => {
                            if (
                                arrayUtils.isEqual(value, categoryValue)
                                && isCompetitorEqual(tmpCompetitorValue, competitorValue)
                                && tmpRegionValue === regionValue
                                && arrayUtils.isEqual(tmpMetricValue, metricValue)
                                && tmpSwitchValue === switchValue
                                && tmpDateRangeValue === dateRangeValue
                            ) {
                                setModuleData({
                                    tmpCategoryValue: value,
                                    isFilterChanged: false,
                                })
                                return 
                            }
                            if (arrayUtils.isEqual(tmpCategoryValue, value)) return
                            setModuleData({ 
                                tmpCategoryValue: value, 
                                isFilterChanged: true,
                            })
                        }}
                    />
                    <LineChartCompetitorDropdown
                        showOkButton={false}
                        selectorClassName={!isCompetitorEqual(tmpCompetitorValue, competitorValue) ? 'dropdown-changed' : ''}
                        region={tmpRegionValue}
                        enableTwoYAxis={tmpSwitchValue}
                        onChange={(value) => {
                            if (
                                arrayUtils.isEqual(tmpCategoryValue, categoryValue)
                                && isCompetitorEqual(value, competitorValue)
                                && tmpRegionValue === regionValue
                                && arrayUtils.isEqual(tmpMetricValue, metricValue)
                                && tmpSwitchValue === switchValue
                                && tmpDateRangeValue === dateRangeValue
                            ) {
                                setModuleData({
                                    tmpCompetitorValue: value,
                                    isFilterChanged: false,
                                })
                                return 
                            }
                            const customerSeller = { vendor: storage.getCustomerVendor(), region: tmpRegionValue }
                            setModuleData({ 
                                tmpCompetitorValue: value?.length ? value : [ customerSeller ], 
                                isFilterChanged: true, 
                            })
                        }}
                    />
                    <RegionDropdown
                        enableFilterInDashboard={false}
                        selectorClassName={tmpRegionValue !== regionValue ? 'dropdown-changed' : ''}
                        value={tmpRegionValue}
                        onChange={value => {
                            if (
                                arrayUtils.isEqual(tmpCategoryValue, categoryValue)
                                && isCompetitorEqual(tmpCompetitorValue, competitorValue)
                                && value === regionValue
                                && arrayUtils.isEqual(tmpMetricValue, metricValue)
                                && tmpSwitchValue === switchValue
                                && tmpDateRangeValue === dateRangeValue
                            ) {
                                setModuleData({
                                    tmpRegionValue: value,
                                    isFilterChanged: false,
                                })
                                return 
                            }
                            setModuleData({ 
                                tmpRegionValue: value, 
                                isFilterChanged: true,
                            })
                        }}
                    />
                    <LineChartMetricDropdown
                        showOkButton={false}
                        selectorClassName={!arrayUtils.isEqual(tmpMetricValue, metricValue) ? 'tree-dropdown-changed' : ''}
                        enableTwoYAxis={tmpSwitchValue}
                        options={metricOptions}
                        value={tmpMetricValue}
                        onItemChange={value => {
                            if (
                                arrayUtils.isEqual(tmpCategoryValue, categoryValue)
                                && isCompetitorEqual(tmpCompetitorValue, competitorValue)
                                && tmpRegionValue === regionValue
                                && arrayUtils.isEqual(value, metricValue)
                                && tmpSwitchValue === switchValue
                                && tmpDateRangeValue === dateRangeValue
                            ) {
                                setModuleData({
                                    tmpMetricValue: sortMetricList(value),
                                    isFilterChanged: false,
                                })
                                return 
                            }
                            if (value.filter(item => item.startsWith('Material_')).length > 2) {
                                const firstValue = metricOptions.find(item => item.key === METRIC_KEY_MATERIAL)?.children?.[0]?.key
                                const newValue = value.filter(item => !item.startsWith('Material_'))
                                setModuleData({ 
                                    tmpMetricValue: sortMetricList([ ...newValue, firstValue ].slice(0, 2)), 
                                    isFilterChanged: true,
                                })
                                return
                            }
                            if (value.length === 1 && value[0] === METRIC_KEY_MATERIAL) {
                                const firstValue = metricOptions.find(item => item.key === METRIC_KEY_MATERIAL)?.children?.[0]?.key
                                setModuleData({ 
                                    tmpMetricValue: sortMetricList([ firstValue ]), 
                                    isFilterChanged: true,
                                })
                                return
                            }
                            if (value.filter(item => item.startsWith('Color_')).length > 2) {
                                const firstValue = metricOptions.find(item => item.key === METRIC_KEY_COLOR)?.children?.[0]?.key
                                const newValue = value.filter(item => !item.startsWith('Color_'))
                                setModuleData({ 
                                    tmpMetricValue: sortMetricList([ ...newValue, firstValue ].slice(0, 2)), 
                                    isFilterChanged: true,
                                })
                                return
                            }
                            if (value.length === 1 && value[0] === METRIC_KEY_COLOR) {
                                const firstValue = metricOptions.find(item => item.key === METRIC_KEY_COLOR)?.children?.[0]?.key
                                setModuleData({ 
                                    tmpMetricValue: sortMetricList([ firstValue ]),
                                    isFilterChanged: true,
                                })
                                return
                            }
                            setModuleData({ 
                                tmpMetricValue: sortMetricList([ ...value ]), 
                                isFilterChanged: true,
                            })
                        }}
                    />
                    <div className={styles.buttonWrapper}>
                        <div className={styles.buttonText}>FETCHING DATA</div>
                        <Button 
                            type={(isFilterChanged || apiLoading) ? 'danger' : 'primary'} 
                            prefix={<RedoOutlined />} 
                            onClick={onExecute}
                            style={{ width: 190 }}
                            className={(apiLoading || isFilterChanged) ? 'executeLoadingButton' : 'loadCompletedButton' }
                        >
                            { apiLoading ? 'Loading' : isFilterChanged ? 'Load' : 'Loading completed' }
                        </Button>
                    </div>
                    <AxisSwitch
                        className={tmpSwitchValue !== switchValue ? 'switch-changed' : ''}
                        value={tmpSwitchValue}
                        onChange={value => {
                            if (
                                arrayUtils.isEqual(tmpCategoryValue, categoryValue)
                                && isCompetitorEqual(tmpCompetitorValue, competitorValue)
                                && tmpRegionValue === regionValue
                                && arrayUtils.isEqual(tmpMetricValue, metricValue)
                                && value === switchValue
                                && tmpDateRangeValue === dateRangeValue
                            ) {
                                setModuleData({
                                    tmpSwitchValue: value,
                                    isFilterChanged: false,
                                })
                                return 
                            }
                            setModuleData({ 
                                tmpSwitchValue: value,
                                isFilterChanged: true,
                            })
                        }}
                    />
                    <Flex1 />
                    <div style={{ height: 56 }}>
                        <div style={{ height: 20, minHeight: 16 }} />
                        <Flex align="center" style={{ height: 36, fontSize: 14, color: '#01A699' }}>
                            <div style={{ height: 36, lineHeight: '36px', fontSize: 14, color: '#666' }}>
                                Selected time period:&nbsp;
                            </div>
                            <div style={{ height: 36, lineHeight: '36px', fontSize: 14, color: '#666' }}>
                                {formatDateStr2(tmpDateRangeValue)}
                            </div>
                        </Flex>
                    </div>
                    <ExportDropdown
                        onExportExcel={onExportExcel}
                        selector={modalSelector}
                        fileName={fileName}
                        excludeSelectors={[ '.line-graph-tooltip' ]}
                    />
                </div>
                {
                    dateRangeValue && (
                        <FinancialChart
                            ref={chartRef}
                            loading={apiLoading}
                            chartData={chartData}
                            formatTooltipValue={(value, index) => {
                                if (metricValue.length === 2) {
                                    return formatMetricValue(value, metricValue[index])
                                }
                                return formatMetricValue(value, metricValue[0])
                            }}
                            formatYAxis={(value, yAxisName) => formatMetricValue(Math.abs(value), yAxisName)}
                            formatStep={metric => formatMetricStep(metric)}
                            defaultDateRange={dateRangeValue}
                            key={dateRangeValue}
                            tmpDateRangeValue={tmpDateRangeValue}
                            onDateRangeChange={value => {
                                if (
                                    arrayUtils.isEqual(tmpCategoryValue, categoryValue)
                                    && isCompetitorEqual(tmpCompetitorValue, competitorValue)
                                    && tmpRegionValue === regionValue
                                    && arrayUtils.isEqual(tmpMetricValue, metricValue)
                                    && tmpSwitchValue === switchValue
                                    && value === dateRangeValue
                                ) {
                                    setModuleData({
                                        tmpDateRangeValue: value,
                                        isFilterChanged: false,
                                    })
                                    return 
                                }
                                setModuleData({ 
                                    tmpDateRangeValue: value, 
                                    isFilterChanged: true,
                                })
                            }}
                        />
                    )
                }
            </div>
        </Modal>
    )
}

export default GraphModal
