import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { Box, Button, Flex, Flex1, MultiSelect, Switch } from 'druikit'
import { useSoldOutVendorsModuleData } from 'features/filters/moduleDataSlice.hook'
import { cloneDeep, isEqual } from 'lodash'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { storage } from 'utils/storage'
import { ExportDropdown, getHtml2CanvasImgUploadPath } from 'componentsv2/ExportDropdown'
import { downloadFileByAxios } from 'export'
import { MultiCategoryDropdown } from 'componentsv2/business/CategoryDropdown'
import { getCompetitorOptionsForGraphModal } from 'utils/optionsUtils'
import { getExportFilenameByModule } from 'utils/exportUtils'
import { SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME } from 'configs'
import { useFilterCountry } from 'hooks/useFilter'
import { RedoOutlined } from '@ant-design/icons'
import { loadingBar } from 'hooks/useLoadingBar'
import classNames from 'classnames'
import { arrayUtils, numberUtils } from 'norna-uikit'
import { formatDateStr2 } from 'utils/dateUtils'
import { getVendorNameByCode } from 'utils'
import FinancialChart, { FinancialChartRefProps } from 'componentsv2/FinancialChart'
import { formatMetricLabel, sortMetricLabel } from './utils'

const exportFilename = getExportFilenameByModule(SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME)

const metricList = [
    '<S sold out', 
    'S sold out', 
    'M sold out', 
    'L sold out', 
    'XL sold out',
    '>XL sold out',
]

const Graph = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ date, setDate ] = useState('')

    const [ moduleData, setModuleData ] = useSoldOutVendorsModuleData()
    const {
        vendorValue,
        metricValue,
        isMultipleSizes = true,
        graphApiPayload,
        graphApiData: apiData,
        graphApiLoading: loading,
        graphCategoryValue = [],
        tmpMetricValue,
        tmpVendorValue,
        tmpGraphCategoryValue = [],
        tmpIsMultipleSizes,
    } = moduleData

    useEffect(() => {
        const newVendorValue = vendorValue.filter(item => item)
        if (!newVendorValue.length) {
            setModuleData({ vendorValue: [ storage.getCustomerVendor() ] })
        }
    }, [])

    useEffect(() => {
        if (tmpGraphCategoryValue.length > 0) return
        const categoryTreeList = storage.getCategoryTreeList()
        const value = categoryTreeList
            .filter(item => [ 'Tops' ].includes(item.name))
            .map(item => item.list?.map(item2 => item2.name))
            .filter(item => item)
            .flat(10) as string[]
        setModuleData({ 
            graphCategoryValue: value, 
            tmpGraphCategoryValue: value,
        })
    }, [ graphCategoryValue ])

    const [ dataSource, setDataSource ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        if (!Object.keys(apiData || {})?.length) return
        const vendorList = Object.keys(apiData || {})
        const firstVendorData = cloneDeep(apiData[vendorList[0]])
        const data: any[] = []
        const dateList = Object.keys(firstVendorData || {})
        const sellers = storage.getSellers()
        vendorList.forEach(vendor => {
            const vendorData = apiData[vendor]
            const launchDate = sellers.find(s => s.vendor === vendor && s.region === filterCountry)?.launch_date as string
            metricList.forEach(metric => {
                data.push({
                    metric,
                    vendor,
                    vendorName: getVendorNameByCode(vendor),
                    yAxis: 'left',
                    data: dateList.map(date => {
                        const value = date < launchDate ? undefined : vendorData[date][metric]
                        return {
                            time: date,
                            value,
                        }
                    }),
                })
            })
        })

        if (isMultipleSizes) {
            // 多个 size, 一个 vendor
            const newData = data
                .filter(item => {
                    return item.vendor === vendorValue[0] && metricValue.includes(item.metric)
                })
                .map(item => {
                    return {
                        yAxisName: item.vendorName,
                        label: formatMetricLabel(item.metric),
                        data: item.data,
                    }
                })
            setDataSource(newData)
            return
        }
        
        // 一个 size, 多个 vendor
        const newData = data
            .filter(item => {
                return item.metric === metricValue[0] && vendorValue.includes(item.vendor)
            })
            .map(item => {
                return {
                    yAxisName: formatMetricLabel(item.metric),
                    label: item.vendorName,
                    data: item.data,
                }
            })
        setDataSource(newData)
    }, [ apiData, vendorValue, metricValue, isMultipleSizes, filterCountry, {} ])

    const lineGraphRef1 = useRef<FinancialChartRefProps>()

    const onExportExcel = async () => {
        const payload = cloneDeep(graphApiPayload)
        payload.query.graph_image = await getHtml2CanvasImgUploadPath({ selector: '.graph-export-cls' })
        payload.data.metrics = metricValue
        payload.data.competitors = vendorValue
        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    /* ******************************** 监听弹窗大小 ********************************** */
    const [ financialChartHeight, setFinancialChartHeight ] = useState(320)

    useLayoutEffect(() => {
        const child = document.querySelector('.sold-out-analytics-graph-modal') as any
        let observer: ResizeObserver
        if (child) {
            observer = new ResizeObserver(function (mutations) {
                lineGraphRef1.current?.updateVisibleTimeRange()
                const rect = mutations[0].contentRect 
                setFinancialChartHeight(Math.max(rect.height - 200, 320))
            })
            observer.observe(child)
        }
        return () => {
            if (child && observer) {
                observer.unobserve(child)
            }
        }
    }, [])

    const onExecute = () => {
        lineGraphRef1.current?.updateVisibleTimeRange()
        setModuleData({
            vendorValue: tmpVendorValue,
            metricValue: tmpMetricValue,
            graphCategoryValue: tmpGraphCategoryValue,
            isMultipleSizes: tmpIsMultipleSizes,
        })
        loadingBar.restart()
    }

    const isFilterChanged = !isEqual(vendorValue, tmpVendorValue)
        || !isEqual(metricValue, tmpMetricValue)
        || !isEqual(graphCategoryValue, tmpGraphCategoryValue)
        || tmpIsMultipleSizes !== isMultipleSizes

    return (
        <div className="graph-export-cls">
            <Flex style={{ position: 'relative', zIndex: 10, alignItems: 'flex-end' }}>
                <ModuleTitle 
                    category="Assortment"
                    title="Sold out"
                />
                <Box width={100} />
                <VendorSelect
                    className={classNames(!isEqual(vendorValue, tmpVendorValue) ? 'select-changed' : '', 'druikit-multi-select-no-ok-button')}
                    minCount={1}
                    maxCount={tmpIsMultipleSizes ? 1 : 9999}
                    value={cloneDeep(tmpVendorValue)}
                    onItemChange={value => {
                        if (!value?.length) return
                        setModuleData({ tmpVendorValue: value })
                    }}
                />
                <Box width={10} />
                <MetricSelect
                    className={classNames(!arrayUtils.isEqual(cloneDeep(metricValue), cloneDeep(tmpMetricValue)) ? 'select-changed' : '', 'druikit-multi-select-no-ok-button')}
                    minCount={1}
                    maxCount={tmpIsMultipleSizes ? 9999 : 1}
                    value={cloneDeep(tmpMetricValue)}
                    onItemChange={value => {
                        if (!value?.length) return
                        setModuleData({ tmpMetricValue: sortMetricLabel(value) })
                    }}
                />
                <Box width={10} />
                <MultiCategoryDropdown
                    selectStyle={{ height: 28 }}
                    selectorClassName={ !arrayUtils.isEqual(cloneDeep(graphCategoryValue), cloneDeep(tmpGraphCategoryValue)) ? 'tree-dropdown-changed' : '' }
                    showOkButton={false}
                    right
                    excludeTotal
                    multiple
                    minCount={1}
                    value={cloneDeep(tmpGraphCategoryValue)}
                    onItemChange={value => {
                        setModuleData({ tmpGraphCategoryValue: value })
                    }}
                />
                <div style={{ height: 48, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 10, color: '#666', paddingLeft: 15 }}>FETCHING DATA</div>
                    <Button 
                        style={{ height: 28, width: 190 }} 
                        type={(isFilterChanged || loading) ? 'danger' : 'primary'} 
                        prefix={<RedoOutlined />} 
                        onClick={onExecute}
                        className={(isFilterChanged || loading) ? 'executeButton' : 'loadCompletedButton' }
                    >
                        { loading ? 'Loading' : isFilterChanged ? 'Load' : 'Loading completed' }
                    </Button>
                </div>
                <Box width={10} />
                <Switch
                    className={isMultipleSizes !== tmpIsMultipleSizes ? 'druikit-switch-changed' : ''}
                    size="small"
                    leftLabel="Multiple vendors"
                    rightLabel="Multiple sizes"
                    value={tmpIsMultipleSizes}
                    onChange={value => {
                        // Multiple vendors -> Multiple sizes
                        if (value === true) {
                            if (tmpVendorValue.length > 1) {
                                setModuleData({
                                    tmpVendorValue: [ tmpVendorValue[0] ],
                                })
                            }
                        }
                        // Multiple sizes -> Multiple vendors
                        else {
                            if (tmpMetricValue.length > 1) {
                                setModuleData({
                                    tmpMetricValue: [ tmpMetricValue[0] ],
                                })
                            }
                        }
                        setModuleData({
                            tmpIsMultipleSizes: value,
                        })
                    }}
                />
                <Flex1 />
                <div style={{ height: 48, marginRight: 10 }}>
                    <div style={{ height: 20 }} />
                    <Flex>
                        <div style={{ height: 28, lineHeight: '28px', fontSize: 12, color: '#666' }}>
                            Selected time period:&nbsp;
                        </div>
                        <div style={{ height: 28, lineHeight: '28px', fontSize: 12, color: '#666' }}>
                            {formatDateStr2(date)}
                        </div>
                    </Flex>
                </div>
                <ExportDropdown
                    label=""
                    fileName={exportFilename}
                    selector=".sold-out-analytics-graph-modal"
                    excludeSelectors={[ '.line-graph-tooltip' ]}
                    onExportExcel={onExportExcel}
                    size="small"
                />
            </Flex>
            <Box height={20} />
            <FinancialChart
                ref={lineGraphRef1 as any}
                loading={loading}
                chartData={dataSource}
                height={financialChartHeight}
                formatYAxis={value => numberUtils.formatNumber(value, { isCentuple: true, decimal: 1, isPercentSymbol: true })}
                formatTooltipValue={value => numberUtils.formatNumber(value, { isCentuple: true, decimal: 1, isPercentSymbol: true })}
                sortTooltipLabel={value => sortMetricLabel(value)}
                step={0.001}
                leftPriceScaleOptions={{
                    maxFixedValue: 0.75,
                    minFixedValue: 0.01,
                }}
                onDateRangeChange={setDate}
            />
        </div>
    )
}

const VendorSelect = ({
    minCount = 1,
    maxCount = 9999,
    value = [],
    onChange,
    onItemChange,
    className,
}: {
    minCount?: number;
    maxCount?: number;
    value: string[];
    onChange?: (value: string[]) => void;
    onItemChange?: (value: string[]) => void;
    className?: string;
}) => {
    const [ filterCountry ] = useFilterCountry()

    const commonProps: any = {
        size: 'small',
        label: 'VENDORS',
        options: cloneDeep(getCompetitorOptionsForGraphModal(filterCountry)),
        dropdownTitle: 'Vendors',
    }

    return (
        <MultiSelect
            {...commonProps}
            className={className}
            minCount={minCount}
            maxCount={maxCount}
            formatMaxCountMessage={value => 'For more than one vendor, please use the multiple vendors toggle.'}
            formatMinCountMessage={value => `Minimum ${value} vendor.`}
            value={value}
            onChange={value => onChange?.(value)}
            onItemChange={value => onItemChange?.(value)}
        />
    )
}

const MetricSelect = ({
    minCount = 1,
    maxCount = 9999,
    value = [],
    onChange,
    onItemChange,
    className,
}: {
    minCount?: number;
    maxCount?: number;
    value: string[];
    onChange?: (value: string[]) => void;
    onItemChange?: (value: string[]) => void;
    className?: string;
}) => {
    const metricOptions = [
        { label: 'XS and smaller', value: '<S sold out' },
        { label: 'S', value: 'S sold out' },
        { label: 'M', value: 'M sold out' },
        { label: 'L', value: 'L sold out' },
        { label: 'XL', value: 'XL sold out' },
        { label: 'XXL and larger', value: '>XL sold out' },
    ]

    const commonProps: any = {
        size: 'small',
        label: 'Sizes',
        dropdownTitle: 'Sizes',
        options: cloneDeep(metricOptions),
    }

    return (
        <MultiSelect
            className={className}
            {...commonProps}
            minCount={minCount}
            maxCount={maxCount}
            formatMaxCountMessage={value => 'For more than one size, please use the multiple sizes toggle.'}
            formatMinCountMessage={value => `Minimum ${value} size.`}
            clearAll={true}
            selectAll={true}
            value={value}
            onChange={value => onChange?.(value)}
            onItemChange={value => onItemChange?.(value)}
        />
    )
}

export default Graph
