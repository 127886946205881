import React from 'react'
import { Text } from 'components/typography/Text'
import { Title } from 'components/typography/Title'
import { Link } from 'components/links'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { formatVendorName } from 'utils/sellers'
import { getVendorNameByCode } from 'utils'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import { ProductTitleProps } from './types'
import styles from './styles.module.scss'
import { URL_STATE } from '../../types'

export const ProductTitle = ({ title, url, urlState, vendor }: ProductTitleProps) => {
    const { name, platform } = formatVendorName(getVendorNameByCode(vendor || ''))

    const tipsEl = (
        <InfoBox
            iconSty={{ width: 14, height: 14, lineHeight: '14px', position: 'relative', top: -2 }}
            containerSty={{ left: '-193px' }}
            title="title"
            text="tips content"
        >
            <TipsContent sty={{ padding: 0, width: 380, wordBreak: 'normal' }}>
                Please note that you sometimes have to manually change region on the vendor eCom site, for the product links in Norna Analytics to work. This is because some eCom sites use cookies, which will redirect you to the region where you are physically located, and not the region of the product you clicked on in Norna Analytics. Once you manually change the region on the eCom site, the Norna Analytics links will work.
            </TipsContent>
        </InfoBox>
    )

    return (
        <div className={styles.productTitle}>
            <div>
                <Text large style={{ color: '#7C7D7C', fontSize: '22px' }}>{name}</Text>
                <br />
                <Text large style={{ color: '#7C7D7C' }}>{platform}</Text>
            </div>

            <div className={styles.title} title={title}>
                <Title xxl secondary style={{ fontSize: '22px', color: '#888888', width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {title}
                </Title>
            </div>

            <ShouldRender shouldRender={urlState === URL_STATE.interpolated}>
                <div className={styles.url}>
                    {tipsEl}
                    <svg fill="rgb(250, 187, 48)" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3666" width="30" height="18">
                        <path d="M341.333333 512a42.666667 42.666667 0 0 0 42.666667 42.666667h256a42.666667 42.666667 0 0 0 0-85.333334H384a42.666667 42.666667 0 0 0-42.666667 42.666667z" fill="rgb(250, 187, 48)" p-id="3667" />
                        <path d="M384 682.666667H307.626667A176.213333 176.213333 0 0 1 128 527.786667 170.666667 170.666667 0 0 1 298.666667 341.333333h85.333333a42.666667 42.666667 0 0 0 0-85.333333H307.626667a262.4 262.4 0 0 0-262.826667 222.293333A256 256 0 0 0 298.666667 768h85.333333a42.666667 42.666667 0 0 0 0-85.333333zM981.333333 479.573333A262.826667 262.826667 0 0 0 715.093333 256h-64.426666C616.106667 256 597.333333 275.2 597.333333 298.666667a42.666667 42.666667 0 0 0 42.666667 42.666666h76.373333A176.213333 176.213333 0 0 1 896 496.213333 170.666667 170.666667 0 0 1 725.333333 682.666667h-85.333333a42.666667 42.666667 0 0 0 0 85.333333h85.333333a256 256 0 0 0 256-288.426667z" fill="rgb(250, 187, 48)" p-id="3668" />
                    </svg>
                    <Text small secondary style={{ color: '#666666', wordBreak: 'break-word' }}>
                        Recently unavailable, monitored for availability
                    </Text>
                </div>
            </ShouldRender>
            <ShouldRender shouldRender={urlState === URL_STATE.invalid}>
                <div className={styles.url} style={{ alignItems: 'center' }}>
                    {tipsEl}
                    <svg style={{ textDecoration: 'line-through' }} fill="rgb(226, 47, 33)" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3666" width="30" height="18">
                        <path d="M341.333333 512a42.666667 42.666667 0 0 0 42.666667 42.666667h256a42.666667 42.666667 0 0 0 0-85.333334H384a42.666667 42.666667 0 0 0-42.666667 42.666667z" fill="rgb(226, 47, 33)" p-id="3667" />
                        <path d="M384 682.666667H307.626667A176.213333 176.213333 0 0 1 128 527.786667 170.666667 170.666667 0 0 1 298.666667 341.333333h85.333333a42.666667 42.666667 0 0 0 0-85.333333H307.626667a262.4 262.4 0 0 0-262.826667 222.293333A256 256 0 0 0 298.666667 768h85.333333a42.666667 42.666667 0 0 0 0-85.333333zM981.333333 479.573333A262.826667 262.826667 0 0 0 715.093333 256h-64.426666C616.106667 256 597.333333 275.2 597.333333 298.666667a42.666667 42.666667 0 0 0 42.666667 42.666666h76.373333A176.213333 176.213333 0 0 1 896 496.213333 170.666667 170.666667 0 0 1 725.333333 682.666667h-85.333333a42.666667 42.666667 0 0 0 0 85.333333h85.333333a256 256 0 0 0 256-288.426667z" fill="rgb(226, 47, 33)" p-id="3668" />
                    </svg>
                    <Text small secondary style={{ color: '#666666' }}>
                        Product discontinued, no URL available
                    </Text>
                </div>
            </ShouldRender>
            <ShouldRender shouldRender={urlState === URL_STATE.valid}>
                <Link href={url} target="_blank" className={styles.url}>
                    {tipsEl}
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3666" width="30" height="18">
                        <path d="M341.333333 512a42.666667 42.666667 0 0 0 42.666667 42.666667h256a42.666667 42.666667 0 0 0 0-85.333334H384a42.666667 42.666667 0 0 0-42.666667 42.666667z" fill="#01a699" p-id="3667" />
                        <path d="M384 682.666667H307.626667A176.213333 176.213333 0 0 1 128 527.786667 170.666667 170.666667 0 0 1 298.666667 341.333333h85.333333a42.666667 42.666667 0 0 0 0-85.333333H307.626667a262.4 262.4 0 0 0-262.826667 222.293333A256 256 0 0 0 298.666667 768h85.333333a42.666667 42.666667 0 0 0 0-85.333333zM981.333333 479.573333A262.826667 262.826667 0 0 0 715.093333 256h-64.426666C616.106667 256 597.333333 275.2 597.333333 298.666667a42.666667 42.666667 0 0 0 42.666667 42.666666h76.373333A176.213333 176.213333 0 0 1 896 496.213333 170.666667 170.666667 0 0 1 725.333333 682.666667h-85.333333a42.666667 42.666667 0 0 0 0 85.333333h85.333333a256 256 0 0 0 256-288.426667z" fill="#04A89B" p-id="3668" />
                    </svg>
                    <Text small secondary style={{ color: '#666666' }}>
                        {url}
                    </Text>
                </Link>
            </ShouldRender>
        </div>
    )
}

ProductTitle.displayName = 'ProductTitle'
