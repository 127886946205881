/* ************************ Dashboard ************************** */
export const PRICING_DASHBOARD_LINE_GRAPH_NAME = 'Pricing graph'

export const PRICE_INSIGHT_TABLE_NAME = 'Price insights'

export const ASSORTMENT_INSIGHT_TABLE_NAME = 'Assortment insights'

export const PRICE_SPREADS_TABLE_NAME = 'Price spreads'

export const IN_OUT_MONITORING_TABLE_NAME = 'In & Out monitoring'

export const SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME = 'Sold out analytics'

export const WEEKLY_TABLE_NAME = 'Most popular launch days'

export const BRAND_POSITION_GRAPH_NAME = 'Brand position'
export const BRAND_POSITION_TABLE_NAME = 'Brand position drivers'

export const DYNAMIC_DASHBOARD_NAME = 'Dynamic dashboard'

export const DYNAMIC_DASHBOARD_REPORT_NAME = 'Dynamic dashboard report'
export const LINE_GRAPH_REPORT_NAME = 'Line graph report'

/* ************************ Pricing ************************** */
export const PRICE_DRIVERS_TABLE_NAME = 'Price drivers (intra period)'

export const PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME = 'Price drivers (period to period)'

export const PRICE_ARCHITECTURE_TABLE_NAME = 'Price architecture'

export const DISCOUNTS_TABLE_NAME = 'Discounts'

export const CROSS_REGION_PRICING_NAME = 'Cross region pricing'

export const CROSS_REGION_SOLD_OUT_NAME = 'Sold out cross region'

/* ************************ Assortment ************************** */
export const CATEGORY_SPLIT_PIE_GRAPH_NAME = 'Category split'

export const COLOR_SPLIT_PIE_GRAPH_NAME = 'Color split'

export const MATERIAL_SPLIT_PIE_GRAPH_NAME = 'Material split'

export const PRODUCT_RANKING_TABLE_NAME = 'Product ranking last week'
export const PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME = 'Product ranking'

export const ATTRIBUTE_RANKING_TABLE_NAME = 'Attribute ranking'

export const SIZE_OF_LINE_TABLE_NAME = 'Size of line'

export const OPTIONS_IN_OUT_CALENDAR_NAME = 'Options in & out by month'

export const PLATFORM_ANALYTICS_NAME = 'Marketplace seller analytics'

/* ************************ Lookbooks ************************** */
export const SEARCH_FOR_OPTIONS_NAME = 'Browse options'
export const MY_SAVED_LOOKBOOKS_NAME = 'My saved lookbooks'
export const MY_SAVED_FILTERS_NAME = 'My saved filters'
