/**
 * globalDataSlice.ts
 */
import { useDispatch, useSelector } from 'react-redux'
import { getCalendarCompareToValue, getCalendarComparisonDate, getCalendarComparisonTmpDate, getCalendarDate, getCalendarPeriodValue, getCalendarSelectionDate, getCalendarTmpDate, getFilterTags, getTmpProperty, updateCalendarCompareToValue, updateCalendarComparisonDate, updateCalendarComparisonTmpDate, updateCalendarDate, updateCalendarPeriodValue, updateCalendarSelectionDate, updateCalendarTmpDate, updateTmpProperty } from 'features/filters/globalDataSlice'

export const useCalendarDate = () => {
    const calendarDate = useSelector(getCalendarDate)

    const dispatch = useDispatch()
    const setCalendarDate = (value: string) => {
        dispatch(updateCalendarDate(value))
    }

    return [
        calendarDate,
        setCalendarDate,
    ]
}

export const useCalendarTmpDate = () => {
    const calendarTmpDate = useSelector(getCalendarTmpDate)

    const dispatch = useDispatch()
    const setCalendarTmpDate = (value: string) => {
        dispatch(updateCalendarTmpDate(value))
    }

    return [
        calendarTmpDate,
        setCalendarTmpDate,
    ]
}

export const useCalendarComparisonDate = () => {
    const calendarComparisonDate = useSelector(getCalendarComparisonDate)

    const dispatch = useDispatch()
    const setCalendarComparisonDate = (value: string) => {
        dispatch(updateCalendarComparisonDate(value))
    }

    return [
        calendarComparisonDate,
        setCalendarComparisonDate,
    ]
}

export const useCalendarComparisonTmpDate = () => {
    const calendarComparisonTmpDate = useSelector(getCalendarComparisonTmpDate)

    const dispatch = useDispatch()
    const setCalendarComparisonTmpDate = (value: string) => {
        dispatch(updateCalendarComparisonTmpDate(value))
    }

    return [
        calendarComparisonTmpDate,
        setCalendarComparisonTmpDate,
    ]
}

export const useCalendarPeriodValue = () => {
    const calendarPeriodValue = useSelector(getCalendarPeriodValue)

    const dispatch = useDispatch()
    const setCalendarPeriodValue = (value: string) => {
        dispatch(updateCalendarPeriodValue(value))
    }

    return [
        calendarPeriodValue,
        setCalendarPeriodValue,
    ]
}

export const useCalendarCompareToValue = () => {
    const calendarCompareToValue = useSelector(getCalendarCompareToValue)

    const dispatch = useDispatch()
    const setCalendarCompareToValue = (value: string) => {
        dispatch(updateCalendarCompareToValue(value))
    }

    return [
        calendarCompareToValue,
        setCalendarCompareToValue,
    ]
}

export const useCalendarSelectionDate = () => {
    const calendarSelectionDate = useSelector(getCalendarSelectionDate)

    const dispatch = useDispatch()
    const setCalendarSelectionDate = (value: string) => {
        dispatch(updateCalendarSelectionDate(value))
    }

    return [
        calendarSelectionDate,
        setCalendarSelectionDate,
    ]
}

export const useTmpProperty = () => {
    const tmpProperty = useSelector(getTmpProperty)

    const dispatch = useDispatch()
    const setTmpProperty = (value: Record<string, string[]>) => {
        dispatch(updateTmpProperty(value))
    }

    return [
        tmpProperty,
        setTmpProperty,
    ]
}

export const useFilterTags = () => {
    const filterTags = useSelector(getFilterTags)

    return [
        filterTags,
    ]
} 
