/**
* @file    MenuBarItem
* @date   2022-07-05
* @author byte_su@163.com
*/

import { ClickAwayListener } from '@material-ui/core'
import classnames from 'classnames'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { parentshasClass } from 'components/common/InfoBox/InfoActionContext'
import { FILTER_DIALOG_CLASS, TIME_PERIOD } from 'consts'
import { selectCollection, updateFilters } from 'features/filters/filtersSlice'
import { getFilterTags, updateFilterTags } from 'features/filters/globalDataSlice'
import { useCalendarComparisonDate, useCalendarComparisonTmpDate, useCalendarDate, useCalendarTmpDate } from 'hooks/useGlobalData'
import { useMenuAndFilterPermission } from 'hooks/useQa'
import { throttle } from 'lodash'
import React, { useCallback, createRef, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { GlobalDatePicker } from './GlobalDatePicker/GlobalDatePicker'
import styles from './style.module.scss'
import { MenuBarItemListItem, MenuBarItemProps, MenuPanelDisappearType } from './types'
import { activeCurrentPath } from './utils'

function getComparisonId(queryCompariso) {
    return queryCompariso?.id || 'norna-analysis'
}
export const MenuBarItem = ({
    panelSty = {},
    disabled = false,
    disabledHover = false,
    disappearType = MenuPanelDisappearType.LEAVE,
    icon, 
    list, 
    CustomListElement, 
    title, 
    active = false,
    currentActive = '',
    updateActive,
}: MenuBarItemProps) => {
    const { menuAvailable } = useMenuAndFilterPermission()
    const { pathname, search } = useLocation()
    const customRef = createRef<any>()
    const dispatch = useDispatch()
    const filterState = useSelector(getFilterTags)
    const queryCompariso = useSelector(selectCollection)
    const id = getComparisonId(queryCompariso)
    const state = active

    // close menu panel
    const closeFn = e => {
        const parEle = parentshasClass(e.target, 'material-filter')
        const dateEle = parentshasClass(e.target, 'DatePicker__calendarContainer')

        if (
            parEle ||
            dateEle ||
            e.target?.closest?.('.menubarItem') ||               // 元素内部事件不关闭
            e.target.closest(`.${FILTER_DIALOG_CLASS}`) ||       // 在由`Filter`打开的class包含`FILTER_DIALOG_CLASS`的弹框上交互不关闭
            document.querySelector(`.${FILTER_DIALOG_CLASS}`) || // 存在由`Filter`打开的class包含`FILTER_DIALOG_CLASS`的弹框不关闭
            e.target?.tagName?.toUpperCase() === 'SVG') return  // menu内部返回

        if (disappearType === MenuPanelDisappearType.CLICK_AWAY) {
            // 自定义panel, 存在关闭方法，并且是打开状态时
            if (customRef.current?.close) { // 
                const closeSuccess = customRef.current?.close?.()
                if (closeSuccess) {
                    updateActive?.(false)
                }
                onUpdateFilter()
            } else {
                updateActive?.(false)
            }
        }
    }

    const onUpdateFilter = () => {
        if (!Object.keys(filterState || {}).length) return
        const newFilterState = { ...filterState }
        if (Array.isArray(newFilterState.vendor)) {
            newFilterState.vendor = newFilterState.vendor.map(item => ({ ...item, brand: undefined }))
        }
        dispatch(updateFilters({ ...newFilterState }))
        dispatch(updateFilterTags({}))
    }

    // eslint-disable-next-line
    const [ , setCalendarDate ] = useCalendarDate()
    const [ calendarTmpDate, setCalendarTmpDate ] = useCalendarTmpDate()
    // eslint-disable-next-line
    const [ , setCalendarComparisonDate ] = useCalendarComparisonDate()
    const [ calendarComparisonTmpDate, setCalendarComparisonTmpDate ] = useCalendarComparisonTmpDate()

    const onUpdateGlobalDate = () => {
        if (!calendarTmpDate || !calendarComparisonTmpDate) return

        if (calendarTmpDate.endsWith('_')) {
            const singleDate = calendarTmpDate.replace('_', '')
            setCalendarDate([ singleDate, singleDate ].join('_'))
        } else {
            setCalendarDate(calendarTmpDate)
        }

        if (calendarComparisonTmpDate.endsWith('_')) {
            const singleDate = calendarComparisonTmpDate.replace('_', '')
            setCalendarComparisonDate([ singleDate, singleDate ].join('_'))
        } else {
            setCalendarComparisonDate(calendarComparisonTmpDate)
        }

        setCalendarTmpDate('')
        setCalendarComparisonTmpDate('')
    }

    /* ****************************************************************** */
    const history = useHistory()
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    const switchToDashboardPage = (url: string) => {
        history.push(url)
    }

    let listHtml = <></>
    if (!CustomListElement) {
        listHtml = (
            <ul>
                {list?.map((item: MenuBarItemListItem) => {
                    const available = menuAvailable(item.label)
                    let currentHtml = <></>

                    if (!available) {
                        currentHtml = (
                            <button className={classnames(styles['menu-disabled'], styles.item, 'cell-mouse-effect-block-align-left')} >
                                <span >{item.label}</span>
                            </button>
                        )
                    } 
                    else if (item.url) {
                        currentHtml = (
                            // eslint-disable-next-line
                            <a
                                className={classnames({ [styles.active]: activeCurrentPath(pathname, search, item.url) })}
                                onClick={() => {
                                    updateActive?.(false)
                                    onUpdateGlobalDate()
                                    onUpdateFilter()
                                    switchToDashboardPage(item.url as string)
                                }}
                            >
                                {item.label}
                            </a>
                        )
                    }
                    else if (item.urlFn) {
                        currentHtml = (
                            <Link
                                className={classnames({ [styles.active]: activeCurrentPath(pathname, search, item.urlFn(id)) })}
                                to={item.urlFn?.(id)}
                                onClick={() => {
                                    updateActive?.(false)
                                    onUpdateGlobalDate()
                                    onUpdateFilter()
                                }}
                            >
                                {item.label}
                            </Link>
                        )
                    } else if (item.click) {
                        currentHtml = (
                            <button
                                onClick={() => {
                                    updateActive?.(false)
                                    item.click?.()
                                }}
                                disabled={!!item.disabled}
                                className={classnames(styles.item, 'cell-mouse-effect-block-align-left')}
                            >
                                <span >{item.label}</span>
                            </button>
                        )
                    }

                    return (
                        <li key={item.label}>{currentHtml}</li>
                    )
                })}
            </ul>
        )
    } else {
        listHtml = <GlobalDatePicker ref={customRef} active={active} />
    }
    const panelRef = createRef<HTMLDivElement>()
    const buttonRef = createRef<HTMLButtonElement>()
    const arrowRef = createRef<HTMLDivElement>()

    const calcSty = {}

    // disable 
    const calcPosition = useCallback(throttle(() => {
        if (!state) return
        try {

            const buttonRect: DOMRect = buttonRef.current?.getBoundingClientRect() as DOMRect
            const panelRect: DOMRect = panelRef.current?.getBoundingClientRect() as DOMRect

            // 当菜单的`Panel`底部位置超出可视区高度, 动态改变`Panel`箭头位置
            if (panelRect && window.innerHeight < (buttonRect?.top + panelRect.height) && panelRef.current) {
                const distance = window.innerHeight - buttonRect.top
                const offsetTop = panelRect.height - distance - 13 + 25
                panelRef.current.style.top = `-${offsetTop}px`
                if (arrowRef.current) {
                    if (offsetTop + 34 > panelRect.height) {
                        arrowRef.current.style.top = `${panelRect.height - 34}px`
                    } else {
                        arrowRef.current.style.top = `${offsetTop + 17}px`
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }

    }, 500), [ arrowRef, panelRef, state ])

    useEffect(() => {
        calcPosition()
        window.addEventListener('resize', calcPosition)

        return () => {
            window.removeEventListener('resize', calcPosition)
        }
    }, [state]) // eslint-disable-line

    return (
        <ClickAwayListener
            onClickAway={closeFn}
        >
            <div
                onMouseEnter={() => {
                    if (disabled || disabledHover || !currentActive || currentActive === title) return
                    updateActive?.(true)
                }}
                onClick={useCallback(e => {
                    if (disabled || disabledHover) return
                    updateActive?.(!active)
                }, [ disabled, updateActive, disabledHover, active ])}

                className={
                    classnames({
                        menubarItem: true,
                        [styles.menubarItem]: true,
                        [styles.active]: state,
                        [styles.disabled]: disabled,
                    })}
            >
                <Dialog ref={dialogRef} />
                <div>
                    <button
                        ref={buttonRef}
                        className={classnames({ [styles.disabled]: disabled })}
                    >
                        {icon}
                    </button>
                    <span className={classnames(styles.label, title)} >{title}</span>
                </div>
                <div
                    ref={panelRef}
                    className={styles.menubarItemPanel}
                    style={{
                        ...panelSty,
                        ...calcSty,
                    }}
                >
                    <div
                        className={styles.arrow}
                        ref={arrowRef}
                    />
                    <div
                        style={{ position: 'relative' }} 
                        className={styles.menubarItemPanelContent}
                        onClick={e => e.stopPropagation()}
                    >
                        {title && title !== TIME_PERIOD ? <div className={styles.title} style={{ cursor: 'default' }}>{title}</div> : null}
                        <div style={{ display:state?'block':'none' }}>{listHtml}</div> 
                    </div>
                </div>
            </div >
        </ClickAwayListener>

    )
}

MenuBarItem.displayName = 'MenuBarItem'

